import React, { useEffect, useState } from 'react'
import TablePrefab from '../TablePrefab'
import { getProjectCompletePercentage, getProjectNumberOfContributions } from '../../../utils'
import { projectsTooltips } from '../../../TooltipHints'
import HeaderComponent from '../TableComponents/HeaderComponent'
import { BooleanBodyComponentTrash, ProgressBodyComponent, ActionsBodyComponentTrash } from '../TableComponents/BodyComponents'
import ViewMore from '../../Dialogs/Projects/ViewMore'

function Projects(props) {
    const [data, setData] = useState([])
    const [multipleSelected, setMultipleSelected] = useState([])
    const [rowsSelected, setRowsSelected] = useState([])
    const [btnVisible, setBtnVisible] = useState(false)
    const [viewMore, setViewMore] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)

    useEffect(() => {
        props.data.forEach(project => {
            project.numberOfTasks = project.tasks.length;
            project.progress = getProjectCompletePercentage(project);
            project.numOfContributions = getProjectNumberOfContributions(project)
        })

        setData(props.data)
    }, [props])

    const getID = (id) => {
        return props.data.filter(proj => proj.id === id)[0]
    }

    const handleRowClick = (id) => {
        setSelectedProject(getID(id))
        setViewMore(true)
    }

    const onRowsSelection = (lastSelected, allSelected, rowSelected) => {

        setBtnVisible(allSelected.length !== 0)

        const items = allSelected.map(data => props.data[data.dataIndex].id)
        setRowsSelected(rowSelected)
        setMultipleSelected(items)
    }

    const deleteItems = (ids) => {
        setRowsSelected([])
        props.delete('Projetos', ids)
    }

    const restoreItems = (ids) => {
        setRowsSelected([])
        props.restore('Projetos', ids)
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'title', label: 'Titulo', options: { filter: false, searchable: true } },
        { name: 'address', label: 'Morada', options: { filter: false, searchable: true } },
        { name: 'numberOfTasks', label: 'Tarefas', options: { filter: false, searchable: false, hint: projectsTooltips.tasks, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'numOfContributions', label: 'Contribuições', options: { filter: false, searchable: false, hint: projectsTooltips.contributions, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'progress', label: 'Progresso', options: { filter: true, searchable: false, hint: projectsTooltips.progress, customBodyRender: (value, tableMeta, updateValue) => <ProgressBodyComponent value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'main_page', label: 'Pagina Inicial', options: { filter: true, searchable: false, hint: projectsTooltips.home_page, customBodyRender: (value, tableMeta, updateValue) => <BooleanBodyComponentTrash value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'online', label: 'Online', options: { filter: true, searchable: false, hint: projectsTooltips.online, customBodyRender: (value, tableMeta, updateValue) => <BooleanBodyComponentTrash value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponentTrash value={value} meta={tableMeta} update={updateValue} onDelete={(props) => deleteItems([props.meta.rowData[0]])} onRestore={(props) => restoreItems([props.meta.rowData[0]])} onView={(props) => handleRowClick(props.meta.rowData[0])} /> } },
    ]

    const options = {
        filter: false,
        responsive: 'standard',
        selectToolbarPlacement: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowSelectionChange: onRowsSelection,
        rowsSelected: rowsSelected,
        customToolbar: () => {
            return (
                <HeaderComponent visible={btnVisible} restoreCb={() => restoreItems(multipleSelected)} deleteCb={() => deleteItems(multipleSelected)} />
            )
        },
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    };

    return (
        <>
            <TablePrefab data={data} columns={columns} loading={props.loading} title='Projetos' customOptions={options} />
            {selectedProject &&
                <ViewMore open={viewMore} close={() => setViewMore(false)} data={selectedProject} />
            }
        </>
    )
}

export default Projects