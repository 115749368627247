import { useEffect } from 'react';
import './App.css';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { getUser } from './Api/Requests';
import { useUserInfo, useUserRole } from './Hooks/useUser';
import ModalProvider from 'mui-modal-provider'

import useToken from './Hooks/useToken'

import TopPanel from './Components/Panels/TopPanel'
import SidePanel from './Components/Panels/SidePanel/SidePanel'
import Dashboard from './Pages/Dashboard'
import Projects from './Pages/Projects'
import News from './Pages/News'
import Tasks from './Pages/Tasks'
import Partners from './Pages/Partners'
import Users from './Pages/Users'
import Lists from './Pages/Lists'
import Recycle from './Pages/Recycle'
import Reports from './Pages/Reports'
import Help from './Pages/Help'
import Newsletter from './Pages/Newsletter';


function App() {

	const { token } = useToken()
	const navigate = useNavigate()
	const { userInfo, setUserInfo } = useUserInfo()
	const { setUserRole } = useUserRole()

	useEffect(() => {
		if (!token) {
			navigate('/login', { replace: true });
			return;
		}

		getUser(token.split('/')[0], token).then(res => {
			const { name, id, email, picture } = res.data
			setUserInfo({ name, id, email, picture, token: token })
			setUserRole(res.data.user_role)
		}, err => {
			console.log(err)
			navigate('/login', { replace: true });
		})
	}, [token])

	return (
		<div>
			<ModalProvider>
				{userInfo && <TopPanel />}
				<div style={{ display: 'flex', paddingLeft: '75px' }}>
					<SidePanel />
					<Routes>
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/projects' element={<Projects />} />
						<Route path='/news' element={<News />} />
						<Route path='/tasks' element={<Tasks />} />
						<Route path='/partners' element={<Partners />} />
						<Route path='/users' element={<Users />} />
						<Route path='/lists' element={<Lists />} />
						<Route path='/recycle' element={<Recycle />} />
						<Route path='/reports' element={<Reports />} />
						<Route path='/newsletter' element={<Newsletter />} />
						<Route path='/help' element={<Help />} />
						<Route path='*' element={<Navigate to='/login' />} />
					</Routes>
				</div>
			</ModalProvider>
		</div>
	);
}

export default App;
