import React, { useEffect, useState } from 'react'
import TablePrefab from './TablePrefab'
import { getProjectCompletePercentage, getProjectNumberOfContributions } from '../../utils'
import { setEntityOnlineOffline, editEntity } from '../../Api/Requests'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { projectsTooltips } from '../../TooltipHints'
import { BooleanBodyComponent, ProgressBodyComponent, ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import ViewMore from '../Dialogs/Projects/ViewMore'
import EditProject from '../Dialogs/Projects/Edit/EditProject'
import AddContribution from '../Dialogs/Contributions/Add/AddContribution'

function ProjectsTable(props) {
    const [data, setData] = useState([])
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectedProject, setSelectedProject] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [openViewMoreDialog, setOpenViewMoreDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openAddCont, setOpenAddCont] = useState(false)

    useEffect(() => {
        props.data.forEach(project => {
            project.numberOfTasks = project.tasks.length;
            project.progress = getProjectCompletePercentage(project);
            project.numOfContributions = getProjectNumberOfContributions(project)
        })

        setData(props.data)

    }, [props])

    const getProjectById = id => {
        return props.data.filter(project => project.id === id)[0]
    }

    const handleEditHomePage = (project, main_page) => {
        setSubmitting(true)
        editEntity('projects', project.id, { main_page }).then(res => {
            showSnackbar(`O projeto ${project.title} ${main_page ? 'é agora visível na pagina inicial' : 'deixou de estar visível na pagina inicial'}`, '', "success")
            setSubmitting(false)
            props.refresh()
        }, err => {
            setSubmitting(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const setOnlineOffline = (project, online) => {
        setSubmitting(true)
        setEntityOnlineOffline('projects', project.id, online).then(res => {
            showSnackbar(`O projeto ${project.title} está agora ${online ? 'Online' : 'Offline'}`, '', "success")
            setSubmitting(false)
            props.refresh()
        }, err => {
            console.log(err)
            setSubmitting(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const handleOpenDelete = (props) => {
        setSelectedProject(getProjectById(props.meta.rowData[0]))
        setOpenDeleteDialog(true)
    }

    const handleOpenViewMore = (props) => {
        setSelectedProject(getProjectById(props.meta.rowData[0]))
        setOpenViewMoreDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedProject(getProjectById(props.meta.rowData[0]))
        setOpenEditDialog(true)
    }

    const handleOpenAddCont = (props) => {
        setSelectedProject(getProjectById(props.meta.rowData[0]))
        setOpenAddCont(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'title', label: 'Titulo', options: { filter: false, searchable: true } },
        { name: 'address', label: 'Morada', options: { filter: false, searchable: true } },
        { name: 'numberOfTasks', label: 'Tarefas', options: { filter: false, searchable: false, hint: projectsTooltips.tasks, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'numOfContributions', label: 'Contribuições', options: { filter: false, searchable: false, hint: projectsTooltips.contributions, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'progress', label: 'Progresso', options: { filter: true, searchable: false, hint: projectsTooltips.progress, customBodyRender: (value, tableMeta, updateValue) => <ProgressBodyComponent value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'main_page', label: 'Pagina Inicial', options: { filter: true, searchable: false, hint: projectsTooltips.home_page, customBodyRender: (value, tableMeta, updateValue) => <BooleanBodyComponent value={value} meta={tableMeta} update={updateValue} change={handleEditHomePage} /> } },
        { name: 'online', label: 'Online', options: { filter: true, searchable: false, hint: projectsTooltips.online, customBodyRender: (value, tableMeta, updateValue) => <BooleanBodyComponent value={value} meta={tableMeta} update={updateValue} change={setOnlineOffline} /> } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onView={handleOpenViewMore} onDelete={handleOpenDelete} onAdd={handleOpenAddCont} entity='Project' /> } },
    ]

    return (
        <>
            <TablePrefab data={data} columns={columns} loading={submitting} title='Projetos' />
            {selectedProject &&
                <>
                    <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Projeto' entityType='projects' nameField='title' data={selectedProject} refresh={props.refresh} />
                    <ViewMore open={openViewMoreDialog} close={() => setOpenViewMoreDialog(false)} data={selectedProject} />
                    <EditProject open={openEditDialog} close={() => { setOpenEditDialog(false); setSelectedProject(null) }} refresh={props.refresh} data={selectedProject} triggerSnackbar={triggerSnackbar} />
                    <AddContribution open={openAddCont} close={() => setOpenAddCont(false)} refresh={props.refresh} tasksData={selectedProject.tasks} task={null} triggerSnackbar={triggerSnackbar} partnersData={props.partnersData} addPartner={props.addPartner} />
                </>
            }
        </>
    )
}

export default ProjectsTable