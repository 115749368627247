import React, { useState, useEffect } from 'react'
import DialogPrefab from '../../DialogPrefab'
import { Button } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { getEntity, getUser } from '../../../../Api/Requests'
import portraitPlaceholder from '../../../../assets/placeholders/portrait_placeholder.png'
import moment from 'moment'
import EditProfile from './EditProfile'
import useGlobalSnackbar from '../../../../Hooks/useGlobalSnackbar'
import { useUserInfo } from '../../../../Hooks/useUser'

import './Profile.css'

function OtherUserProfile(props) {
    const [user, setUser] = useState(null)
    const [activeUser, setActiveUser] = useState(null)
    const [showSideView, setShowSideView] = useState(false)
    const [userRolesData, setUserRolesData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()
    const { userInfo } = useUserInfo()

    useEffect(() => {
        setUser(props.user)

        if (userInfo.id) {
            getUser(userInfo.id).then(res => {
                setActiveUser(res.data)
            }, err => {
                console.log(err)
            })
        }

        getEntity('userRoles').then(res => {
            setUserRolesData(res.data)
        }, err => {
            console.log(err)
        })
    }, [refresh, userInfo])

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth={showSideView ? 'md' : 'xs'}
            title={`Perfil ${user?.name}`}
        >
            {user &&
                <div className='profile-main-div'>
                    <div className={`profile-portrait-div ${showSideView ? 'small' : 'large'}`}>
                        <div className='profile-userType-wrapper-div'>
                            <div className='userType-div-user-card'>{user.user_role.name}</div>
                        </div>
                        <div className='profile-picture-div'>
                            {user.picture && user.picture.length > 0 ? (
                                <img alt='user portrait' style={{ objectFit: 'cover' }} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${user.picture}`} width='100%' height='100%' />
                            ) : (
                                <img alt='user portrait' src={portraitPlaceholder} width='100%' height='100%' />
                            )}
                        </div>
                        <div className='profile-bottom-div'>
                            <p className='users-name'>{user.name}</p>
                            <p className='users-job' style={{ color: 'var(--light-beige)' }}>{user.email}</p>
                            {moment(user.last_login).isValid() &&
                                <p className='users-last-login' style={{ color: 'var(--light-beige)' }}>{`Last login: ${moment(user.last_login).format('YYYY-MM-DD H:mm:ss')}`}</p>
                            }
                        </div>
                        <div className='profile-actions'>
                            <Button variant='outlined' onClick={() => setShowSideView(!showSideView)} startIcon={<Edit />}>{showSideView ? 'Fechar Editar' : 'Editar'}</Button>
                        </div>
                    </div>
                    <div className={`profile-info-div ${showSideView ? 'show' : 'hide'}`}>
                        <div className='profile-edit-div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <EditProfile
                                activeUser={activeUser}
                                user={user}
                                userRolesData={userRolesData}
                                refresh={props.refresh}
                                triggerSnackbar={triggerSnackbar}
                            />
                        </div>
                    </div>
                </div>
            }
        </DialogPrefab>
    )
}

export default OtherUserProfile