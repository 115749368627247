import React, { Component } from 'react'
import axios from 'axios'
import FormData from 'form-data'
import { DialogContent } from '@mui/material'
import DialogPrefab from '../../DialogPrefab'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'

import '../../StepDialog.css'

export default class EditIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon_name: props.data.icon_name,
            icon: props.data.icon,
            infoComplete: false,
            submitting: false,
        }
        this.changedState = { string: {}, object: {} }
    }

    resetState = () => {
        this.setState({ ...this.state })
        this.changedState = { string: {}, object: {} }
    }

    addToChangedState = (property, value) => {
        if (property === 'infoComplete') return;

        if (value !== this.props.data[property]) {
            this.changedState[typeof value][property] = value;
        } else {
            delete this.changedState[typeof value][property]
        }

        this.setState({ infoComplete:
            Object.keys(this.changedState.string).length > 0  || Object.keys(this.changedState.object.icon).length > 0 
        })
    }

    updateState = (property, value, ignoreToChangedState) => {
        this.setState({ [property]: value }, () => {
            if (!ignoreToChangedState) this.addToChangedState(property, value)
        })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        let formData = new FormData();

        Object.entries(this.changedState.string).forEach(([key, value]) => {
            formData.set(key, value)
        })

        Object.entries(this.changedState.object).forEach(([key, value]) => {
            for (let i = 0; i < value.length; i++) {
                formData.append(key, value[i].info)
            }
        })

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/icons/${this.props.data.id}`, formData, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
        }).then(res => {
            this.setState({ submitting: false })
            this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }

    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='xs'
                open={this.props.open}
                close={this.props.close}
                title='Editar Icon'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-inputs' style={{ height: 'fit-content', overflow: 'visible', margin: '0' }}>
                        <Info state={this.state} updateStateOnParent={this.updateState} />
                    </div>
                    <div className='add-dialog-action' style={{ justifyContent: 'center' }}>
                        <StandardButton disabled={!this.state.infoComplete} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}