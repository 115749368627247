import React, { useState, useEffect } from 'react'
import TasksStatsTable from '../Tables/TasksStatsTable'
import PartnersByTask from '../Charts/Tasks/PartnersByTask'
import Categories from '../Charts/Tasks/Categories'
import { getPartnerNumberOfContributions } from '../../utils'

import './TasksStatsPanel.css'
import { Button } from '@mui/material'

function ProjectStatsPanel(props) {
    const [entitySelected, setEntitySelected] = useState(false)
    const [chartTitle, setChartTitle] = useState('Tarefas e Parceiros')
    const [chart1Data, setChart1Data] = useState([])

    useEffect(() => {
        setChart1Data(getPartnerNumberOfContributions(props.data))
    }, [props.data])

    const setActiveEntity = (id) => {
        const filteredTask = [...props.data].filter(task => task.id === id)
        setChart1Data(getPartnerNumberOfContributions(filteredTask))
        setEntitySelected(true)
        setChartTitle(filteredTask[0].title_pt)
    }

    const clearEntitySelected = () => {
        setEntitySelected(false)
        setChart1Data(getPartnerNumberOfContributions(props.data))
        setChartTitle('Tarefas e Parceiros')
    }

    return (
        <div className='tasks-stats-panel-main-div' style={{ position: 'relative' }}>
            <div className='projects-stats-panel-title'>
                <p>Estatísticas das Tarefas</p>
            </div>
            <div className='tasks-stats-panel-content'>
                <div className='tasks-stats-panel-left'>
                    <div className='tasks-stats-panel-left-title'>
                        <p>{chartTitle}</p>
                        {entitySelected && <Button onClick={() => clearEntitySelected()}>Limpar tarefa selecionada</Button>}
                    </div>
                    <div className='tasks-stats-panel-left-charts'>
                        <div className='tasks-charts-div'>
                            <Categories data={props.categories} />
                        </div>
                        <div className='tasks-charts-div'>
                            <PartnersByTask data={chart1Data} />
                        </div>
                    </div>
                </div>
                <div className='tasks-stats-table' style={{ width: '30%' }}>
                    <TasksStatsTable data={props.data} setActiveEntity={setActiveEntity} type='Tarefas' nameProperty='title_pt' />
                </div>
            </div>
        </div>
    )
}

export default ProjectStatsPanel