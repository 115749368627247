import React, { useState } from 'react'
import TablePrefab from './TablePrefab'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import EditNewsletter from '../Dialogs/Newsletter/Edit/EditNewsletter'

function NewsletterTable(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [selectedEmail, setSelectedEmail] = useState(null)

    const getEmailById = id => {
        return props.data.filter(cat => cat.id === id)[0]
    }

    const handleOpenDelete = (props) => {
        const email = getEmailById(props.meta.rowData[0])
        setSelectedEmail(email)

        setOpenDeleteDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedEmail(getEmailById(props.meta.rowData[0]))
        setOpenEditDialog(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'email', label: 'Email', options: { filter: false, searchable: true } },
        {
            name: 'subscribed',
            label: 'Subscrito?',
            options: {
                filter: true,
                searchable: false,
                customBodyRender: (value) => <p style={{ textAlign: 'center' }}>{value ? 'Sim' : 'Não'}</p>,
                customFilterListOptions: { render: v => `Subscrito: ${v}` },
                filterOptions: {
                    fullWidth: true,
                    names: ['Sim', 'Não'],
                    logic(status, filter) {
                        if (filter.length > 0) {
                            switch (filter[0]) {
                                case 'Sim': return status !== true;
                                case 'Não': return status !== false;
                                default: return false
                            }
                        }
                        return false;
                    }
                }
            }
        },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onDelete={handleOpenDelete} entity='Newsletter' /> } },
    ]

    const options = {
        viewColumns: false,
        filter: true,
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    };

    return (
        <>
            <TablePrefab data={props.data} columns={columns} loading={props.loading} customOptions={options} title='Emails newsletter' />
            {selectedEmail &&
                <>
                    <EditNewsletter open={openEditDialog} close={() => { setOpenEditDialog(false); setSelectedEmail(null) }} data={selectedEmail} refresh={props.refresh} triggerSnackbar={triggerSnackbar} />
                    <DeleteDialog onlyPermanent={true} open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Email' entityType='newsletter' nameField='email' data={selectedEmail} refresh={props.refresh} />
                </>
            }
        </>
    )
}

export default NewsletterTable