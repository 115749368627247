import React, { useEffect, useState } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { Divider, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import { HelpOutline as Help, Delete, CloudUpload as Upload } from '@mui/icons-material'
import CustomTooltip from '../../../Display/CustomTooltip'
import { projectsTooltips } from '../../../../TooltipHints'
import { findImageGCD } from '../../../../utils'

import '../Images.css'

const StyledButton = styled(Button)({
	color: 'white !important',
	backgroundColor: 'var(--dark-blue) !important',
	borderColor: 'white !important',
	margin: '20px 0 !important',
	'&:hover': {
		backgroundColor: 'white',
		color: 'var(--dark-blue)'
	}
})


function Images(props) {
	const [openBackgroundImageUploader, setOpenBackgroundImageUploader] = useState(false)
	const [openSpaceImageUploader, setOpenSpaceImageUploader] = useState(false)
	const [openProjectImageUploader, setOpenProjectImageUploader] = useState(false)
	const [backgroundImagePreview, setBackgroundImagePreview] = useState([])
	const [beforeImagePreview, setBeforeImagePreview] = useState([])
	const [afterImagePreview, setAfterImagePreview] = useState([])

	useEffect(() => {
		if (props.state.background_picture.length > 0) changeBackgroundImage(props.state.background_picture, true)
		if (props.state.before_pictures.length > 0 && props.state.split_display) handleMultiPicsUpload(props.state.before_pictures, 'before_pictures', true)
		if (props.state.after_pictures.length > 0) handleMultiPicsUpload(props.state.after_pictures, 'after_pictures', true)
	}, [])

	const changeBackgroundImage = (file, skipUpdateParent) => {

		if (skipUpdateParent) {
			setBackgroundImagePreview(file)
			return;
		}

		const picture = URL.createObjectURL(new Blob(file, { type: 'image/png' }))
		const image = new Image();
		const id = crypto.randomUUID()

		image.onload = () => {
			file[0].width = image.width;
			file[0].height = image.height;
			const GCD = findImageGCD(image.width, image.height);
			file[0].aspectRatio = `${image.width / GCD}:${image.height / GCD}`

			setBackgroundImagePreview([{ id, picture, info: file[0] }])
		}

		image.src = picture;

		props.updateStateOnParent('background_picture', [{ id, picture, info: file[0] }])
	}

	const handleMultiPicsUpload = (files, type, skipUpdateParent) => {

		if (skipUpdateParent) {
			type === 'before_pictures' ? setBeforeImagePreview(files) : setAfterImagePreview(files)
			return;
		}

		const imagesArr = []
		let counter = 0; // counter to only update parent state when all images are loaded

		files.forEach(file => {

			const picture = URL.createObjectURL(new Blob([file], { type: 'image/png' }))
			const image = new Image();

			image.onload = () => {
				file.width = image.width;
				file.height = image.height;
				const GCD = findImageGCD(image.width, image.height);
				file.aspectRatio = `${image.width / GCD}:${image.height / GCD}`

				const id = crypto.randomUUID()

				if (type === 'before_pictures') {
					setBeforeImagePreview(state => [...state, { id, picture, info: file }])
				} else {
					setAfterImagePreview(state => [...state, { id, picture, info: file }])
				}

				imagesArr.push({ id, picture, info: file })
				counter++;

				if (counter === files.length) {
					props.updateStateOnParent(type, [...props.state[type], ...imagesArr])
				}
			}

			image.src = picture;
		})
	}

	const removeBackgroundImage = () => {
		setBackgroundImagePreview([])

		props.updateStateOnParent('background_picture', [])
	}

	const handleRemoveImages = (img, type) => {
		let arr;

		if (type === 'before_pictures') {
			arr = beforeImagePreview.filter(image => image.id !== img.id)
			setBeforeImagePreview(arr)
		} else {
			arr = afterImagePreview.filter(image => image.id !== img.id)
			setAfterImagePreview(arr)
		}

		props.updateStateOnParent(type, arr)
	}

	return (
		<div className='add-dialog-images-upload-main-div'>
			<div className='project-info-dialog-title' >
				<p className='project-info-dialog-text-title'>Faça o upload das imagens deste projeto</p>
			</div>
			<div className='images-upload-divs'>
				<div className='main-image-upload-div'>
					<div className='image-upload-section-title'>
						<p className='project-info-dialog-text-subtitle'>Imagem de fundo</p>
						<CustomTooltip title={projectsTooltips.background_image}>
							<Help sx={{ marginLeft: '10px' }} fontSize='small' />
						</CustomTooltip>
					</div>
					<div className='main-image-inner-div'>
						{backgroundImagePreview.length === 0 ? (
							<StyledButton sx={{ alignSelf: 'flex-start' }} onClick={() => setOpenBackgroundImageUploader(true)} variant='outlined' startIcon={<Upload />}>Carregar Imagem</StyledButton>
						) : (
							<div className='project-upload-background-image-preview' style={{ backgroundImage: `url(${backgroundImagePreview[0].picture})` }}>
								<div className='image-action-div'>
									<CustomTooltip title='Remover imagem'>
										<IconButton sx={{ color: 'black' }} onClick={removeBackgroundImage}>
											<Delete htmlColor='white' />
										</IconButton>
									</CustomTooltip>
								</div>
								{backgroundImagePreview[0].info &&
									<div className='image-info-div'>
										<p>{`${backgroundImagePreview[0].info.name}`}</p>
										<p>{`${backgroundImagePreview[0].info.size} Bytes`}</p>
										<p>{`${backgroundImagePreview[0].info.width} X ${backgroundImagePreview[0].info.height} | ${backgroundImagePreview[0].info.aspectRatio}`}</p>
									</div>
								}
							</div>
						)}
					</div>
				</div>
				{props.state.split_display &&
					<>
						<Divider orientation='vertical' sx={{ height: '100%' }} />
						<div className='space-image-upload-div'>
							<div className='image-upload-section-title'>
								<p className='project-info-dialog-text-subtitle'>Imagens do espaço</p>
								<CustomTooltip title={projectsTooltips.space_images} >
									<Help sx={{ marginLeft: '10px' }} fontSize='small' />
								</CustomTooltip>
							</div>
							<div className='multiple-images-inner-div'>
								{!props.state.before_pictures || props.state.before_pictures.length < 5 ?
									<StyledButton onClick={() => setOpenSpaceImageUploader(true)} variant='outlined' startIcon={<Upload />}>Carregar Imagens</StyledButton> : ''
								}
								<div className='multiple-images-container'>
									{beforeImagePreview?.map(image => {
										return (
											<div key={image.picture} className='project-upload-multiple-image-preview' style={{ backgroundImage: `url(${image.picture})` }}>
												<div className='image-action-div'>
													<CustomTooltip title='Remover imagem'>
														<IconButton sx={{ color: 'black' }} onClick={() => handleRemoveImages(image, 'before_pictures')}>
															<Delete htmlColor='white' />
														</IconButton>
													</CustomTooltip>
												</div>
												{image.info &&
													<div className='image-info-div'>
														<p>{`${image.info.name}`}</p>
														<p>{`${image.info.size} Bytes`}</p>
														<p>{`${image.info.width} X ${image.info.height} | ${image.info.aspectRatio}`}</p>
													</div>
												}
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</>
				}
				<Divider orientation='vertical' sx={{ height: '100%' }} />
				<div className='project-image-upload-div'>
					<div className='image-upload-section-title'>
						<p className='project-info-dialog-text-subtitle'>Imagem do projeto</p>
						<CustomTooltip title={projectsTooltips.project_images}>
							<Help sx={{ marginLeft: '10px' }} fontSize='small' />
						</CustomTooltip>
					</div>
					<div className='multiple-images-inner-div'>
						{!props.state.after_pictures || props.state.after_pictures.length < 5 ?
							<StyledButton onClick={() => setOpenProjectImageUploader(true)} variant='outlined' startIcon={<Upload />}>Carregar Imagens</StyledButton> : ''
						}
						<div className='multiple-images-container'>
							{afterImagePreview?.map(image => {
								return (
									<div key={image.picture} className='project-upload-multiple-image-preview' style={{ backgroundImage: `url(${image.picture})` }}>
										<div className='image-action-div'>
											<CustomTooltip title='Remover imagem'>
												<IconButton sx={{ color: 'black' }} onClick={() => handleRemoveImages(image, 'after_pictures')}>
													<Delete htmlColor='white' />
												</IconButton>
											</CustomTooltip>
										</div>
										{image.info &&
											<div className='image-info-div'>
												<p>{`${image.info.name}`}</p>
												<p>{`${image.info.size} Bytes`}</p>
												<p>{`${image.info.width} X ${image.info.height} | ${image.info.aspectRatio}`}</p>
											</div>
										}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
			<BackgroundImageUploader open={openBackgroundImageUploader} close={() => setOpenBackgroundImageUploader(false)} changeBackgroundImage={changeBackgroundImage} />
			<SpaceImageUploader open={openSpaceImageUploader} close={() => setOpenSpaceImageUploader(false)} changeSpaceImages={handleMultiPicsUpload} currentLength={beforeImagePreview.length} />
			<ProjectImageUploader open={openProjectImageUploader} close={() => setOpenProjectImageUploader(false)} changeProjectImages={handleMultiPicsUpload} currentLength={afterImagePreview.length} />
		</div>
	)
}

export default Images

const BackgroundImageUploader = props => {
	return (
		<DropzoneDialog
			previewGridClasses={{ container: 'dropzone-preview-container', item: 'dropzone-preview-item' }}
			acceptedFiles={['image/*']}
			cancelButtonText='Cancelar'
			submitButtonText='Submeter'
			filesLimit={1}
			maxFileSize={2500000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeBackgroundImage(file); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Imagem de fundo'
			maxWidth='xs'
			dropzoneText='Arraste a imagem ou clique aqui para fazer upload'
			getFileLimitExceedMessage={() => 'Numero Máximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
			getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
			getFileRemovedMessage={() => 'Imagem removida com sucesso'}
			getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
		/>
	)
}

const SpaceImageUploader = props => {
	return (
		<DropzoneDialog
			previewGridClasses={{ container: 'dropzone-preview-container', item: 'dropzone-preview-item' }}
			acceptedFiles={['image/*']}
			cancelButtonText='Cancelar'
			submitButtonText='Submeter'
			filesLimit={5 - props.currentLength}
			maxFileSize={2500000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeSpaceImages(file, 'before_pictures'); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Imagens do espaço, MAX: 5'
			maxWidth='xs'
			dropzoneText='Arraste as imagens ou clique aqui para fazer upload'
			getFileLimitExceedMessage={(n) => `Numero Máximo de ficheiros excedido. Só poderá adicionar mais ${n} ficheiros`}
			getFileAddedMessage={(file) => `Imagem ${file} adicionada.`}
			getFileRemovedMessage={(file) => `Imagem ${file} removida.`}
			getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
		/>
	)
}

const ProjectImageUploader = props => {
	return (
		<DropzoneDialog
			previewGridClasses={{ container: 'dropzone-preview-container', item: 'dropzone-preview-item' }}
			dropzoneClass='dropzone-area'
			acceptedFiles={['image/*']}
			cancelButtonText='Cancelar'
			submitButtonText='Submeter'
			filesLimit={5 - props.currentLength}
			maxFileSize={2500000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeProjectImages(file, 'after_pictures'); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Imagens do Projeto, MAX: 5'
			maxWidth='xs'
			dropzoneText='Arraste as imagens ou clique aqui para fazer upload'
			getFileLimitExceedMessage={(n) => `Numero Máximo de ficheiros excedido. Só poderá adicionar mais ${n} ficheiros`}
			getFileAddedMessage={(file) => `Imagem ${file} adicionada.`}
			getFileRemovedMessage={(file) => `Imagem ${file} removida.`}
			getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
		/>
	)
}