import React, { Component } from 'react'
import axios from 'axios'
import { DialogContent } from '@mui/material'
import DialogPrefab from '../../DialogPrefab'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'

import '../../StepDialog.css'

export default class AddIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: '',
            icon: [],
            infoComplete: false,
            submitting: false,
        }

        this.initialState = {
            activeStep: 0,
            name: '',
            icon: '',
            infoComplete: false,
            submitting: false,
        }
    }

    resetState = () => {
        this.setState({ ...this.initialState })
    }


    updateState = (property, value) => {
        this.setState({ [property]: value }, () => {
            if (this.state.icon.length > 0 && this.state.name.length > 2) {
                this.setState({ infoComplete: true })
            } else {
                this.setState({ infoComplete: false })
            }
        })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const icon = this.state.icon.map(img => img.info)

        let formData = new FormData();
        formData.set('icon_name', this.state.name)
        formData.append('icon', icon[0])

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/icons`, formData, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
        }).then(res => {
            this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }


    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='xs'
                open={this.props.open}
                close={this.props.close}
                title='Adicionar novo Icon'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-inputs' style={{ height: 'fit-content', overflow: 'visible', margin: '0' }}>
                        <Info state={this.state} updateStateOnParent={this.updateState} />
                    </div>
                    <div className='add-dialog-action' style={{ justifyContent: 'center' }}>
                        <StandardButton disabled={!this.state.infoComplete} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}
