import React, { useState } from 'react'
import LanguageChanger from '../../../Display/LanguageChanger'

import '../Review.css'

function Review(props) {
    const [lang, setLang] = useState('pt')

    return (
        <div className='add-dialog-review-container'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>Reveja toda a informação antes de submeter</p>
            </div>
            <div className='add-dialog-review-main-div'>
                <div className='add-news-info-div'>
                    <div className='add-news-review-info' style={{ marginBottom: '15px'}}>
                        <InfoLabel label='Localidade' value={props.state.location} />
                        <InfoLabel label='Tema' value={props.state.news_theme?.name_pt} />
                        <InfoLabel label='Publicar após submeter?' value={props.state.published ? 'Sim' : 'Não'} />
                    </div>
                    <div className='add-news-review-info'>
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                            <LanguageChanger onLanguageChange={setLang} />
                        </div>
                        <InfoLabel label='Titulo' value={lang === 'pt' ? props.state.title_pt : props.state.title_en} />
                        <InfoLabel label='Texto da noticia' value={lang === 'pt' ? props.state.description_pt : props.state.description_en} />
                    </div>
                </div>
                <div className='add-dialog-review-images'>
                    <p style={{ textAlign: 'center', fontSize: '20px', marginBottom: '15px' }}>Imagens</p>
                    <ImagesInfo label='' data={props.state.image} />
                </div>
            </div>
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value }) => {
    return (
        <div className='review-label-div'>
            <p className='review-label-title'>{label}</p>
            <p className='review-label-value'>{value}</p>
        </div>
    )
}

const ImagesInfo = ({ label, data }) => {
    return (
        <div className='review-images-div'>
            <p className='review-images-title'>{label}</p>
            <div className='review-images-container'>
                {data?.map(elem => {
                    return (
                        <div key={elem.id} className='review-image-info' style={{ backgroundImage: `url(${elem.picture})`, width: '100%', height: '150px' }}>
                            <p>{elem.info.name}</p>
                            <p>{`${elem.info.size} bytes`}</p>
                            <p>{elem.info.aspectRatio}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}