import React, { useState } from 'react'
import StandardButton from '../Buttons/StandardButton'
import CustomToggleButton from '../Buttons/CustomToggleButton';
import SearchInput from '../Inputs/SearchInput';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useUserRole } from '../../Hooks/useUser';

import './PageTop.css'

const getPermissionEntity = (entity) => {
    switch(entity) {
        case 'Projetos': return 'Project';
        case 'Noticias': return 'News';
        case 'Tarefas': return 'Task';
        case 'Parceiros': return 'Partner'
        case 'Users': return 'User';
        case 'Categorias': return 'Category';
        case 'Temas': return 'Themes'
        default: return entity
    }
}

function PageTop(props) {
    const [openFilter, setOpenFilter] = useState(false)
    const { userRole } = useUserRole()

    const viewToggleButtonData = props.viewOptions ? props.viewOptions :
        [
            { label: 'Tabela', value: 'table' },
            { label: 'Cartas', value: 'cards' },
        ]

    return (
        <div className='page-top-main-div'>
            <div className='page-top-top-div'>
                <p className='page-top-title'>{props.title}</p>
                <div className='page-top-entity-info'>
                    {props.info.map(elem => {
                        return (
                            <EntityInfo key={elem.label} title={elem.label} value={elem.value} />
                        )
                    })}
                </div>
            </div>
            <div className='page-top-bottom-div'>
                {(props.addEntity && userRole[`canAdd${getPermissionEntity(props.title)}`]) &&
                    <StandardButton onClick={props.addEntity} light>{`Adicionar ${props.addName ? props.addName : props.title.slice(0, -1)}`}</StandardButton>
                }
                {props.customButton &&
                    <StandardButton onClick={props.customButton.func} light>{props.customButton.name}</StandardButton>
                }
                {props.onChangeSearch &&
                    <SearchInput value={props.searchValue} onChange={props.onChangeSearch} />
                }
                {props.changeView ? (
                    <div className='page-top-bottom-view-changer'>
                        <p>Vista</p>
                        <CustomToggleButton light value={props.view} onChange={props.changeView} data={viewToggleButtonData} />
                    </div>
                ) : <div style={{ width: '200px' }}></div>
                }
            </div>
            {props.filterComponent &&
                <div className={`${openFilter ? 'top-page-filter-panel' : 'top-page-filter-panel hidden'}`}>
                    <div className='top-page-filter-panel-handle' onClick={() => setOpenFilter(!openFilter)} >
                        {openFilter ? <ArrowUpward htmlColor='white' /> : <ArrowDownward htmlColor='white' />}
                    </div>
                    <div className={`${openFilter ? 'top-page-filter-content' : 'top-page-filter-content hidden'}`}>
                        <Divider orientation='horizontal' sx={{ backgroundColor: 'white', width: '43%', position: 'absolute', top: '10px', right: '20px' }} />
                        <Divider orientation='horizontal' sx={{ backgroundColor: 'white', width: '43%', position: 'absolute', top: '10px', left: '20px' }} />
                        <p className='top-page-filter-title'>Filtros</p>
                        {props.filterComponent}
                    </div>
                </div>
            }
        </div>
    )
}

export default PageTop

const EntityInfo = ({ title, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '200px', color: 'white' }}>
            <p style={{ fontSize: '18px' }}>{title}</p>
            <p style={{ fontSize: '32px' }}>{value}</p>
        </div>
    )
}
