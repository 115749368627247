import React, { useState } from 'react'
import { Construction, Engineering, Edit, Delete, Visibility, ThumbUpAlt, AddTask } from '@mui/icons-material'
import { Chip, Tooltip, IconButton } from '@mui/material'
import { setEntityOnlineOffline } from '../../../Api/Requests'
import Avatars from '../../Display/Partners/Avatars'
import { getPartnersFromProject, getProjectCompletePercentage, getProjectNumberOfContributions } from '../../../utils'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import ViewMore from '../../Dialogs/Projects/ViewMore'
import EditProject from '../../Dialogs/Projects/Edit/EditProject'
import AddContribution from '../../Dialogs/Contributions/Add/AddContribution'
import { useUserRole } from '../../../Hooks/useUser'

import './ProjectCard.css'

function ProjectCard({ project, refresh, ...props }) {
    const { showSnackbar } = useGlobalSnackbar()
    const projectPercentage = getProjectCompletePercentage(project)
    const projectPartners = getPartnersFromProject(project)
    const projectContributions = getProjectNumberOfContributions(project)
    const { userRole } = useUserRole()

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openViewMoreDialog, setOpenViewMoreDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openContDialog, setOpenContDialog] = useState(false)

    const setOnlineOffline = (project, online) => {
        setEntityOnlineOffline('projects', project.id, online).then(res => {
            showSnackbar(`O projeto ${project.title} está agora ${online ? 'Online' : 'Offline'}`, '', "success")
            refresh()
        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const triggerEditDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='project-card-main-div'>
            <div className='project-card-chip-div'>
                {project.online ?
                    userRole.canEditProject ?
                        <Tooltip disableInteractive title='Clique para tornar o projeto Offline'>
                            <Chip color='success' size='small' label='Online' variant='outlined' onClick={() => setOnlineOffline(project, false)} />
                        </Tooltip>
                        :
                        <Chip color='success' size='small' label='Online' variant='outlined' />
                    :
                    userRole.canEditProject ?
                        <Tooltip disableInteractive title='Clique para tornar o projeto Online'>
                            <Chip color='error' size='small' label='Offline' variant='outlined' onClick={() => setOnlineOffline(project, true)} />
                        </Tooltip>
                        :
                        <Chip color='error' size='small' label='Offline' variant='outlined' />
                }
            </div>
            <div className='project-card-left-info'>
                <Tooltip disableInteractive title={`Este projeto é constituído por ${project.tasks.length} tarefas`}>
                    <div className='left-info-tasks'>
                        <Construction sx={{ filter: 'drop-shadow(1px 1px 2px black)' }} htmlColor='white' />
                        <p style={{ marginLeft: '5px', textShadow: '1px 1px 2px black' }}>{project.tasks.length}</p>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={`${projectPartners.length} Parceiros já contribuíram para este projeto`}>
                    <div className='left-info-tasks'>
                        <Engineering sx={{ filter: 'drop-shadow(1px 1px 2px black)' }} htmlColor='white' />
                        <p style={{ marginLeft: '5px', textShadow: '1px 1px 2px black' }}>{projectPartners.length}</p>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={`Este projeto já conta com ${projectContributions} contribuições`}>
                    <div className='left-info-tasks'>
                        <ThumbUpAlt sx={{ filter: 'drop-shadow(1px 1px 2px black)' }} htmlColor='white' />
                        <p style={{ marginLeft: '5px', textShadow: '1px 1px 2px black' }}>{projectContributions}</p>
                    </div>
                </Tooltip>
            </div>
            <div className='project-card-image-div'>
                <img alt='project banner' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${project.background_picture}`} width='100%' height='auto' />
            </div>
            <div className='project-card-info-div'>
                <p className='project-card-title'>{project.title}</p>
                <div className='project-card-progress'>
                    <p>Progresso do projeto</p>
                    <div className='project-progress-bar'>
                        <div className='project-progress-bar-inner' style={{ width: `${projectPercentage}%` }} />
                    </div>
                    <p className='project-progress-bar-text'>{`${projectPercentage}%`}</p>
                </div>
                <div className='project-card-actions-div'>
                    {projectPartners.length > 0 &&
                        <p>Parceiros</p>
                    }
                    <div className='project-card-actions-inner'>
                        <Avatars clickable={true} data={project} max={7} color='white' />
                    </div>
                </div>
            </div>
            <div className='project-card-slider'>
                <CardActions
                    icon={<AddTask htmlColor='white' />}
                    tooltip='Adicionar Contribuição'
                    permission='canAddContribution'
                    action={() => setOpenContDialog(true)}
                />
                <CardActions
                    icon={<Edit htmlColor='white' />}
                    tooltip='Editar Projeto'
                    permission='canEditProject'
                    action={() => setOpenEditDialog(true)}
                />
                <Tooltip disableInteractive title='Ver Mais Info'>
                    <IconButton onClick={() => setOpenViewMoreDialog(true)}>
                        <Visibility htmlColor='white' />
                    </IconButton>
                </Tooltip>
                <CardActions
                    icon={<Delete htmlColor='white' />}
                    tooltip='Eliminar Projeto'
                    permission='canRemoveProject'
                    action={() => setOpenDeleteDialog(true)}
                />
            </div>
            <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Projeto' entityType='projects' nameField='title' data={project} refresh={refresh} />
            <ViewMore open={openViewMoreDialog} close={() => setOpenViewMoreDialog(false)} data={project} />
            <EditProject open={openEditDialog} close={() => setOpenEditDialog(false)} refresh={refresh} data={project} triggerSnackbar={triggerEditDialogSnackbar} />
            <AddContribution open={openContDialog} close={() => setOpenContDialog(false)} refresh={refresh} task={null} tasksData={project.tasks} triggerSnackbar={triggerEditDialogSnackbar} partnersData={props.partnersData} addPartner={props.addPartner} />
        </div>
    )
}

export default ProjectCard

const CardActions = ({ icon, tooltip, permission, action }) => {
    const { userRole } = useUserRole()

    if (!userRole[permission]) return ''

    return (
        <Tooltip disableInteractive title={tooltip}>
            <IconButton onClick={action}>
                {icon}
            </IconButton>
        </Tooltip>
    )
}