import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import NewsFilter from '../Components/Panels/FilterPanels/NewsFilter'
import { getEntity } from '../Api/Requests'
import NewsCard from '../Components/Cards/News/NewsCard'
import { Grid } from 'react-loading-icons'
import { useDebounce } from '../utils'
import NewsTable from '../Components/Tables/NewsTable'
import AddNews from '../Components/Dialogs/News/Add/AddNews'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import AddNewsTheme from '../Components/Dialogs/NewsThemes/Add/AddNewsTheme'
import { getUserSettings, setUserSettings } from '../UserSettings'

import './css/News.css'

const reduceViewsAndShares = (news, param) => {
    if (!news || news.length === 0) return 0
    return news.reduce((prev, current) => {
        return { [param]: prev[param] + current[param] }
    })
}

function News() {
    const [view, setView] = useState(getUserSettings('news').view.view)
    const [search, setSearch] = useState('')
    const [news, setNews] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [immutableNews, setImmutableNews] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openAddNews, setOpenAddNews] = useState(false)
    const [openAddThemes, setOpenAddThemes] = useState(false)
    const [themesData, setThemesData] = useState([])
    const { showSnackbar } = useGlobalSnackbar();
    const debounceSearch = useDebounce(search, 500)

    const topPageInfo = [
        { label: 'Total de Noticias', value: immutableNews.length },
        { label: 'Total de visitas', value: reduceViewsAndShares(news, 'views').views },
        { label: 'Total de Partilhas', value: reduceViewsAndShares(news, 'shares').shares },
        { label: 'Noticias Publicadas', value: immutableNews.filter(x => x.published).length }
    ]

    useEffect(() => {
        setLoading(true)
        getEntity('news').then(res => {
            setNews(res.data)
            setFilteredData(res.data)
            setImmutableNews(res.data)
            refreshSingleEntity()
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }, [refresh])

    const refreshSingleEntity = () => {
        getEntity('newsTheme').then(res => {
            setThemesData(res.data.filter(theme => !theme.hide))
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))
    }

    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setNews([...filteredData.filter(news => news.title_pt.toLowerCase().includes(search.toLowerCase()))])
                return;
            }
            setNews(filteredData)
        }
    }, [debounceSearch])

    const updateFilteredData = (data) => {
        setFilteredData(data);
        setNews(data)
    }

    const triggerAddDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='news-main-div'>
            <PageTop
                title='Noticias'
                info={topPageInfo}
                view={view}
                changeView={(view) => { setView(view); setUserSettings('news', 'view', 'view', view) }}
                searchValue={search}
                onChangeSearch={(value) => setSearch(value)}
                filterComponent={<NewsFilter data={immutableNews} setData={updateFilteredData} />}
                addEntity={() => setOpenAddNews(true)}
            />
            <div className='news-container'>
                {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> :
                    news.length === 0 ? <p>Sem resultados para apresentar</p> :
                        view === 'cards' ? (
                            news.map(news => {
                                return (
                                    <NewsCard key={news.id} news={news} refresh={() => setRefresh(!refresh)} themesData={themesData} addTheme={() => setOpenAddThemes(true)} />
                                )
                            })
                        ) : (
                            <NewsTable data={news} refresh={() => setRefresh(!refresh)} themesData={themesData} addTheme={() => setOpenAddThemes(true)} />
                        )}
            </div>
            <AddNews
                open={openAddNews}
                close={() => setOpenAddNews(false)}
                refresh={() => setRefresh(!refresh)}
                triggerSnackbar={triggerAddDialogSnackbar}
                themesData={themesData}
                addTheme={() => setOpenAddThemes(true)}
            />
            <AddNewsTheme open={openAddThemes} close={() => setOpenAddThemes(false)} refresh={() => refreshSingleEntity()} triggerSnackbar={triggerAddDialogSnackbar} />
        </div>
    )
}

export default News