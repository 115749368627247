import React from 'react'

import './DashboardCard.css'

function DashboardCard({ title, text, data }) {
    return (
        <div className='dashboard-card-main-div'>
            <div className='dashboard-card-info'>
                <p className='dashboard-card-title'>{title}</p>
                <p className='dashboard-card-text'>{text}</p>
            </div>
        </div>
    )
}

export default DashboardCard