import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'
import BigSwitch from '../../../Switches/TableSwitch'
import { CustomAutoComplete } from '../../../Inputs/CustomAutoComplete'
import { Divider, FormControlLabel } from '@mui/material';
import { tasksTooltips } from '../../../../TooltipHints';
import CustomTooltip from '../../../Display/CustomTooltip';
import IconPicker from '../../Icons/IconPicker';
import { CloudUpload } from '@mui/icons-material'

import '../Info.css'

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            title_ptIsValid: false,
            title_enIsValid: false,
            formIsValid: false,
            formErrors: { title_pt: '', title_en: '' },
            openIcons: false
        }
    }

    componentDidMount() {
        if (this.state.infoComplete) {
            this.setState({
                title_ptIsValid: true,
                title_enIsValid: true,
                formErrors: { title_pt: '', title_en: '' },
                openIcons: false
            })
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    handleCheckboxInput = event => {
        const name = event.target.name;
        const value = event.target.checked;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.validateForm())
    }

    handleAutocompleteInput = (field, data) => {
        this.props.updateStateOnParent(field, data)
        this.setState({ [field]: data }, () => this.validateForm())
    }

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let title_ptIsValid = this.state.title_ptIsValid;
        let title_enIsValid = this.state.title_enIsValid;

        switch (name) {
            case 'title_pt':
                title_ptIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s,-.+]*$/).test(value) && value.length > 4;
                fieldValidationErrors.title_pt = title_ptIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um nome válido`}`
                break;
            case 'title_en':
                title_enIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s,-.+]*$/).test(value) && value.length > 4;
                fieldValidationErrors.title_en = title_enIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um nome válido`}`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            title_ptIsValid: title_ptIsValid,
            title_enIsValid: title_enIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.title_ptIsValid &&
                this.state.title_enIsValid &&
                this.state.milestone > 0 &&
                this.state.project !== null &&
                this.state.category !== null &&
                this.state.icon !== null
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    render() {
        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' >
                    <p className='project-info-dialog-text-title'>Preencha o seguinte formulário para poder criar uma nova tarefa.</p>
                </div>
                <div className='project-info-form'>
                    <div className='task-info-left-div'>
                        <TextField
                            size='small'
                            label='Nome da tarefa em Português'
                            name='title_pt'
                            value={this.state.title_pt}
                            onChange={this.handelInput}
                            autoFocus
                            required
                            error={!this.state.title_ptIsValid}
                            helperText={this.state.formErrors.title_pt}
                        />
                        <TextField
                            size='small'
                            label='Nome da tarefa em Inglês'
                            name='title_en'
                            value={this.state.title_en}
                            onChange={this.handelInput}
                            required
                            error={!this.state.title_enIsValid}
                            helperText={this.state.formErrors.title_en}
                        />
                        <div className='autocomplete-plus-add-button'>
                            <CustomAutoComplete
                                OptionLabel='name_pt'
                                size='small'
                                fullWidth
                                sx={{ margin: '10px 0' }}
                                data={this.props.categoriesData}
                                multiple={false}
                                id='project'
                                label='Categoria'
                                value={this.state.category}
                                onChange={(id, value) => this.handleAutocompleteInput('category', value)}
                                error={!this.state.category}
                            />
                            <CustomTooltip title='Adicionar nova categoria'>
                                <button className='add-more-button' onClick={this.props.addCategory}>+</button>
                            </CustomTooltip>
                        </div>
                        <div className='autocomplete-plus-add-button'>
                            <CustomAutoComplete
                                OptionLabel='title'
                                size='small'
                                fullWidth
                                sx={{ margin: '10px 0' }}
                                data={this.props.projectsData}
                                multiple={false}
                                id='project'
                                label='Projeto'
                                value={this.state.project}
                                onChange={(id, value) => this.handleAutocompleteInput('project', value)}
                                error={!this.state.project}
                            />
                            <CustomTooltip title='Adicionar novo projeto'>
                                <button className='add-more-button' onClick={this.props.addProject}>+</button>
                            </CustomTooltip>
                        </div>
                    </div>
                    <Divider orientation='vertical' sx={{ height: 'inherit' }} />
                    <div className='task-info-right-div'>
                        <TextField
                            size='small'
                            label='Objetivo'
                            name='milestone'
                            type='number'
                            fullWidth={false}
                            value={this.state.milestone}
                            onChange={this.handelInput}
                            required
                            error={this.state.milestone < 1}
                        />
                        <div className='info-form-checkbox'>
                            <CustomTooltip title={tasksTooltips.online}>
                                <FormControlLabel
                                    label='Online?'
                                    control={<BigSwitch
                                        sx={{ marginRight: '10px' }}
                                        name='online'
                                        value={this.state.online}
                                        checked={this.state.online}
                                        onChange={this.handleCheckboxInput}
                                    />}
                                />
                            </CustomTooltip>
                            <CustomTooltip title={tasksTooltips.localCraft}>
                                <FormControlLabel
                                    label='Artesanato Local?'
                                    control={<BigSwitch
                                        sx={{ marginRight: '10px' }}
                                        name='local_craft'
                                        value={this.state.local_craft}
                                        checked={this.state.local_craft}
                                        onChange={this.handleCheckboxInput}
                                    />}
                                />
                            </CustomTooltip>
                        </div>
                        <div className='task-icon-picker'>
                            <div className='task-icon' onClick={() => this.setState({ openIcons: true })} tabIndex={1} onKeyPress={e => { if (e.key === 'Enter') this.setState({ openIcons: true }) }}>
                                {this.state.icon ? (
                                    <img src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${this.state.icon.icon}`} alt={this.state.icon.icon_name} height='100%' width='100%' />
                                ) : <CloudUpload fontSize='large' />}
                            </div>
                            {!this.state.icon ?
                                <p>Clique para escolher um icon para a tarefa</p> : <p>{this.state.icon.icon_name}</p>
                            }
                        </div>
                    </div>
                </div>
                <IconPicker open={this.state.openIcons} close={() => this.setState({ openIcons: false })} pickIcon={(icon) => this.handleAutocompleteInput('icon', icon)} />
            </div>
        )
    }
}


