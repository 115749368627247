import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getEntity } from '../Api/Requests'
import { useUserRole } from '../Hooks/useUser'
import ProjectStatsPanel from '../Components/Panels/ProjectStatsPanel'
import NewsStatsPanel from '../Components/Panels/NewsStatsPanel'
import ContributionsStatsPanel from '../Components/Panels/ContributionsStatsPanel'
import TasksStatsPanel from '../Components/Panels/TasksStatsPanel'


import './css/Reports.css'

function Reports() {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const { userRole } = useUserRole()

    useEffect(() => {
        setLoading(true)

        const fetch = async () => {
            getEntity('stats').then(res => {
                setData(res.data)
                setLoading(false)
            }, err => {
                console.log(err)
                setLoading(false)
            })
        }

        if (!userRole.canViewStatistics) {
            navigate('/dashboard', { replace: true })
        } else {
            fetch();
        }
    }, [])

    return (
        <div className='reports-main-div'>
            <div className='reports-top-div'>
                <EntityInfo title='Total de Contribuições' value={data?.counts.contributions} />
                <EntityInfo title='Total de Projetos' value={data?.counts.projects} />
                <EntityInfo title='Total de Noticias' value={data?.counts.news} />
                <EntityInfo title='Visualizações de Projetos' value={data?.counts.projectViews} />
                <EntityInfo title='Visualizações de Noticias' value={data?.counts.newsViews} />
                <EntityInfo title='Partilhas de Projetos' value={data?.counts.projectShares} />
                <EntityInfo title='Partilhas de Noticias' value={data?.counts.newsShares} />
            </div>
            <div className='reports-content-div'>
                {/* <div className='reports-google-analytics'>
                    Google Analytics here
                </div> */}
                {data &&
                    <>
                        <div className='reports-projects-charts'>
                            <ProjectStatsPanel data={data.data.projects} loading={loading} />
                        </div>
                        <div className='reports-news-charts'>
                            <NewsStatsPanel data={data.data.news} loading={loading} />
                        </div>
                        <div className='reports-contributions-charts'>
                            <ContributionsStatsPanel data={data.data.contributions} loading={loading} />
                        </div>
                        <div className='reports-tasks-charts'>
                            <TasksStatsPanel data={data.data.tasks} categories={data.data.categories} />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Reports

const EntityInfo = ({ title, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '200px', color: 'white' }}>
            <p style={{ fontSize: '18px' }}>{title}</p>
            <p style={{ fontSize: '32px' }}>{value}</p>
        </div>
    )
}