import React, { useState, useEffect } from 'react'
import DialogPrefab from '../../DialogPrefab'
import { Button, Tabs, Tab } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { getUser, getEntity } from '../../../../Api/Requests'
import portraitPlaceholder from '../../../../assets/placeholders/portrait_placeholder.png'
import moment from 'moment'
import EditProfile from './EditProfile'
import PasswordChange from './PasswordChange'
import useGlobalSnackbar from '../../../../Hooks/useGlobalSnackbar'
import { DropzoneDialog } from 'material-ui-dropzone'
import axios from 'axios'
import FormData from 'form-data'

import './Profile.css'

function Profile(props) {
    const [user, setUser] = useState(null)
    const [showSideView, setShowSideView] = useState(false)
    const [tab, setTab] = useState(0)
    const [userRolesData, setUserRolesData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [openImageUploader, setOpenImageUploader] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()

    useEffect(() => {
        if (props.userID) {
            getUser(props.userID).then(res => {
                setUser(res.data)
            }, err => {
                console.log(err)
            })

            getEntity('userRoles').then(res => {
                setUserRolesData(res.data)
            }, err => {
                console.log(err)
            })
        }
    }, [props.userID, refresh])

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const changePortrait = (file) => {

        let formData = new FormData();
        formData.append('picture', file[0])

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/addpicture/${user.id}`, formData, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
        }).then(res => {
            triggerSnackbar({ err: false, response: res.data })
            setRefresh(!refresh);
        }, err => {
            console.log(err)
            triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
        })
    }

    const removePortrait = () => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/removepicture/${user.id}`, {}, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            triggerSnackbar({ err: false, response: res.data })
            setRefresh(!refresh);
        }, err => {
            console.log(err)
            triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth={showSideView ? 'md' : 'xs'}
            title='O Meu Perfil'
        >
            {user &&
                <div className='profile-main-div'>
                    <div className={`profile-portrait-div ${showSideView ? 'small' : 'large'}`}>
                        <div className='profile-userType-wrapper-div'>
                            <div className='userType-div-user-card'>{user.user_role.name}</div>
                        </div>
                        <div className='profile-picture-div'>
                            <div className='profile-portrait-actions'>
                                {user.picture && user.picture.length > 0 ? (
                                    <>
                                        <Button sx={{ width: '100px', marginBottom: '5px', backgroundColor: '#ffffff69', '&:hover': { backgroundColor: 'rgb(25 118 210 / 23%)', color: 'white' } }} variant='outlined' size='small' onClick={() => setOpenImageUploader(true)} >Alterar</Button>
                                        <Button sx={{ width: '100px', marginTop: '5px', backgroundColor: '#ffffff69', '&:hover': { backgroundColor: 'rgb(25 118 210 / 23%)', color: 'white' } }} variant='outlined' size='small' onClick={removePortrait} >Remover</Button>
                                    </>
                                ) : (
                                    <Button sx={{ width: '100px', marginBottom: '5px', '&:hover': { backgroundColor: 'rgb(25 118 210 / 23%)' } }} variant='outlined' size='small' onClick={() => setOpenImageUploader(true)} >Adicionar Imagem</Button>
                                )}
                            </div>
                            {user.picture && user.picture.length > 0 ? (
                                <img alt='user portrait' style={{ objectFit: 'cover' }} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${user.picture}`} width='100%' height='100%' />
                            ) : (
                                <img alt='user portrait' src={portraitPlaceholder} width='100%' height='100%' />
                            )}
                        </div>
                        <div className='profile-bottom-div'>
                            <p className='users-name'>{user.name}</p>
                            <p className='users-job' style={{ color: 'var(--light-beige)' }}>{user.email}</p>
                            {moment(user.last_login).isValid() &&
                                <p className='users-last-login' style={{ color: 'var(--light-beige)' }}>{`Last login: ${moment(user.last_login).format('YYYY-MM-DD H:mm:ss')}`}</p>
                            }
                        </div>
                        <div className='profile-actions'>
                            <Button variant='outlined' onClick={() => setShowSideView(!showSideView)} startIcon={<Edit />}>{showSideView ? 'Fechar Editar' : 'Editar'}</Button>
                        </div>
                    </div>
                    <div className={`profile-info-div ${showSideView ? 'show' : 'hide'}`}>
                        <Tabs value={tab} onChange={(e, value) => setTab(value)} centered>
                            <Tab label='Editar Info' />
                            <Tab label='Alterar Password' />
                        </Tabs>
                        <div className='profile-edit-div'>
                            <TabPanel value={tab} index={0}>
                                <EditProfile
                                    activeUser={user}
                                    user={user}
                                    userRolesData={userRolesData}
                                    refresh={() => setRefresh(!refresh)}
                                    triggerSnackbar={triggerSnackbar}
                                />
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <PasswordChange
                                    user={user}
                                    triggerSnackbar={triggerSnackbar} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            }
            <ImageUploader open={openImageUploader} close={() => setOpenImageUploader(false)} changeImage={changePortrait} />
        </DialogPrefab>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ display: 'flex', justifyContent: 'center' }}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

const ImageUploader = props => {
    return (
        <DropzoneDialog
            previewGridClasses={{ container: 'dropzone-preview-container', item: 'dropzone-preview-item' }}
            acceptedFiles={['image/*']}
            cancelButtonText='Cancelar'
            submitButtonText='Submeter'
            filesLimit={1}
            maxFileSize={1000000}
            open={props.open}
            onClose={() => props.close()}
            onSave={(file) => { props.changeImage(file); props.close() }}
            showPreviews={false}
            showPreviewsInDropzone={true}
            dialogTitle='Upload Logo'
            maxWidth='xs'
            dropzoneText='Arraste a Imagem ou clique aqui para fazer upload'
            getFileLimitExceedMessage={() => 'Numero Máximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
            getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
            getFileRemovedMessage={() => 'Imagem removida com sucesso'}
            getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas até um máximo de 1mb.'}
        />
    )
}

export default Profile

