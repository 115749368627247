import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import Projects from '../Components/Tables/Trash/Projects'
import News from '../Components/Tables/Trash/News'
import Tasks from '../Components/Tables/Trash/Tasks'
import useDialog from '../Components/Dialogs/useDialog'
import { getUserSettings, setUserSettings } from '../UserSettings'

import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'

import './css/Recycle.css'
import { getEntity, removeAllFromTrash, removeFromTrash, restoreFromTrash } from '../Api/Requests'


const viewOptions = [
    { label: 'Projetos', value: 'projects' },
    { label: `Noticias`, value: 'news' },
    { label: 'Tarefas', value: 'tasks' },
]


function Recycle() {
    const [trash, setTrash] = useState()
    const [view, setView] = useState(getUserSettings('trash').view.view)
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const { showSnackbar } = useGlobalSnackbar();
    const { openInfoDialog } = useDialog()

    useEffect(() => {
        setLoading(true)
        getEntity('trash').then(res => {
            setTrash(res.data)
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            setLoading(false)
        })
    }, [refresh])


    const topPageInfo = [
        { label: 'Total de projetos', value: trash?.projects.length },
        { label: 'Total de noticias', value: trash?.news.length },
        { label: 'Noticias tarefas', value: trash?.tasks.length }
    ]

    const entityConfig = {
        projects: { name: 'projects', component: <Projects data={trash?.projects} loading={loading} refresh={() => setRefresh(!refresh)} restore={(entity, ids) => handleRestore(entity, ids)} delete={(entity, ids) => handleDelete(entity, ids)} /> },
        news: { name: 'news', component: <News data={trash?.news} loading={loading} refresh={() => setRefresh(!refresh)} restore={(entity, ids) => handleRestore(entity, ids)} delete={(entity, ids) => handleDelete(entity, ids)} /> },
        tasks: { name: 'tasks', component: <Tasks data={trash?.tasks} loading={loading} refresh={() => setRefresh(!refresh)} restore={(entity, ids) => handleRestore(entity, ids)} delete={(entity, ids) => handleDelete(entity, ids)} /> }
    }

    const handleDelete = (entity, ids) => {
        const infoDialogData = { title: 'Tem a certeza?', message: `Tem a certeza que pretende eliminar ${entity} ${ids.join(', ')}?` };
        openInfoDialog(infoDialogData, 'y/n', () => deleteEntity(entity, ids))
    }

    const handleRestore = (entity, ids) => {
        const infoDialogData = { title: 'Tem a certeza?', message: `Tem a certeza que pretende restaurar ${entity} ${ids.join(', ')}?` };
        openInfoDialog(infoDialogData, 'y/n', () => restoreEntity(entity, ids))
    }

    const handleRemoveAll = () => {
        const infoDialogData = { title: 'Tem a certeza?', message: `Tem a certeza que pretende eliminar todos os items da reciclagem?` };
        openInfoDialog(infoDialogData, 'y/n', () => removeAll())
    }

    const deleteEntity = (entity, ids) => {
        setLoading(true)
        removeFromTrash(ids, entity).then((res) => {
            showSnackbar(res.data, '', "success")
            setRefresh(!refresh)
            setLoading(false)
        }, (err) => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
            setLoading(false)
        });
    }

    const restoreEntity = (entity, ids) => {
        setLoading(true)
        restoreFromTrash(ids, entity).then((res) => {
            console.log(res)
            showSnackbar(res.data, '', "success")
            setRefresh(!refresh)
            setLoading(false)
        }, (err) => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
            setLoading(false)
        });
    }

    const removeAll = () => {
        setLoading(true)
        removeAllFromTrash().then(res => {
            console.log(res)
            showSnackbar(res.data, '', "success")
            setRefresh(!refresh)
            setLoading(false)
        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
            setLoading(false)
        })
    }

    return (
        <div className='news-main-div'>
            <PageTop
                title='Reciclagem'
                info={topPageInfo}
                view={view}
                viewOptions={viewOptions}
                changeView={(view) => { setView(view); setUserSettings('trash', 'view', 'view', view) }}
                customButton={{ name: 'Limpar reciclagem', func: () => handleRemoveAll() }}
            />
            <div className='news-container'>
                {(trash && view) && entityConfig[view]?.component}
            </div>
        </div>
    )
}

export default Recycle