import React from 'react'
import ContributionsChart from '../Charts/contributions/ContributionsChart'
import StatsContributionsTimeLine from '../Display/Contributions/StatsContributionsTimeLine'
import { Grid } from 'react-loading-icons'


function ContributionsStatsPanel(props) {
    return (
        <div className='projects-stats-panel-main-div'>
            <div className='projects-stats-panel-title'>
                <p>Contribuições</p>
            </div>
            {props.loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' style={{ marginTop: '150px' }} /> :
                <div className='projects-stats-panel-content'>
                    <div className='projects-charts-div' style={{ width: '70%', height: '500px' }}>
                        <ContributionsChart data={props.data} title='Contribuições de parceiros' />
                    </div>
                    <div className='contribution-timeline'>
                        <StatsContributionsTimeLine data={props.data} />
                    </div>
                </div>
            }
        </div>
    )

}

export default ContributionsStatsPanel
