import React, { useEffect, useState } from 'react'

import '../Review.css'

function Review(props) {
	const [changes, setChanges] = useState(false)

	useEffect(() => {
		if (Object.keys(props.state.string).length > 0 ||
			Object.keys(props.state.boolean).length > 0 ||
			Object.keys(props.state.object).length > 0) {
			setChanges(true)
		}
	}, [props.state])

	return (
		<div className='add-dialog-review-container'>
			<div className='project-info-dialog-title' >
				<p className='project-info-dialog-text-title'>{changes ? 'Foram detetadas as seguintes alterações' : 'Não foram detetadas quaisquer alterações'}</p>
			</div>
			{changes &&
				<div className='add-dialog-review-main-div'>
					<div className='add-dialog-review-info' style={Object.keys(props.state.object).length === 0 ? { width: '100%' } : {}}>
						{Object.entries(props.state.string).map(([key, value]) => {
							return (
								<InfoLabel key={key} label={getCorrespondingLabel(key)} value={typeof value === 'string' ? value : value.name} />
							)
						})}
						{Object.entries(props.state.boolean).map(([key, value]) => {
							return (
								<InfoLabel key={key} label={getCorrespondingLabel(key)} value={value ? 'Sim' : 'Não'} />
							)
						})}
					</div>
					{Object.keys(props.state.object).length > 0 &&
						<div className='add-dialog-review-images'>
							<p style={{ textAlign: 'center', fontSize: '20px', marginBottom: '15px' }}>Imagens</p>
							{props.state.object.background_picture &&
								<ImagesInfo label='Imagem de fundo' data={props.state.object.background_picture} />
							}
							{props.state.boolean.split_display && props.state.object.before_pictures &&
								<ImagesInfo label='Imagem do Espaço' data={props.state.object.before_pictures} />
							}
							{props.state.object.after_pictures &&
								<ImagesInfo label='Imagem do Projeto' data={props.state.object.after_pictures} />
							}
						</div>
					}
				</div>
			}
		</div>
	)
}

export default Review

const InfoLabel = ({ label, value }) => {
	return (
		<div className='review-label-div'>
			<p className='review-label-title'>{label}</p>
			<p className='review-label-value'>{value}</p>
		</div>
	)
}

const ImagesInfo = ({ label, data }) => {

	return (
		<div className='review-images-div'>
			<p className='review-images-title'>{label}</p>
			<div className='review-images-container'>
				{data?.map(elem => {
					return (
						<div key={elem.id} className='review-image-info' style={{ backgroundImage: `url(${elem.picture})` }}>
							<p>{elem.info.name}</p>
							<p>{`${elem.info.size} bytes`}</p>
							<p>{elem.info.aspectRatio}</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const getCorrespondingLabel = (label) => {
	switch (label) {
		case 'title': return 'Nome do Projeto';
		case 'address': return 'Morada'
		case 'location': return 'Localização';
		case 'vr_link': return 'Link Vr'
		case 'space_description_pt': return 'Descrição do espaço em Português';
		case 'space_description_en': return 'Descrição do espaço em Inglês';
		case 'project_description_pt': return 'Descrição do projeto em Português';
		case 'project_description_en': return 'Descrição do projeto em Inglês';
		case 'main_page': return 'Pagina inicial?'
		case 'split_display': return 'Vista "Antes e Depois"'
		default: return label
	}
} 
