import React from 'react'
import { ListItem, ListItemIcon, ListItemText, List } from '@mui/material'
import { Link } from 'react-router-dom'
import { useUserRole } from '../../../Hooks/useUser'

import {
    DashboardOutlined as DashboardIcon,
    PeopleAltOutlined as PartnersIcon,
    PersonOutlineOutlined as UserIcon,
    FormatListBulletedOutlined as ListIcon,
    HelpOutlineOutlined as HelpIcon,
    Delete as RecycleIcon,
    Construction as TasksIcon,
    Store as ProjectsIcon,
    Newspaper as NewsIcon,
    Assessment as ReportsIcon,
    Email as NewsletterIcon
} from '@mui/icons-material'

const listItemStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', '&:hover': { transform: 'scale(1.1)' } }
const listItemStyleActive = { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.3)', cursor: 'default', '&:hover': { backgroundColor: 'rgba(255,255,255,0.3)' } }
const iconStyle = { display: 'flex', justifyContent: 'center', color: 'white' }
const textStyle = { '&>span': { fontSize: '12px', color: 'white' } }

//TODO: preventdefault on onContextMenu e aparecer um menu com opções relacionadas com a tab

export const MainListItems = (props) => {

    return (
        <List sx={{ display: 'flex', flexDirection: 'column' }}>
            <ListItem
                sx={props.activeTab === 'dashboard' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('dashboard')}
                button
                component={Link}
                to={{ pathname: '/dashboard' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Dashboard' />
            </ListItem>
            <ListItem
                sx={props.activeTab === 'projects' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('projects')}
                button
                component={Link}
                to={{ pathname: '/projects' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <ProjectsIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Projetos' />
            </ListItem>
            <ListItem
                sx={props.activeTab === 'news' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('news')}
                button
                component={Link}
                to={{ pathname: '/news' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <NewsIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Noticias' />
            </ListItem>
            <ListItem
                sx={props.activeTab === 'tasks' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('tasks')}
                button
                component={Link}
                to={{ pathname: '/tasks' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <TasksIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Tarefas' />
            </ListItem>
            <ListItem
                sx={props.activeTab === 'partners' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('partners')}
                button
                component={Link}
                to={{ pathname: '/partners' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <PartnersIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Parceiros' />
            </ListItem>
        </List>
    )
}

export const SecondaryListItems = (props) => {
    const { userRole } = useUserRole()

    return (
        <List sx={{ display: 'flex', flexDirection: 'column' }}>
            {userRole.canViewUser &&
                <ListItem
                    sx={props.activeTab === 'users' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('users')}
                    button
                    component={Link}
                    to={{ pathname: '/users' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <UserIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Users' />
                </ListItem>
            }
            <ListItem
                sx={props.activeTab === 'lists' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('lists')}
                button
                component={Link}
                to={{ pathname: '/lists' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <ListIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Listas' />
            </ListItem>
            <ListItem
                sx={props.activeTab === 'newsletter' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('newsletter')}
                button
                component={Link}
                to={{ pathname: '/newsletter' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <NewsletterIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Newsletter' />
            </ListItem>
            {userRole.canViewStatistics &&
                <ListItem
                    sx={props.activeTab === 'reports' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('reports')}
                    button
                    component={Link}
                    to={{ pathname: '/reports' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <ReportsIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Estatísticas' />
                </ListItem>
            }
        </List>
    )
}

export const BottomListItems = (props) => {
    const { userRole } = useUserRole()

    return (
        <List sx={{ display: 'flex', flexDirection: 'column' }}>
            {userRole.canManageTrash &&
                <ListItem
                    sx={props.activeTab === 'recycle' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('recycle')}
                    button
                    component={Link}
                    to={{ pathname: '/recycle' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <RecycleIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Lixo' />
                </ListItem>
            }
            <ListItem
                sx={props.activeTab === 'help' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('help')}
                button
                component={Link}
                to={{ pathname: '/help' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Ajuda' />
            </ListItem>
        </List>
    )
}