import React, { useState } from 'react'

import ptFlag from '../../assets/misc/pt.svg'
import ukFlag from '../../assets/misc/uk.svg'

import './LanguageChanger.css'

function LanguageChanger({ onLanguageChange }) {
    const [languageSelected, setLanguageSelected] = useState('pt')

    const handleLanguageChange = (lang) => {
        onLanguageChange(lang)
        setLanguageSelected(lang)
    }

    return (
        <div className='language-changer-main-div'>
            <img className={`flag ${languageSelected === 'pt' ? 'active' : 'inactive'}`} alt='Português' src={ptFlag} width='50%' height='auto' onClick={() => handleLanguageChange('pt')} />
            <img className={`flag ${languageSelected === 'en' ? 'active' : 'inactive'}`} alt='English' src={ukFlag} width='50%' height='auto' onClick={() => handleLanguageChange('en')} />
        </div>
    )
}

export default LanguageChanger