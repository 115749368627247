import React, { useEffect, useState } from 'react'
import './TaskCard.css'
import Avatars from '../../Display/Partners/Avatars'
import { Divider, Chip } from '@mui/material'
import ViewMoreTask from '../../Dialogs/Tasks/ViewMoreTask'
import { Edit, Delete, Visibility, AddTask } from '@mui/icons-material'
import CustomTooltip from '../../Display/CustomTooltip'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { setEntityOnlineOffline } from '../../../Api/Requests'
import EditTask from '../../Dialogs/Tasks/Edit/EditTask'
import AddContribution from '../../Dialogs/Contributions/Add/AddContribution'
import { useUserRole } from '../../../Hooks/useUser'
import placeholderIcon from '../../../assets/placeholders/logo_placeholder_1.png'

export function SmallTaskCard({ task, lang, ...props }) {
    const [percentage, setPercentage] = useState(0)
    const [current, setCurrent] = useState(0)
    const [openViewMore, setOpenViewMore] = useState(false)

    useEffect(() => {
        if (task.contributions.length === 0) return;

        let count = 0;

        task.contributions.forEach(x => {
            count += x.quantity
        })

        setCurrent(count)
        const percentage = (count / task.milestone) * 100;

        setPercentage(Math.floor(percentage * 100) / 100)
    }, [task])

    return (
        <>
            <div className='task-small-card-container' onClick={() => setOpenViewMore(true)} >
                <div className='task-small-card-icon-div'>
                    <img className='task-small-card-image' alt={task.title_pt} src={task.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${task.icon.icon}` : placeholderIcon} />
                </div>
                <div className='task-small-card-right'>
                    <div className='task-small-card-upper'>
                        <p style={{ width: '100%' }}>{lang === 'pt' ? task.title_pt : task.title_en}</p>
                        <div style={{ width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around' }}>
                            <p>{`${current} / ${task.milestone} `}</p>
                            <div className='task-small-card-progress'>
                                <div className='task-small-card-progress-inner' style={{ width: `${percentage}%` }} />
                            </div>
                        </div>
                    </div>
                    <Divider orientation='horizontal' sx={{ width: '100%', backgroundColor: 'white' }} />
                    <div className='task-small-card-bottom'>
                        {task.contributions.length > 0 ? (
                            <>
                                <Avatars clickable={false} size='sm' data={task} from='task' max={6} color='white' />
                            </>
                        ) : (
                            <p style={{ fontSize: '14px', color: 'white' }}>Esta tarefa ainda não tem nenhum contributo</p>
                        )}
                    </div>
                </div>
            </div>
            <ViewMoreTask open={openViewMore} close={() => setOpenViewMore(false)} data={task} />
        </>
    )
}

export function TaskCard({ task, lang, ...props }) {
    const [percentage, setPercentage] = useState(0)
    const [current, setCurrent] = useState(0)
    const [openViewMore, setOpenViewMore] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openAddCont, setOpenAddCont] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()
    const { userRole } = useUserRole()

    useEffect(() => {
        if (task.contributions.length === 0) return;

        let count = 0;

        task.contributions.forEach(x => {
            count += x.quantity
        })

        setCurrent(count)
        const percentage = (count / task.milestone) * 100;

        setPercentage(Math.floor(percentage * 100) / 100)
    }, [task])

    const setOnlineOffline = (task, online) => {
        setEntityOnlineOffline('tasks', task.id, online).then(res => {
            showSnackbar(`A tarefa ${task.title_pt} está agora ${online ? 'Online' : 'Offline'}`, '', "success")
            props.refresh()
        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const triggerEditDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')

    }

    return (
        <>
            <div className='task-card-container' tabIndex={1}>
                <p style={{ fontSize: '12px', position: 'absolute', top: '-7px', left: '64px', backgroundColor: 'white' }}>{task.project ? task.project.title : 'Sem projeto associado'}</p>
                <div className='task-card-chip-div'>
                    {task.online ?
                        userRole.canEditTask ? (
                            <CustomTooltip title='Clique para tornar a tarefa Offline'>
                                <Chip color='success' size='small' label='Online' variant='outlined' sx={{ borderRadius: '0' }} onClick={() => setOnlineOffline(task, false)} />
                            </CustomTooltip>
                        )
                            :
                            <Chip color='success' size='small' label='Online' variant='outlined' sx={{ borderRadius: '0' }} />
                        :
                        userRole.canEditTask ? (
                            <CustomTooltip title='Clique para tornar a tarefa Online'>
                                <Chip color='error' size='small' label='Offline' variant='outlined' sx={{ borderRadius: '0' }} onClick={() => setOnlineOffline(task, true)} />
                            </CustomTooltip>
                        ) :
                            <Chip color='error' size='small' label='Offline' variant='outlined' sx={{ borderRadius: '0' }} />

                    }
                </div>
                <div className='task-card-icon-div'>
                    {task.icon ?
                        <img className='task-card-image' alt={task.title_pt} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${task.icon.icon}`} />
                        :
                        <img className='task-card-image' alt='icon não definido' src={placeholderIcon} />
                    }
                </div>
                <div className='task-card-right'>
                    <div className='task-card-upper'>
                        <p style={{ width: '100%' }}>{lang === 'pt' ? task.title_pt : task.title_en}</p>
                        <div style={{ width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', position: 'relative' }}>
                            <p>{`${current} / ${task.milestone} `}</p>
                            <div className='task-card-progress'>
                                <div className='task-card-progress-inner' style={{ width: `${percentage}%` }} />
                            </div>
                            <p style={{ position: 'absolute', color: 'white', right: '32%' }}>{percentage}%</p>
                        </div>
                    </div>
                    <Divider orientation='horizontal' sx={{ width: '100%', backgroundColor: 'white' }} />
                    <div className='task-card-bottom'>
                        {task.contributions.length > 0 ? (
                            <>
                                <Avatars clickable={false} size='sm' data={task} from='task' max={6} color='white' />
                            </>
                        ) : (
                            <p style={{ fontSize: '14px', color: 'white' }}>Esta tarefa ainda não tem nenhum contributo</p>
                        )}
                    </div>
                </div>
                <div className='task-card-actions-div'>
                    <CardActions
                        icon={<AddTask htmlColor='var(--dark-beige)' fontSize='small' onClick={() => setOpenAddCont(true)} />}
                        tooltip='Adicionar contribuição'
                        permission='canAddContribution'
                    />
                    <CardActions
                        icon={<Edit htmlColor='var(--dark-beige)' fontSize='small' onClick={() => setOpenEditDialog(true)} />}
                        tooltip='Editar Tarefa'
                        permission='canEditTask'
                    />
                    <CustomTooltip title='Ver mais info'>
                        <Visibility htmlColor='var(--dark-beige)' fontSize='small' onClick={() => setOpenViewMore(true)} />
                    </CustomTooltip>
                    <CardActions
                        icon={<Delete htmlColor='var(--dark-beige)' fontSize='small' onClick={() => setOpenDeleteDialog(true)} />}
                        tooltip='Eliminar contribuição'
                        permission='canRemoveTask'
                    />
                </div>
            </div>
            <ViewMoreTask open={openViewMore} close={() => setOpenViewMore(false)} data={task} />
            <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Tarefa' entityType='tasks' nameField='title_pt' data={task} refresh={props.refresh} />
            <EditTask open={openEditDialog} close={() => setOpenEditDialog(false)} task={task} refresh={props.refresh} triggerSnackbar={triggerEditDialogSnackbar} projectsData={props.projectsData} categoriesData={props.categoriesData} addCategory={props.addCategory} addProject={props.addProject} />
            <AddContribution open={openAddCont} close={() => setOpenAddCont(false)} refresh={props.refresh} tasksData={null} task={task} triggerSnackbar={triggerEditDialogSnackbar} partnersData={props.partnersData} addPartner={props.addPartner} />
        </>
    )
}

const CardActions = ({ icon, tooltip, permission }) => {
    const { userRole } = useUserRole()

    if (!userRole[permission]) return ''

    return (
        <CustomTooltip title={tooltip}>
            {icon}
        </CustomTooltip>
    )
}