import React, { useEffect, useState } from 'react'
import TablePrefab from './TablePrefab'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import ViewMorePartner from '../Dialogs/Partners/ViewMorePartner'
import EditPartner from '../Dialogs/Partners/Edit/EditPartner'

function PartnersTable(props) {
    const [data, setData] = useState([])
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectedPartner, setSelectedTask] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [openViewMoreDialog, setOpenViewMoreDialog] = useState(false)
    const [openEditPartner, setOpenEditPartner] = useState(false)

    useEffect(() => {
        props.data.forEach(partner => {
            partner.numberOfContributions = partner.contributions.length;
        })

        setData(props.data)

    }, [props])

    const getPartnerId = id => {
        return props.data.filter(partner => partner.id === id)[0]
    }

    const handleOpenDelete = (props) => {
        setSelectedTask(getPartnerId(props.meta.rowData[0]))
        setOpenDeleteDialog(true)
    }

    const handleOpenViewMore = (props) => {
        setSelectedTask(getPartnerId(props.meta.rowData[0]))
        setOpenViewMoreDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedTask(getPartnerId(props.meta.rowData[0]))
        setOpenEditPartner(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'name', label: 'Nome', options: { filter: false, searchable: true } },
        { name: 'address', label: 'Morada', options: { filter: false, searchable: false } },
        { name: 'numberOfContributions', label: 'Contribuições', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '14px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'contact', label: 'Contacto', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '14px', textAlign: 'center', width: '100%' }}>{value}</p> } },
        { name: 'website', label: 'Website', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '14px', textAlign: 'center', width: '100%' }}>{value}</p> } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onView={handleOpenViewMore} onDelete={handleOpenDelete} entity='Partner' /> } },
    ]

    return (
        <>
            <TablePrefab data={data} columns={columns} loading={submitting} title='Parceiros' />
            {selectedPartner &&
                <>
                    <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Parceiro' entityType='partners' nameField='name' data={selectedPartner} refresh={props.refresh} onlyPermanent={true} />
                    <ViewMorePartner open={openViewMoreDialog} close={() => setOpenViewMoreDialog(false)} data={selectedPartner} />
                    <EditPartner open={openEditPartner} close={() => setOpenEditPartner(false)} data={selectedPartner} refresh={props.refresh} triggerSnackbar={triggerSnackbar} />
                </>
            }
        </>
    )
}

export default PartnersTable