import React, { useState } from 'react'
import TablePrefab from './TablePrefab'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import EditNewsTheme from '../Dialogs/NewsThemes/Edit/EditNewsTheme'
import useDialog from '../Dialogs/useDialog'

function ThemesTable(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [selectedTheme, setSelectedTheme] = useState(null)
    const { openInfoDialog } = useDialog()

    const getCatById = id => {
        return props.data.filter(cat => cat.id === id)[0]
    }

    const handleOpenDelete = (props) => {
        const theme = getCatById(props.meta.rowData[0])
        setSelectedTheme(theme)

        if (theme.news.length > 0) {
            openInfoDialog({ title: 'Aviso', message: `Ao eliminar este tema, ${theme.news.length} noticias irão ficar sem tema.\nPoderá mais tarde associar outro tema a estas noticias.\n\nPoderá também ver que noticias não têm tema com os filtros na pagina noticias.\nATENÇÃO:Noticias sem tema não irão aparecer no website.\n\nPretende continuar?` }, 'y/n', () => setOpenDeleteDialog(true))
            return;
        }

        setOpenDeleteDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedTheme(getCatById(props.meta.rowData[0]))
        setOpenEditDialog(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'name_pt', label: 'Nome em Português', options: { filter: false, searchable: true } },
        { name: 'name_en', label: 'Nome em Inglês', options: { filter: false, searchable: true } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onDelete={handleOpenDelete} entity='Themes' /> } },
    ]

    return (
        <>
            <TablePrefab data={props.data} columns={columns} loading={props.loading} title='Temas de Noticias' />
            {selectedTheme &&
                <>
                    <EditNewsTheme open={openEditDialog} close={() => { setOpenEditDialog(false); setSelectedTheme(null) }} data={selectedTheme} refresh={props.refresh} triggerSnackbar={triggerSnackbar} />
                    <DeleteDialog onlyPermanent={true} open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Temas de noticias' entityType='newsTheme' nameField='name_pt' data={selectedTheme} refresh={props.refresh} />
                </>
            }
        </>
    )
}

export default ThemesTable