import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { RRule } from 'rrule'
import { ToggleButtonGroup, ToggleButton, StyledEngineProvider } from '@mui/material'
import '../projects/ProjectsStatsChart.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const buildRRule = (timeFrame) => {

    let rule = '';

    switch (timeFrame) {
        case 'week':
            rule = new RRule({
                dtstart: moment().subtract(1, 'week')._d,
                freq: RRule.DAILY,
                interval: 1,
                count: 8
            }).all();
            break;
        case 'month':
            rule = new RRule({
                dtstart: moment().subtract(1, 'months')._d,
                freq: RRule.DAILY,
                interval: 1,
                count: 32
            }).all();
            break;
        case 'year':
            rule = new RRule({
                dtstart: moment().subtract(1, 'year')._d,
                freq: RRule.MONTHLY,
                interval: 1,
                count: 13
            }).all();
            break;
        default:
    }

    for (let i = 0; i < rule.length; i++) {
        if (timeFrame === 'year') {
            rule[i] = moment(rule[i]).format('MMM YYYY')
        } else {
            rule[i] = moment(rule[i]).format('DD MMM')
        }
    }
    return rule;
}

function ContributionChart(props) {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('year')
    const [chartType, setChartType] = useState('evolution')
    const [data, setData] = useState([])

    let dataCounter = 0;
    
    useEffect(() => {
        setData([...props.data])
    }, [props.data])


    const prepareDataToGraph = () => {

        const data = []
        const numberOfDays = buildRRule(selectedTimeFrame);

        numberOfDays.forEach((day, index) => {
            data.push({ x: day, y: calculateViewsPerDay(day) })
        })
        return data
    }

    const calculateViewsPerDay = (day) => {
        const options = { year: { dateFormat: 'MMM YYYY' }, week: { dateFormat: 'DD MMM' }, month: { dateFormat: 'DD MMM' } }

        if (chartType === 'evolution') {

            data.forEach((elem, index) => {
                if (moment(new Date(elem.createdAt)).format(options[selectedTimeFrame].dateFormat) === day) {
                    dataCounter++;
                }
            })
            return dataCounter

        } else {
            let varCounter = 0;

            data.forEach((elem, index) => {
                if (moment(new Date(elem.createdAt)).format(options[selectedTimeFrame].dateFormat) === day) {
                    varCounter++;
                }
            })
            return varCounter;
        }

    }

    const state = {
        labels: buildRRule(selectedTimeFrame),
        datasets: [
            { data: prepareDataToGraph() },
        ]
    }

    return (
        <StyledEngineProvider injectFirst>
            <div className='views-chart-outer-div' style={{ maxWidth: '1200px'}}>
                <div className='chart-title-div'>
                    <p className='views-chart-title'>{props.title}</p>
                </div>
                <div className='views-chart-main-div'>
                    <ToggleButtonGroup
                        value={chartType}
                        exclusive
                        onChange={(event, type) => setChartType(type)}
                        aria-label='selected chart type'
                    >
                        <ToggleButton className='views-chart-toggle-btn' value='evolution'>Evolução</ToggleButton>
                        <ToggleButton className='views-chart-toggle-btn' value='variable'>Variável</ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        value={selectedTimeFrame}
                        exclusive
                        onChange={(event, timeFrame) => setSelectedTimeFrame(timeFrame)}
                        aria-label='selected timeFrame'
                    >
                        <ToggleButton className='views-chart-toggle-btn' value='week'>Ultima semana</ToggleButton>
                        <ToggleButton className='views-chart-toggle-btn' value='month'>Ultimo Mês</ToggleButton>
                        <ToggleButton className='views-chart-toggle-btn' value='year'>Ultimo Ano</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div>
                    <Line
                        data={state}
                        width={1200}
                        height={350}
                        options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            tension: 0.3,
                            cubicInterpolationMode: 'monotone',
                            elements: {
                                line: {
                                    backgroundColor: '#ff5c00',
                                    borderColor: '#ff5c00'
                                },
                                point: {
                                    backgroundColor: '#ff5c00',
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                        type: 'time',
                                    },

                                },
                                y: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        stepSize: 1
                                    }
                                }
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: false,
                                    position: 'top',
                                    text: ['Total Views'],
                                    padding: 10
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </StyledEngineProvider>
    )
}

export default ContributionChart