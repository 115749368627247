import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'
import BigSwitch from '../../../Switches/TableSwitch'
import { CustomAutoComplete } from '../../../Inputs/CustomAutoComplete'
import { Divider, FormControlLabel } from '@mui/material';
import LanguageChanger from '../../../Display/LanguageChanger'
import { newsTooltips } from '../../../../TooltipHints';
import CustomTooltip from '../../../Display/CustomTooltip';
import TextArea from '../../../Inputs/TextArea';

import '../Info.css'

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            title_ptIsValid: false,
            title_enIsValid: false,
            formIsValid: false,
            formErrors: { title_pt: '', title_en: '' },
            selectedLanguage: 'pt'
        }
    }

    componentDidMount() {
        if (this.state.infoComplete) {
            this.setState({
                title_ptIsValid: true,
                title_enIsValid: true,
                formErrors: { title_pt: '', title_en: '' },
            })
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    handleCheckboxInput = event => {
        const name = event.target.name;
        const value = event.target.checked;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.validateForm())
    }

    handleAutocompleteInput = data => {
        this.props.updateStateOnParent('news_theme', data)
        this.setState({ news_theme: data }, () => this.validateForm())
    }

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let title_ptIsValid = this.state.title_ptIsValid;
        let title_enIsValid = this.state.title_enIsValid;

        switch (name) {
            case 'title_pt':
                title_ptIsValid = value.length > 4 && value.length <= 70;
                fieldValidationErrors.title_pt = title_ptIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Titulo deve conter mais do que 4 caracteres e um máximo de 70`}`
                break;
            case 'title_en':
                title_enIsValid = value.length > 4 && value.length <= 70;
                fieldValidationErrors.title_en = title_enIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Titulo deve conter mais do que 4 caracteres e um máximo de 70`}`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            title_ptIsValid,
            title_enIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.title_ptIsValid &&
                this.state.title_enIsValid &&
                this.state.location.length > 4 &&
                this.state.news_theme &&
                this.state.description_en.length > 10 &&
                this.state.description_pt.length > 10
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    checkLanguageAlert = () => {
        if (this.state.selectedLanguage === 'en') {
            return this.state.description_pt.length === 0
        }
        return this.state.description_en.length === 0
    }

    render() {
        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' >
                    <p className='project-info-dialog-text-title'>Preencha o seguinte formulário para poder criar uma nova noticia.</p>
                    <p className='project-info-dialog-text-subtitle'>Todos os campos são obrigatórios e as descrições têm a versão em Português e Inglês</p>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'row-reverse' }}>
                    <div className='project-info-left-div' style={{ paddingTop: '45px', justifyContent: 'flex-start' }}>
                        < TextField
                            size='small'
                            label='Localidade'
                            name='location'
                            sx={{ marginRight: '10px 0' }}
                            value={this.state.location}
                            onChange={this.handelInput}
                            required
                            placeholder='Exemplo: Covilhã, Vila de Manteigas'
                            error={this.state.location.length < 4}
                        />
                        <div className='autocomplete-plus-add-button'>
                            <CustomAutoComplete
                                OptionLabel='name_pt'
                                size='small'
                                fullWidth
                                sx={{ margin: '20px 0' }}
                                data={this.props.newsThemeData}
                                multiple={false}
                                id='theme'
                                label='Tema'
                                value={this.state.news_theme}
                                onChange={(id, value) => this.handleAutocompleteInput(value)}
                                error={!this.state.news_theme}
                            />
                            <CustomTooltip title='Adicionar novo tema'>
                                <button className='add-more-button' onClick={this.props.addTheme}>+</button>
                            </CustomTooltip>
                        </div>
                        <div className='info-form-checkbox'>
                            <CustomTooltip title={newsTooltips.published}>
                                <FormControlLabel
                                    label='Publicar após de submeter'
                                    control={<BigSwitch
                                        sx={{ marginRight: '10px' }}
                                        name='published'
                                        value={this.state.published}
                                        checked={this.state.published}
                                        onChange={this.handleCheckboxInput}
                                    />}
                                />
                            </CustomTooltip>
                        </div>
                    </div>
                    <Divider orientation='vertical' sx={{ height: 'inherit' }} />
                    <div className='project-info-right-div'>
                        <LanguageChanger onLanguageChange={(lang) => this.setState({ selectedLanguage: lang })} />
                        {this.state.selectedLanguage === 'pt' ? (
                            <>
                                <TextField
                                    size='small'
                                    label='Titulo da Noticia'
                                    name='title_pt'
                                    value={this.state.title_pt}
                                    onChange={this.handelInput}
                                    autoFocus
                                    required
                                    error={!this.state.title_ptIsValid}
                                    helperText={this.state.formErrors.title_pt}
                                />
                                <TextArea
                                    size='small'
                                    rows={7}
                                    label='Texto da Noticia'
                                    name='description_pt'
                                    value={this.state.description_pt}
                                    onChange={this.handelInput}
                                    required
                                    actions
                                    error={this.state.description_pt.length < 10}
                                />
                                {this.checkLanguageAlert() && <p className='project-info-dialog-text-subtitle'>Clique na bandeira Inglesa para preencher esta info em Inglês</p>}
                            </>
                        ) : (
                            <>
                                <TextField
                                    size='small'
                                    label='News Title'
                                    name='title_en'
                                    value={this.state.title_en}
                                    onChange={this.handelInput}
                                    required
                                    error={!this.state.title_enIsValid}
                                    helperText={this.state.formErrors.title_en}
                                />
                                <TextArea
                                    size='small'
                                    rows={7}
                                    label='News Text'
                                    name='description_en'
                                    value={this.state.description_en}
                                    onChange={this.handelInput}
                                    required
                                    actions
                                    error={this.state.description_en.length < 10}
                                />
                                {this.checkLanguageAlert() && <p className='project-info-dialog-text-subtitle'>Clique na bandeira Portuguesa para preencher esta info em Português</p>}
                            </>
                        )}

                    </div>
                </div>
            </div>
        )
    }
}
