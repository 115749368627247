import React, { useEffect, useState } from 'react'
import CustomToggleButton from '../../Buttons/CustomToggleButton'
import { Button } from '@mui/material'
import { FilterAutocomplete } from '../../Inputs/CustomAutoComplete'
import { getEntity } from '../../../Api/Requests'

import './FilterPanel.css'

function NewsFilter(props) {
    const [autoCompleteData, setAutoCompleteData] = useState([])
    const [state, setState] = useState({
        published: 'all',
        theme: []
    })

    const filterButtons = [
        {
            label: 'Noticia Publicada?',
            onChange: (e) => filterData('published', e),
            value: state.published,
            data: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
    ]

    const resetFilters = () => {
        setState({
            published: 'all',
            theme: []
        })
    }

    const filterData = (filter, value) => {
        setState(state => ({
            ...state,
            ...{ [filter]: value }
        }))
    }

    useEffect(() => {
        getEntity('newsTheme').then(res => {
            setAutoCompleteData(res.data)
        }, err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (props.data) {
            const filteredData = props.data.filter(elem => {
                return (
                    (state.published !== 'all' ? elem.published === state.published : true) &&
                    (state.theme.length > 0 ? state.theme.some(theme => theme.id === elem.newsThemeId) : true)
                )
            })
            props.setData(filteredData)
        }
    }, [state])


    return (
        <div className='filter-main-div'>
            {filterButtons.map(filter => {
                return (
                    <div key={filter.label} className='filter-label-div'>
                        <p>{filter.label}</p>
                        <CustomToggleButton light data={filter.data} value={filter.value} onChange={filter.onChange} />
                    </div>
                )
            })}
            <div className='filter-label-div'>
                <p>Tema</p>
                <div style={{ width: '250px' }}>
                    <FilterAutocomplete multiple value={state.theme} optionLabel='name_pt' data={autoCompleteData} label='' id='theme' onChange={(id, value) => filterData('theme', value)} />
                </div>
            </div>
            <Button size='small' sx={{ border: '2px solid white', marginTop: '20px', color: 'white', width: '202px' }} onClick={resetFilters}>Limpar Filtros</Button>
        </div>
    )
}

export default NewsFilter