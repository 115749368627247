import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'
import { DropzoneDialog } from 'material-ui-dropzone'
import { iconsTooltips } from '../../../../TooltipHints';
import { CloudUpload as Upload, Delete, Help } from '@mui/icons-material'
import { IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import CustomTooltip from '../../../Display/CustomTooltip';

const StyledButton = styled(Button)({
    color: 'white !important',
    backgroundColor: 'var(--dark-blue) !important',
    borderColor: 'white !important',
    margin: '20px 0 !important',
    '&:hover': {
        backgroundColor: 'white',
        color: 'var(--dark-blue)'
    }
})


export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            openImageUploader: false,
            imagePreview: [],
            formIsValid: false,
        }
    }

    componentDidMount() {
        if (this.props.state.icon.length > 0) this.changeImage(this.props.state.icon, true)
    }

    debounceInput = debounce((name, value) => {
        this.validateForm()
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    validateForm() {

        this.setState({
            formIsValid:
                this.state.name.length > 2
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    changeImage = (file, skipUpdateParent) => {

        if (skipUpdateParent) {
            this.setState({ imagePreview: file })
            return;
        }

        const picture = URL.createObjectURL(new Blob(file, { type: file[0].type }))
        const id = crypto.randomUUID()

        this.setState({ imagePreview: [{ id, picture, info: file[0] }] })

        this.props.updateStateOnParent('icon', [{ id, picture, info: file[0] }])
    }

    removeBackgroundImage = () => {
        this.setState({ imagePreview: [] })

        this.props.updateStateOnParent('icon', [])
    }

    render() {

        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' style={{ flexDirection: 'row' }} >
                    <p className='contributions-info-dialog-text-title'>Criar um novo Icon:</p>
                    <CustomTooltip title={iconsTooltips.icon}>
                        <Help sx={{ marginLeft: '10px' }} fontSize='small' />
                    </CustomTooltip>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column' }}>
                    <TextField
                        size='small'
                        label='Nome'
                        name='name'
                        autoFocus={true}
                        value={this.state.name}
                        onChange={this.handelInput}
                        required
                        error={this.state.name.length < 3}
                    />
                    <div className='main-image-inner-div'>
                        {this.state.imagePreview.length === 0 ? (
                            <StyledButton sx={{ alignSelf: 'flex-start' }} onClick={() => this.setState({ openImageUploader: true })} variant='outlined' startIcon={<Upload />}>Carregar Icon</StyledButton>
                        ) : (
                            <div className='project-upload-background-image-preview' style={{ height: '150px', width: '150px', position: 'relative', background: 'var(--dark-blue)' }}>
                                <img alt='icon' src={this.state.imagePreview[0].picture} height='100%' width='100%' />
                                <div className='image-action-div' style={{ position: 'absolute', bottom: '0' }}>
                                    <CustomTooltip title='Remover imagem'>
                                        <IconButton sx={{ color: 'black' }} onClick={this.removeBackgroundImage}>
                                            <Delete htmlColor='white' />
                                        </IconButton>
                                    </CustomTooltip>
                                </div>
                                {this.state.imagePreview[0].info &&
                                    <div className='image-info-div' style={{ position: 'absolute' }}>
                                        <p>{`${this.state.imagePreview[0].info.name}`}</p>
                                        <p>{`${this.state.imagePreview[0].info.size} Bytes`}</p>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
                <ImageUploader open={this.state.openImageUploader} close={() => this.setState({ openImageUploader: false })} changeImage={this.changeImage} />
            </div>
        )
    }
}


const ImageUploader = props => {
    return (
        <DropzoneDialog
            previewGridClasses={{ container: 'dropzone-preview-container', item: 'dropzone-preview-item' }}
            acceptedFiles={['image/png', 'image/svg+xml']}
            cancelButtonText='Cancelar'
            submitButtonText='Submeter'
            filesLimit={1}
            maxFileSize={102400}
            open={props.open}
            onClose={() => props.close()}
            onSave={(file) => { props.changeImage(file); props.close() }}
            showPreviews={false}
            showPreviewsInDropzone={true}
            dialogTitle='Upload Icon'
            maxWidth='xs'
            dropzoneText='Arraste a imagem ou clique aqui para fazer upload'
            getFileLimitExceedMessage={() => 'Numero Máximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
            getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
            getFileRemovedMessage={() => 'Imagem removida com sucesso'}
            getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas até um máximo de 100kb.'}
        />
    )
}