import React, { useState } from 'react'
import { PasswordTextField } from '../../../Inputs/TextField'

function Review(props) {
    const [viewPass, setViewPass] = useState(false)

    return (
        <div className='add-dialog-review-container' style={{ alignItems: 'center'}}>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>Reveja toda a informação antes de submeter</p>
            </div>
            <div className='add-dialog-review-main-div' style={{ height: 'fit-content' }}>
                <div className='add-dialog-review-info' style={{ width: '100%', marginBottom:'15px' }}>
                    <InfoLabel fullWidth={true} label='Nome' value={props.state.name} />
                    <InfoLabel fullWidth={true} label='email' value={props.state.email} />
                    <InfoLabel fullWidth={true} label='Permissão' value={props.state.userRole.name} />
                    <InfoLabel
                        onMouseEnter={() => setViewPass(true)}
                        onMouseLeave={() => setViewPass(false)}
                        fullWidth={true}
                        label='Password'
                        value={viewPass ? props.state.password : '************'}
                    />
                </div>
            </div>
            <div>
                {/* <p>Introduza a sua password para comprovar a sua identidade</p> */}
                <PasswordTextField
                    fullWidth={false}
                    size='small'
                    label='Password do usuário'
                    name='password'
                    value={props.state.authPassword}
                    onChange={(e) => props.updateStateOnParent('authPassword', e.target.value)}
                    required
                    error={props.state.authPassword.length < 6}
                    helperText='Introduza a sua password para comprovar a sua identidade'
                />
            </div>
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value, fullWidth, ...props }) => {
    return (
        <div className='review-label-div' style={fullWidth ? { width: '100%' } : {}} {...props}>
            <p className='review-label-title'>{label}</p>
            <p className='review-label-value'>{value}</p>
        </div>
    )
}