import React, { Component } from 'react'
import DialogPrefab from '../DialogPrefab'
import { TextField } from '../../Inputs/TextField'
import StandardButton from '../../Buttons/StandardButton'
import { debounce } from '../../../utils'
import { sendForgotPass } from '../../../Api/Requests'

import './ForgotPass.css'

class ForgotPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailIsValid: true,
            formErr: { email: '' },
            formIsValid: false,
            submitting: false
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    })

    handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value }, () => this.debounceInput(name, value))
    }

    validateFields(name, value) {

        let fieldValidationErrors = this.state.formErr;
        let emailIsValid = this.state.emailIsValid;

        emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
        fieldValidationErrors.email = emailIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um email válido`}`

        this.setState({
            formErr: fieldValidationErrors,
            emailIsValid,
        }, () => this.setState({ formIsValid: this.state.emailIsValid }))
    }

    handleSubmit = () => {
        this.setState({ submitting: true })

        sendForgotPass(this.state.email).then(res => {
            this.props.handleResponse(res, false)
            this.setState({ submitting: false })
        }, err => {
            this.props.handleResponse(err, true)
            this.setState({ submitting: false })
        })
    }

    render() {
        return (
            <DialogPrefab
                open={this.props.open}
                close={this.props.close}
                maxWidth='sm'
                title='Recuperar Password'
            >
                <div className='forgot-pass-main-div'>
                    <div className='forgot-pass-label-div'>
                        <p className='forgot-pass-title'>Indique-nos o seu email</p>
                        <TextField
                            label='Email'
                            name='email'
                            onChange={this.handleInput}
                            autoFocus
                            value={this.state.email}
                            error={!this.state.emailIsValid}
                            helperText={this.state.formErr.email}
                        />
                    </div>
                    <StandardButton
                        style={{ alignSelf: 'flex-end', marginBottom: '10px' }}
                        loading={this.state.submitting}
                        onClick={this.handleSubmit}
                        disabled={!this.state.formIsValid}
                    >
                        Submeter
                    </StandardButton>
                </div>
            </DialogPrefab>
        )
    }
}

export default ForgotPass