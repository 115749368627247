import React, { useState } from 'react'
import { TextField } from './TextField'
import { AddLink, AlternateEmail } from '@mui/icons-material'
import { Divider } from '@mui/material'
import CustomTooltip from '../Display/CustomTooltip'
import { form } from '../../TooltipHints'
import DialogPrefab from '../Dialogs/DialogPrefab'

import './TextArea.css'

function TextArea({ rows, size, label, name, value, onChange, actions, ...props }) {
    const [openAddLink, setOpenAddLink] = useState(false)
    const [openAddEmail, setOpenAddEmail] = useState(false)

    const addLinkToTextBox = (text, url) => {
        onChange({ target: { name: name, value: `${value} ${`[[${text}|${url}]]`}` } })
    }

    return (
        <div className='text-area-main-div'>
            {actions &&
                <div className='text-area-buttons-div'>
                    <CustomTooltip title={form.link}>
                        <button tabIndex={-1} className='text-area-action-button' onClick={() => setOpenAddLink(true)}><AddLink fontSize='small' /></button>
                    </CustomTooltip>
                    <CustomTooltip title={form.email}>
                        <button tabIndex={-1} className='text-area-action-button' onClick={() => setOpenAddEmail(true)}><AlternateEmail fontSize='small' /></button>
                    </CustomTooltip>
                </div>
            }
            <TextField
                size={size}
                multiline
                rows={rows}
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                {...props}
            />
            <AddLinkDialog open={openAddLink} close={() => setOpenAddLink(false)} type='Link' onSubmit={addLinkToTextBox} />
            <AddLinkDialog open={openAddEmail} close={() => setOpenAddEmail(false)} type='Email' onSubmit={addLinkToTextBox} />
        </div>
    )
}

export default TextArea

const AddLinkDialog = ({ open, close, type, onSubmit }) => {
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [urlHelperText, setUrlHelperText] = useState('')
    const [urlErr, setUrlErr] = useState(false)

    const handleSubmit = () => {
        if (type === 'Link') {
            if (name.length < 1) {
                setName(url)
            }
            if (!new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(url)) {
                setUrlErr(true)
                setUrlHelperText('O Link que indicou não é válido, ex: https://google.pt')
                return;
            }
            submit();
            return;
        }

        if (name.length < 1) {
            setName(url)
        }
        if (!new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(url)) {
            setUrlErr(true)
            setUrlHelperText('O Email que indicou não é válido, ex: omeuemail@mail.com')
            return;
        }
        submit();
    }

    const submit = () => {
        onSubmit(name, url)
        handleClose()
    }

    const handleClose = () => {
        setName('')
        setUrl('')
        setUrlErr(false)
        setUrlHelperText('')
        close()
    }

    return (
        <DialogPrefab
            open={open}
            close={handleClose}
            maxWidth='xs'
            title={`Adicionar ${type}`}
        >
            <div className='text-area-add-main-div'>
                <TextField
                    placeholder={`Escreva um nome para o ${type}`}
                    size='small'
                    label='Nome'
                    name='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                />
                <Divider orientation='horizontal' sx={{ width: '100%' }} />
                <TextField
                    placeholder={`Escreva o ${type} aqui`}
                    size='small'
                    label={type}
                    name='url'
                    value={url}
                    error={urlErr}
                    onChange={(e) => setUrl(e.target.value)}
                    helperText={urlHelperText}
                    onKeyPress={e => { if (e.key === 'Enter') handleSubmit() }}
                />
                <button className='text-area-add-submit-btn' onClick={handleSubmit}>{`Adicionar ${type}`}</button>
            </div>
        </DialogPrefab>
    )
}