import React from 'react'
import DialogPrefab from '../DialogPrefab'
import { ArrowForwardIos } from '@mui/icons-material'
import { Paper } from '@mui/material'

import moment from 'moment'
import iconPlaceholder from '../../../assets/placeholders/logo_placeholder_1.png'

import './ViewMore.css'

function ViewMoreContributions(props) {

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='sm'
            title={`contribuição de ${props.partner.name}`}
        >
            <div className='view-more-contributions-main-div'>
                <p className='view-more-contributions-title'>{`Contribuição de ${props.partner.name} ${props.data.task.project ? `para o projeto ${props.data.task.project.title}` : 'para um projeto que já não existe'}`}</p>
                <div className='view-more-contributions-steps-div'>
                    <div className='view-more-contributions-steps-outer'>
                        <p>{`O Parceiro ${props.partner.name}`}</p>
                        <Paper elevation={6} className='view-more-contributions-steps'>
                            <img alt='partner' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${props.partner.logo}`} height='80%' width='auto' />
                        </Paper>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <p style={{ fontSize: '14px', marginBottom: '10px' }}>Contribuiu com</p>
                        <ArrowForwardIos />
                    </div>
                    <div className='view-more-contributions-steps-outer'>
                        <p>{`${props.data.quantity} ${props.data.task.title_pt}`}</p>
                        <Paper elevation={6} className='view-more-contributions-steps'>
                            <img alt='item' src={props.data.task.icon ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${props.data.task.icon.icon}` : iconPlaceholder} height='70%' width='auto' />
                        </Paper>
                    </div>
                </div>
                <p style={{ color: 'var(--dark-blue)', fontSize: '18px' }}>{moment(props.data.createdAt).format('DD-MMM-YYYY HH:mm')}</p>
            </div>
        </DialogPrefab>
    )
}

export default ViewMoreContributions