import React, { useState, useEffect } from 'react'
import { Avatar, Divider, Paper } from '@mui/material'
import { PlayCircleFilled } from '@mui/icons-material'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab'
import DialogPrefab from '../DialogPrefab'
import moment from 'moment'
import { getPartnersFromTask } from '../../../utils'
import ViewMorePartners from '../Partners/ViewMorePartner'
import sortArray from 'sort-array'
import { getTaskCompletePercentage } from '../../../utils'
import Image from '../../Display/Image'
import placeholderIcon from '../../../assets/placeholders/logo_placeholder_1.png'

import './ViewMore.css'

function ViewMoreTask(props) {
    const [partners, setPartners] = useState([])
    const [openPartner, setOpenPartner] = useState(false)
    const [selectedPartner, setSelectedPartner] = useState(null)
    const [timelineData, setTimelineData] = useState([])
    const [taskProgress, setTaskProgress] = useState(0)
    const [currentValue, setCurrentValue] = useState(0)
    
    useEffect(() => {
        setPartners(getPartnersFromTask(props.data))

        const sortedArr = sortArray(props.data.contributions, { by: 'createdAt', order: 'desc' })
        setTimelineData([...sortedArr, { id: props.data.title_pt, started: true, createdAt: props.data.createdAt }])

        setTaskProgress(getTaskCompletePercentage(props.data))

        if (props.data.contributions.length > 0) {
            const value = props.data.contributions.reduce((prev, curr) => {
                return { quantity: prev.quantity + curr.quantity }
            })

            setCurrentValue(value.quantity)
        }
    }, [props.data])


    const handleOpenPartner = (partner) => {
        setSelectedPartner(partner)
        setOpenPartner(true)
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='md'
            title={props.data.title_pt}
        >
            <div className='task-view-more-main-div'>
                <Paper elevation={6} className='task-view-more-contributions'>
                    <Timeline position='alternate' sx={{ padding: '6px 5px' }}>
                        {timelineData.map(cont => {
                            return (
                                <TimeLineItem key={cont.id} contribution={cont} task={props.data} />
                            )
                        })}
                    </Timeline>
                </Paper>
                <div className='task-view-more-info-div'>
                    <Paper elevation={6} className='task-view-more-info'>
                        <p className='task-view-more-div-title'>Informação</p>
                        <div className='task-view-more-info-header'>
                            <div className='task-view-more-info-title'>
                                {props.data.icon ?
                                    <img alt='task icon' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${props.data.icon.icon}`} height='60px' width='auto' />
                                    :
                                    <img alt='task icon' src={placeholderIcon} height='60px' width='auto' />
                                }
                                <p>{props.data.title_pt}</p>
                            </div>
                            <p style={{ fontSize: '14px' }}>{props.data.project ? props.data.project.title : 'Sem projeto associado'}</p>
                            <div className='task-view-more-info-progress'>
                                <div className='task-view-more-info-inner-progress' style={{ width: `${taskProgress}%` }} />
                                <p>{taskProgress}%</p>
                            </div>
                        </div>
                        <Divider orientation='horizontal' sx={{ width: '100%' }} />
                        <div className='task-view-more-info-body'>
                            <BodyLabels label='Objetivo' value={props.data.milestone} />
                            <BodyLabels label='Alcançado' value={currentValue} />
                            <BodyLabels label='Contribuições' value={props.data.contributions.length} />
                            <BodyLabels label='Categoria' value={props.data.category.name_pt} />
                            <BodyLabels label='Artesanato Local' value={props.data.local_craft ? 'Sim' : 'Não'} />
                            <BodyLabels label='Online' value={props.data.online ? 'Sim' : 'Não'} />
                        </div>
                    </Paper>
                    <Paper elevation={6} className='task-view-more-meta'>
                        <p className='task-view-more-div-title'>Metadata</p>
                        <div className='task-view-more-meta-inner'>
                            <p>{`ID: ${props.data.id}`}</p>
                            {props.data.icon &&
                                <p>{`Icon: ${props.data.icon.icon}`}</p>
                            }
                            <p>{`Criado em: ${moment(props.data.createdAt).format('DD-MMM-YYYY HH:mm')}`}</p>
                            <p>{`Modificado em: ${moment(props.data.updatedAt).format('DD-MMM-YYYY HH:mm')}`}</p>
                        </div>
                    </Paper>
                </div>
                <Paper elevation={6} className='task-view-more-partners'>
                    <p className='task-view-more-div-title'>Parceiros</p>
                    {partners.map(partner => {
                        return (
                            <div key={partner.id} style={{ textAlign: 'center' }} onClick={() => handleOpenPartner(partner)}>
                                <div className='task-view-more-partner-logo'>
                                    <Image alt='partner-logo' value={partner.logo} entity='partners' height='100%' width='100%' style={{ objectFit: 'cover' }} />
                                </div>
                                <p style={{ fontSize: '14px', marginTop: '-10px' }}>{partner.name}</p>
                            </div>
                        )
                    })}
                </Paper>
            </div>
            {selectedPartner &&
                <ViewMorePartners open={openPartner} close={() => setOpenPartner(false)} data={selectedPartner} />
            }
        </DialogPrefab>
    )
}

export default ViewMoreTask

const BodyLabels = ({ label, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: ' 10px' }}>
            <p style={{ fontSize: '16px' }}>{label}</p>
            <p style={{ fontSize: '14px', color: 'var(--dark-beige)', marginLeft: '10px' }}>{value}</p>
        </div>
    )
}

const TimeLineItem = ({ contribution, task }) => {

    if (contribution.started) {
        return (
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {moment(contribution.createdAt).format('DD-MMM-YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <PlayCircleFilled fontSize='large' htmlColor='var(--dark-blue)' />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <p style={{ fontSize: '14px' }}>Tarefa criada</p>
                    <p style={{ fontSize: '14px', color: 'var(--medium-beige)' }}>{task.title_pt}</p>
                </TimelineContent>
            </TimelineItem>
        )
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                {moment(contribution.createdAt).format('DD-MMM-YYYY HH:mm')}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <Avatar alt={contribution.partner.name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${contribution.partner.logo}`} />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <p style={{ fontSize: '14px' }}>{contribution.partner.name}</p>
                <p style={{ fontSize: '14px', color: 'var(--medium-beige)' }}>{`${contribution.quantity} ${task.title_pt}`}</p>
            </TimelineContent>
        </TimelineItem>
    )
}