import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { PasswordTextField, TextField } from '../../../Inputs/TextField'
import { CustomAutoComplete } from '../../../Inputs/CustomAutoComplete'

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            nameIsValid: false,
            emailIsValid: false,
            passwordIsValid: false,
            confirmPasswordIsValid: false,
            formIsValid: false,
            formErrors: { name: '', email: '', password: '', confirmPassword: '' },
        }
    }

    componentDidMount() {
        if (this.state.infoComplete) {
            this.setState({
                nameIsValid: true,
                emailIsValid: true,
                passwordIsValid: true,
                confirmPasswordIsValid: true,
                formErrors: { name: '', email: '', password: '', confirmPassword: '' },
            })
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if(name === 'password') {
            this.setState({ confirmPassword: '', confirmPasswordIsValid: false}, () => this.props.updateStateOnParent('confirmPassword', ''))
        }

        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    handleAutocompleteInput = (field, data) => {
        this.props.updateStateOnParent(field, data)
        this.setState({ [field]: data }, () => this.validateForm())
    }

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let passwordIsValid = this.state.passwordIsValid;
        let confirmPasswordIsValid = this.state.confirmPasswordIsValid;

        switch (name) {
            case 'name':
                nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s]*$/).test(value) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um nome válido`}`
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um email válido`}`
                break;
            case 'password':
                passwordIsValid = new RegExp(/^(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
                fieldValidationErrors.password = passwordIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Password inválida, tem que conter 2 Maiúsculas, 2 números e mais de 8 caracteres `}`
                break;
            case 'confirmPassword':
                confirmPasswordIsValid = value === this.state.password;
                fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Passwords não coincidem`}`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid,
            emailIsValid,
            passwordIsValid,
            confirmPasswordIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.passwordIsValid &&
                this.state.confirmPasswordIsValid &&
                this.state.userRole !== null
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    render() {
        return (
            <div className='project-info-main-div' style={{ alignItems: 'center' }}>
                <div className='project-info-dialog-title' >
                    <p className='project-info-dialog-text-title' style={{ textAlign: 'center' }}>Preencha o seguinte formulário para poder criar um novo User.</p>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column', width: '80%' }}>
                    <TextField
                        size='small'
                        label='Nome'
                        name='name'
                        value={this.state.name}
                        onChange={this.handelInput}
                        autoFocus
                        required
                        error={!this.state.nameIsValid}
                        helperText={this.state.formErrors.name}
                    />
                    <TextField
                        size='small'
                        label='Email'
                        name='email'
                        value={this.state.email}
                        onChange={this.handelInput}
                        required
                        error={!this.state.emailIsValid}
                        helperText={this.state.formErrors.email}
                    />
                    <CustomAutoComplete
                        OptionLabel='name'
                        size='small'
                        fullWidth
                        sx={{ margin: '10px 0' }}
                        data={this.props.userRoleData}
                        multiple={false}
                        id='userRole'
                        label='Permissões'
                        value={this.state.userRole}
                        onChange={(id, value) => this.handleAutocompleteInput('userRole', value)}
                        error={!this.state.userRole}
                    />
                    <PasswordTextField
                        size='small'
                        label='Password do usuário'
                        name='password'
                        value={this.state.password}
                        onChange={this.handelInput}
                        required
                        error={!this.state.passwordIsValid}
                        helperText={this.state.formErrors.password}
                    />
                    <PasswordTextField
                        size='small'
                        label='Confirmar password'
                        name='confirmPassword'
                        value={this.state.confirmPassword}
                        onChange={this.handelInput}
                        required
                        error={!this.state.confirmPasswordIsValid}
                        helperText={this.state.formErrors.confirmPassword}
                    />
                </div>
            </div>
        )
    }
}


