import React, { useEffect, useState } from 'react'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab'
import sortArray from 'sort-array'
import moment from 'moment'
import { Avatar } from '@mui/material'

function StatsContributionsTimeLine(props) {
    const [data, setData] = useState([])

    useEffect(() => {
        const lastMonth = props.data.filter(elem => moment(elem.createdAt).isSameOrAfter(moment().subtract(1, 'month'), 'day'))

        const sortedArr = sortArray(lastMonth, { by: 'createdAt', order: 'desc' })
        setData(sortedArr)

    }, [props.data])

    return (
        <>
            <p style={{ textAlign: 'center', letterSpacing: '2px', color: 'var(--dark-beige)'}}>Últimos 30 Dias</p>
            <Timeline position='alternate' sx={{ padding: '6px 5px' }}>
                {data.map(cont => {
                    return (
                        <TimeLineItem key={cont.id} contribution={cont} task={props.data} />
                    )
                })}
            </Timeline>
        </>
    )
}

export default StatsContributionsTimeLine

const TimeLineItem = ({ contribution, task }) => {
    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                {moment(contribution.createdAt).format('DD-MMM-YYYY HH:mm')}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <Avatar alt={contribution.partner.name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${contribution.partner.logo}`} />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '25px', px: 2 }}>
                <p style={{ fontSize: '14px' }}>{contribution.partner.name}</p>
            </TimelineContent>
        </TimelineItem>
    )
}