import React, { useState } from 'react'
import { openInNewTab } from '../../../utils'
import { Home, Language, Phone, ThumbUpAlt, Edit, Delete, Visibility } from '@mui/icons-material'
import { Button } from '@mui/material'
import CustomTooltip from '../../Display/CustomTooltip'
import ViewMorePartner from '../../Dialogs/Partners/ViewMorePartner'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import PlaceholderImage from '../../../assets/placeholders/logo_placeholder_1.png'
import EditPartner from '../../Dialogs/Partners/Edit/EditPartner'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { useUserRole } from '../../../Hooks/useUser'

import './PartnerCard.css'


export function PartnerCard({ partner, refresh }) {
    const [openViewMore, setOpenViewMore] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditPartner, setOpenEditPartner] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()

    const triggerEditDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='partners-card-main-div'>
            <div className='partners-card-action-div'>
                <CardAction permission='canEditPartner'>
                    <Button onClick={() => setOpenEditPartner(true)} startIcon={<Edit />}>Editar</Button>
                </CardAction>
                <Button onClick={() => setOpenViewMore(true)} startIcon={<Visibility />}>Ver Mais</Button>
                <CardAction permission='canRemovePartner'>
                    <Button onClick={() => setOpenDeleteDialog(true)} startIcon={<Delete />}>Eliminar</Button>
                </CardAction>
            </div>
            <CustomTooltip title={`${partner.name} já realizou ${partner.contributions.length} contribuições`}>
                <div className='partners-card-contributions'>
                    <ThumbUpAlt htmlColor='white' fontSize='small' sx={{ marginRight: '5px' }} />
                    <p>{partner.contributions.length}</p>
                </div>
            </CustomTooltip>
            <div className='partners-card-left-div'>
                {partner.logo ?
                    <img alt='partner-logo' className='partner-card-logo' style={{ objectFit: 'cover' }} width='60%' height='auto' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${partner.logo}`} />
                    :
                    <img alt='partner-logo' className='partner-card-logo' style={{ objectFit: 'cover' }} width='60%' height='auto' src={PlaceholderImage} />
                }
            </div>
            <div className='partners-card-right-div'>
                <div className='partners-card-title'>
                    <p>{partner.name}</p>
                </div>
                <div className='partners-card-info'>
                    <div className='partners-info-icon'><Home fontSize='small' sx={{ marginRight: '10px' }} /><p>{partner.address}</p></div>
                    <div className='partners-info-icon'><Phone fontSize='small' sx={{ marginRight: '10px' }} /><p>{partner.contact}</p></div>
                    <div className='partners-info-icon'><Language fontSize='small' sx={{ marginRight: '10px' }} /><p>{partner.website}</p></div>
                </div>
            </div>
            <ViewMorePartner open={openViewMore} close={() => setOpenViewMore(false)} data={partner} />
            <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Parceiro' entityType='partners' nameField='name' data={partner} refresh={refresh} onlyPermanent={true} />
            <EditPartner open={openEditPartner} close={() => setOpenEditPartner(false)} data={partner} refresh={refresh} triggerSnackbar={triggerEditDialogSnackbar} />
        </div>
    )
}

export function SmallPartnerCard({ partner, ...props }) {
    return (
        <div className='partners-small-card-main-div' {...props}>
            <div className='partners-small-card-image'>
                <img alt='partner-logo' style={{ objectFit: 'cover' }} width='100%' height='100%' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${partner.logo}`} />
            </div>
            <div className='partners-small-card-info'>
                <p className='partners-small-card-title'>{partner.name}</p>
                <p className='partners-small-card-address'>{partner.address}</p>
                <p className='partners-small-card-site' onClick={() => openInNewTab(partner.website)}>{partner.website}</p>
            </div>
        </div>
    )
}

const CardAction = ({ children, permission }) => {
    const { userRole } = useUserRole()

    if (!userRole[permission]) return ''

    return (children)
}