import React, { useEffect, useState } from 'react'
import { FormControlLabel, FormGroup, Checkbox, Divider, Button, Tooltip } from '@mui/material'
import { TextField } from '../../../Inputs/TextField'
import StandardButton from '../../../Buttons/StandardButton'
import axios from 'axios'
import useDialog from '../../useDialog'
import useGlobalSnackbar from '../../../../Hooks/useGlobalSnackbar';

import {
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material'

import './UserRolesSettings.css'
import DialogPrefab from '../../DialogPrefab'

const checkboxData = [
    {
        category: 'Projetos', permissions: [
            { label: 'Adicionar', property: 'canAddProject' },
            { label: 'Editar', property: 'canEditProject' },
            { label: 'Remover', property: 'canRemoveProject' },
        ]
    },
    {
        category: 'News', permissions: [
            { label: 'Adicionar', property: 'canAddNews' },
            { label: 'Editar', property: 'canEditNews' },
            { label: 'Remover', property: 'canRemoveNews' },
        ]
    },
    {
        category: 'Tarefas', permissions: [
            { label: 'Adicionar', property: 'canAddTask' },
            { label: 'Editar', property: 'canEditTask' },
            { label: 'Remover', property: 'canRemoveTask' },
        ]
    },
    {
        category: 'Parceiros', permissions: [
            { label: 'Adicionar', property: 'canAddPartner' },
            { label: 'Editar', property: 'canEditPartner' },
            { label: 'Remover', property: 'canRemovePartner' },
        ]
    },
    {
        category: 'Users', permissions: [
            { label: 'Ver Users', property: 'canViewUser' },
            { label: 'Adicionar', property: 'canAddUser' },
            { label: 'Editar', property: 'canEditUser' },
            { label: 'Remover', property: 'canRemoveUser' },
        ]
    },
    {
        category: 'Categorias', permissions: [
            { label: 'Adicionar', property: 'canAddCategory' },
            { label: 'Editar', property: 'canEditCategory' },
            { label: 'Remover', property: 'canRemoveCategory' },
        ]
    },
    {
        category: 'Icons', permissions: [
            { label: 'Adicionar', property: 'canAddIcons' },
            { label: 'Editar', property: 'canEditIcons' },
            { label: 'Remover', property: 'canRemoveIcons' },
        ]
    },
    {
        category: 'Temas', permissions: [
            { label: 'Adicionar', property: 'canAddThemes' },
            { label: 'Editar', property: 'canEditThemes' },
            { label: 'Remover', property: 'canRemoveThemes' },
        ]
    },
    {
        category: 'Misc', permissions: [
            { label: 'Gerir Lixo', property: 'canManageTrash' },
            { label: 'Gerir Preferências', property: 'canManageSettings' },
            { label: 'Vew Estatísticas', property: 'canViewStatistics' },
        ]
    },
]

let stateChanges = {}

function UserRolesSettings(props) {
    const [selectedRole, setSelectedRole] = useState(null)
    const [originalData, setOriginalData] = useState()
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false)
    const { openInfoDialog } = useDialog()
    const { showSnackbar } = useGlobalSnackbar();

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.checked

        setSelectedRole(state => ({
            ...state,
            ...{ [name]: Boolean(value) }
        }))

        if (originalData[name] !== value) {
            stateChanges[name] = Boolean(value)
        } else {
            delete stateChanges[name]
        }
    }

    useEffect(() => {
        if (selectedRole) {
            setOriginalData(props.userRoles.filter(role => role.id === selectedRole.id)[0])
        }
    }, [selectedRole])


    const confirmSwitchRole = (role) => {
        if (Object.keys(stateChanges).length > 0) {
            openInfoDialog(
                {
                    title: 'Alterações não gravadas',
                    message: `Fez alterações ao cargo ${selectedRole.name} mas não as gravou. \nPretende descartar as alterações e mudar para o cargo ${role.name}? `
                },
                'y/n',
                () => handleChangeSelectedRole(role)
            )
        } else {
            handleChangeSelectedRole(role)
        }
    }

    const handleChangeSelectedRole = (role) => {
        setSelectedRole(role);
        stateChanges = {}
    }

    const handleSubmit = async () => {

        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/userRoles/${selectedRole.id}`, stateChanges, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            stateChanges = {}
            setSelectedRole(null)
            props.refresh()
            showSnackbar(res.data, '', 'success')

        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
        })
    }

    const createNewRole = async (name) => {

        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/userRoles/`, { name }, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            setSelectedRole(null)
            props.refresh()
            showSnackbar(res.data, '', 'success')
        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
        })
    }

    const removeRole = async () => {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/userRoles/${selectedRole.id}`, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            setSelectedRole(null)
            props.refresh()
            showSnackbar(res.data, '', 'success')

        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
        })
    }

    const editRole = async (name) => {

        await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/userRoles/${selectedRole.id}`, { name }, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            setSelectedRole(null)
            props.refresh()
            showSnackbar(res.data, '', 'success')

        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
        })
    }

    return (
        <div className='roles-settings-container'>
            <p className='roles-settings-title'>Cargos dos Usuários</p>
            <div className='roles-settings-bottom'>
                <div className='roles-selector'>
                    {props.userRoles.map(role => {
                        return (
                            <RolesCard key={role.id} active={role.id === selectedRole?.id ? 'true' : 'false'} name={role.name} onClick={() => confirmSwitchRole(role)} />
                        )
                    })}
                    <Tooltip title='Add new Role'>
                        <div className='role-card' onClick={() => setOpenAddRoleDialog(true)}>
                            +
                        </div>
                    </Tooltip>
                </div>
                <div className='roles-info'>
                    {selectedRole &&
                        <div className='role-permissions-main-div'>
                            <div className='role-permissions-title-div'>
                                <p className='role-permissions-title'>{selectedRole.name}</p>
                                <div className='role-permissions-actions'>
                                    <Tooltip title='Edit Role Name'>
                                        <EditIcon onClick={() => setOpenEditDialog(true)} />
                                    </Tooltip>
                                    {selectedRole.id !== 1 &&
                                        <Tooltip title='Remove Role'>
                                            <DeleteIcon onClick={() => removeRole()} />
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                            {checkboxData.map((category, index) => {
                                return (
                                    <div key={index} className='roles-category'>
                                        <p className='role-category-title'>{category.category}</p>
                                        <Divider orientation='horizontal' sx={{ width: '80%' }} />
                                        <div className='role-category-checkbox'>
                                            <FormGroup key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                {category.permissions.map((permissions, index) => {
                                                    return (
                                                        <FormControlLabel key={index} control={<Checkbox
                                                            name={permissions.property}
                                                            color='primary'
                                                            disabled={selectedRole.id === 1}
                                                            value={selectedRole[permissions.property]}
                                                            checked={selectedRole[permissions.property]}
                                                            onChange={(e) => handleInput(e)}
                                                        />} label={permissions.label} />
                                                    )
                                                })}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
            <Button disabled={Object.keys(stateChanges).length < 1} sx={{ margin: '10px' }} onClick={handleSubmit} >Gravar alterações</Button>
            <EditDialog open={openEditDialog} close={() => setOpenEditDialog(false)} ocb={(name) => editRole(name)} default={selectedRole?.name} />
            <AddDialog open={openAddRoleDialog} close={() => setOpenAddRoleDialog(false)} ocb={(name) => createNewRole(name)} />
        </div>
    )
}

const RolesCard = (props) => {
    return (
        <div className={`role-card ${props.active === 'true' ? 'active' : ''}`} {...props}>
            <p>{props.name}</p>
        </div>
    )
}

const EditDialog = (props) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        if (props.default) {
            setValue(props.default)
        }
    }, [props.default])

    return (
        <DialogPrefab
            maxWidth="xs"
            open={props.open}
            close={() => { props.close(); setValue('') }}
            aria-labelledby="max-width-dialog-title"
            title={`Editar cargo ${props.default}`}

        >
            <div className="info-dialog-action">
                <div className="info-dialog-text-div">
                    <p>Escreva o novo nome para este cargo</p>
                    <TextField
                        required
                        autoFocus
                        id='name'
                        name='name'
                        label='Nome'
                        size='small'
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        error={value.length === 0}
                    />
                </div>
                <StandardButton disabled={value.length === 0 || value === props?.default} onClick={() => { props.ocb(value); props.close() }}>
                    Submeter
                </StandardButton>
            </div>
        </DialogPrefab>
    )
}


const AddDialog = (props) => {
    const [value, setValue] = useState('')


    return (
        <DialogPrefab
            maxWidth="xs"
            open={props.open}
            close={() => { props.close(); setValue('') }}
            aria-labelledby="max-width-dialog-title"
            title='Adicionar novo cargo'
        >
            <div className="info-dialog-action">
                <div className="info-dialog-text-div">
                    <p>Escreva o nome para este cargo</p>
                    <TextField
                        required
                        autoFocus
                        id='name'
                        name='name'
                        label='Nome'
                        size='small'
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        error={value.length === 0}
                    />
                </div>
                <StandardButton disabled={value.length === 0} onClick={() => { props.ocb(value); props.close() }}>
                    Submeter
                </StandardButton>
            </div>
        </DialogPrefab>
    )
}

export default UserRolesSettings