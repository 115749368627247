import React, { Component } from 'react'
import axios from 'axios'
import { DialogContent, Stepper, Step, StepLabel } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { RateReview, Preview } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import DialogPrefab from '../../DialogPrefab'
import { Grid } from 'react-loading-icons'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'
import Review from './Review'

import '../../StepDialog.css'

const steps = ['Informação', 'Rever Informação']

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, black 0%, var(--dark-blue) 50%, black 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, black 0%, var(--dark-blue) 50%, black 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const StyledStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, black 0%, var(--dark-blue) 50%)',
        border: '2px solid white',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, black 0%, var(--dark-blue) 50%)',
    }),
}));

function ColorLibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <RateReview />,
        2: <Preview />,
    };

    return (
        <StyledStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </StyledStepIconRoot>
    );
}

export default class EditTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            title_pt: props.task.title_pt,
            title_en: props.task.title_en,
            milestone: props.task.milestone,
            category: props.task.category.id === 1 ? null : props.task.category,
            project: props.task.project,
            online: props.task.online,
            local_craft: props.task.local_craft,
            icon: props.task.icon,
            infoComplete: true,
            submitting: false
        }
        this.changedState = {}
    }

    resetState = () => {
        this.setState({ ...this.state, activeStep: 0 })
        this.changedState = {}
    }

    checkNextBtn = () => {
        if (this.state.activeStep === 0 && this.state.infoComplete) return true
        return false
    }

    checkSubmitBtn = () => {
        return Object.keys(this.changedState).length > 0
    }

    handleNextStep = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }

    handlePrevStep = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };

    addToChangedState = (property, value) => {
        if (property === 'infoComplete') return;

        if (typeof value === 'object') {
            if (value.id !== this.props.task[property]?.id) {
                this.changedState[property] = value;
            } else {
                delete this.changedState[property];
            }

            return;
        }

        if (value.toString() !== this.props.task[property].toString()) {
            this.changedState[property] = value;
        } else {
            delete this.changedState[property];
        }
    }

    updateState = (property, value, ignoreToChangedState) => {
        this.setState({ [property]: value }, () => {
            if (!ignoreToChangedState) this.addToChangedState(property, value)
        })
    }

    getStepContent = () => {
        switch (this.state.activeStep) {
            case 0: return <Info
                state={this.state}
                updateStateOnParent={this.updateState}
                projectsData={this.props.projectsData}
                categoriesData={this.props.categoriesData}
                addCategory={this.props.addCategory}
                addProject={this.props.addProject}
            />
            case 1: return <Review state={this.changedState} previousState={this.state} />
            default: return ''
        }
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const data = {}

        Object.entries(this.changedState).forEach(([key, value]) => {
            switch (key) {
                case 'project': data.projectId = value.id
                    break;
                case 'category': data.categoryId = value.id
                    break;
                case 'icon': data.iconId = value.id
                    break;
                default: data[key] = value
            }
        })

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/tasks/${this.props.task.id}`, data, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            this.setState({ submitting: false })
            // this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }

    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='md'
                open={this.props.open}
                close={this.props.close}
                title='Editar Tarefa'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-stepper'>
                        <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<StyledStepConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorLibStepIcon}><p style={{ color: 'var(--dark-beige)', fontSize: '14px', letterSpacing: '2px' }}>{label}</p></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className='add-dialog-inputs' style={{ height: '350px' }}>
                        {this.getStepContent()}
                    </div>
                    <div className='add-dialog-action'>
                        {this.state.activeStep !== 0 && <StandardButton onClick={this.handlePrevStep} style={{ marginRight: '20px', color: 'var(--dark-beige)' }} >Anterior</StandardButton>}
                        {this.state.activeStep === steps.length - 1 ? (
                            this.state.submitting ? (
                                <Grid fill='var(--dark-beige)' height='50px' width='50' />
                            ) : (
                                <StandardButton disabled={!this.checkSubmitBtn()} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                            )
                        ) : (
                            <StandardButton disabled={!this.checkNextBtn()} onClick={this.handleNextStep} >Seguinte</StandardButton>
                        )}
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}