import axios from 'axios'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const getApiKey = async () => {
    return JSON.parse(sessionStorage.getItem('token'))?.token
}

export const loginUser = async (email, password) => {
    return await axios.post(`${SERVER_URL}/api/user/auth`, { email, password })
}

export const sendForgotPass = async (email) => {
    return await axios.post(`${SERVER_URL}/api/user/forgotpass`, { email })
}

export const resetPass = async (data) => {
    return await axios.post(`${SERVER_URL}/api/user/resetpass`, data)
}

export const getUser = async (id, key) => {
    if (!key) key = await getApiKey();

    return await axios.get(`${SERVER_URL}/api/users/${id}`, { headers: { key: key } })
}

export const getUserForPassReset = async (id, secret) => {
    return await axios.get(`${SERVER_URL}/api/user/getuserforpassreset/${id}/${secret}`)
}

export const setEntityOnlineOffline = async (entity, id, online) => {
    return await axios.put(`${SERVER_URL}/api/${entity}/${id}`, { online }, { headers: { key: await getApiKey() } })
}

export const getEntity = async (entity, id) => {
    return await axios.get(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ''}`, { headers: { key: await getApiKey() } })
}

export const editEntity = async (entity, id, data) => {
    return await axios.put(`${SERVER_URL}/api/${entity}/${id}`, data, { headers: { key: await getApiKey() } })
}

export const removeEntity = async (entity, id, permanent) => {
    return await axios.delete(`${SERVER_URL}/api/${entity}/${id}`, { headers: { 'key': await getApiKey() }, data: { permanent } })
}

export const publishNews = async (id) => {
    return await axios.post(`${SERVER_URL}/api/news/publish/${id}`, {}, { headers: { key: await getApiKey() } })
}

export const removeUser = async (id, password) => {
    return await axios.delete(`${SERVER_URL}/api/users/${id}`, { headers: { 'key': await getApiKey() }, data: { password } })
}

export const removeFromTrash = async (ids, entity) => {
    return await axios.put(`${SERVER_URL}/api/trash/delete`, { ids, entity }, { headers: { key: await getApiKey() } })
}

export const restoreFromTrash = async (ids, entity) => {
    return await axios.put(`${SERVER_URL}/api/trash/restore`, { ids, entity }, { headers: { key: await getApiKey() } })
}

export const removeAllFromTrash = async (ids, entity) => {
    return await axios.put(`${SERVER_URL}/api/trash/empty`, {}, { headers: { key: await getApiKey() } })
}

export const getNewsletter = async () => {
    return await axios.get(`${SERVER_URL}/api/newsletter/getEmails`, { headers: { key: await getApiKey() } })
}