import React from 'react'
import MUIDatatables from 'mui-datatables'
import { styled } from '@mui/material/styles'
import { TailSpin } from 'react-loading-icons'

const MUIDatatablesStyled = styled(MUIDatatables)({
    borderRadius: '20px',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to right, var(--dark-blue), black)',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--dark-blue)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        }
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--dark-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--dark-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--dark-blue)',
        borderLeft: '5px solid var(--light-beige)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        borderLeft: '5px solid transparent'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'white',
    }
})

const options = {
    viewColumns: false,
    filter: false,
    responsive: 'standard',
    selectableRows: 'none',
    rowsPerPageOptions: [10, 25, 50, 100],
    textLabels: {
        body: {
            noMatch: "Sem resultados",
            toolTip: "Ordenar",
            columnHeaderToolTip: column => `Ordenar por ${column.label}`
        },
        pagination: {
            next: 'Proxima',
            previous: 'Anterior',
            rowsPerPage: 'Linhas por pagina',
            displayRows: 'de'
        },
        toolbar: {
            search: 'Procurar',
            downloadCsv: 'Download CSV',
            print: 'Imprimir',
            viewColumns: 'Ver Colunas',
            filterTable: 'Filtrar Tabela'
        },
        filter: {
            all: 'ALL',
            title: 'FILTROS',
            reset: 'RESET'
        },
        viewColumns: {
            title: 'Mostrar Colunas',
            titleAria: 'Mostrar/Esconder Colunas',
        }
    }
};

const backdropDivStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '500',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '20px'
}

function TablePrefab({ data, columns, loading, title, customOptions }) {
    return (
        <div style={{ marginTop: '20px', position: 'relative' }}>
            {loading &&
                <div style={backdropDivStyle}> <TailSpin stroke='var(--light-beige)' height='60px' width='60px' /> </div>
            }
            <MUIDatatablesStyled
                title={title}
                data={data}
                columns={columns}
                options={customOptions ? customOptions : options}
            />
        </div>
    )
}

export default TablePrefab