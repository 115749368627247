import React, { useEffect, useState } from 'react'
import TablePrefab from './TablePrefab'
import { getTaskCompletePercentage } from '../../utils'
import { setEntityOnlineOffline } from '../../Api/Requests'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { tasksTooltips } from '../../TooltipHints'
import { BooleanBodyComponent, ProgressBodyComponent, ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import ViewMoreTask from '../Dialogs/Tasks/ViewMoreTask'
import EditTask from '../Dialogs/Tasks/Edit/EditTask'
import AddContribution from '../Dialogs/Contributions/Add/AddContribution'

function TasksTable(props) {
    const [data, setData] = useState([])
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [openViewMoreDialog, setOpenViewMoreDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openAddCont, setOpenAddCont] = useState(false)

    useEffect(() => {
        props.data.forEach(task => {

            if (task.contributions.length > 0) {
                const value = task.contributions.reduce((prev, curr) => {
                    return { quantity: prev.quantity + curr.quantity }
                })

                task.current = value.quantity
            }

            task.numberOfContributions = task.contributions.length;
            task.progress = getTaskCompletePercentage(task);
            task.categoryName = task.category.name_pt
        })

        setData(props.data)

    }, [props])

    const getTaskById = id => {
        return props.data.filter(task => task.id === id)[0]
    }

    const setOnlineOffline = (task, online) => {
        setSubmitting(true)
        setEntityOnlineOffline('tasks', task.id, online).then(res => {
            showSnackbar(`A tarefa ${task.title_pt} está agora ${online ? 'Online' : 'Offline'}`, '', "success")
            setSubmitting(false)
            props.refresh()
        }, err => {
            console.log(err)
            setSubmitting(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const handleOpenDelete = (props) => {
        setSelectedTask(getTaskById(props.meta.rowData[0]))
        setOpenDeleteDialog(true)
    }

    const handleOpenViewMore = (props) => {
        setSelectedTask(getTaskById(props.meta.rowData[0]))
        setOpenViewMoreDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedTask(getTaskById(props.meta.rowData[0]))
        setOpenEditDialog(true)
    }

    const handleOpenAddCont = (props) => {
        setSelectedTask(getTaskById(props.meta.rowData[0]))
        setOpenAddCont(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'title_pt', label: 'Titulo', options: { filter: false, searchable: true } },
        { name: 'categoryName', label: 'Categoria', options: { filter: false, searchable: false } },
        { name: 'numberOfContributions', label: 'Contribuições', options: { filter: false, searchable: false, hint: tasksTooltips.contributions, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'milestone', label: 'Objetivo', options: { filter: false, searchable: false, hint: tasksTooltips.milestone, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'current', label: 'Atual', options: { filter: false, searchable: false, hint: tasksTooltips.current, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'progress', label: 'Progresso', options: { filter: true, searchable: false, hint: tasksTooltips.progress, customBodyRender: (value, tableMeta, updateValue) => <ProgressBodyComponent value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'online', label: 'Online?', options: { filter: true, searchable: false, hint: tasksTooltips.online, customBodyRender: (value, tableMeta, updateValue) => <BooleanBodyComponent value={value} meta={tableMeta} update={updateValue} change={setOnlineOffline} /> } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onView={handleOpenViewMore} onDelete={handleOpenDelete} onAdd={handleOpenAddCont} entity='Task' /> } },
    ]

    return (
        <>
            <TablePrefab data={data} columns={columns} loading={submitting} title='Tarefas' />
            {selectedTask &&
                <>
                    <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Tarefa' entityType='tasks' nameField='title_pt' data={selectedTask} refresh={props.refresh} />
                    <ViewMoreTask open={openViewMoreDialog} close={() => setOpenViewMoreDialog(false)} data={selectedTask} />
                    <EditTask
                        open={openEditDialog}
                        close={() => { setOpenEditDialog(false); setSelectedTask(null) }}
                        refresh={props.refresh} task={selectedTask}
                        triggerSnackbar={triggerSnackbar}
                        projectsData={props.projectsData}
                        categoriesData={props.categoriesData}
                        addCategory={props.addCategory}
                        addProject={props.addProject}
                    />
                    <AddContribution open={openAddCont} close={() => setOpenAddCont(false)} refresh={props.refresh} tasksData={null} task={selectedTask} triggerSnackbar={triggerSnackbar} partnersData={props.partnersData} addPartner={props.addPartner}/>
                </>
            }
        </>
    )
}

export default TasksTable