import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'
import { CustomAutoComplete } from '../../../Inputs/CustomAutoComplete'
import CustomTooltip from '../../../Display/CustomTooltip';

import '../Info.css'

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            formIsValid: false,
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateForm()
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    handleAutocompleteInput = (field, data) => {
        this.props.updateStateOnParent(field, data)
        this.setState({ [field]: data }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.quantity > 0 &&
                this.state.task !== null &&
                this.state.partner !== null
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    render() {

        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' >
                    <p className='contributions-info-dialog-text-title'>Criar uma nova contribuição para a tarefa:</p>
                    {this.state.task &&
                        <p className='contributions-info-dialog-text-title' style={{ fontSize: '14px' }}>{this.state.task.title_pt}</p>
                    }
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column' }}>
                    <TextField
                        size='small'
                        label='Contribuição'
                        name='quantity'
                        type='number'
                        sx={{ margin: '10px 0' }}
                        value={this.state.quantity}
                        onChange={this.handelInput}
                        required
                        error={this.state.quantity < 1}
                    />
                    {this.props.tasksData ? (

                        <CustomAutoComplete
                            OptionLabel='title_pt'
                            size='small'
                            fullWidth
                            sx={{ margin: '10px 0' }}
                            data={this.props.tasksData}
                            multiple={false}
                            id='task'
                            label='Tarefa'
                            value={this.state.task}
                            onChange={(id, value) => this.handleAutocompleteInput('task', value)}
                            error={!this.state.task}
                        />
                    ) : ''}
                    <div className='autocomplete-plus-add-button'>
                        <CustomAutoComplete
                            OptionLabel='name'
                            size='small'
                            fullWidth
                            sx={{ margin: '10px 0' }}
                            data={this.props.partnersData}
                            multiple={false}
                            id='partner'
                            label='Parceiro'
                            value={this.state.partner}
                            onChange={(id, value) => this.handleAutocompleteInput('partner', value)}
                            error={!this.state.partner}
                        />
                        <CustomTooltip title='Adicionar novo Parceiro'>
                            <button className='add-more-button' onClick={this.props.addPartner}>+</button>
                        </CustomTooltip>
                    </div>
                </div>
            </div>
        )
    }
}


