import React, { useState, useEffect } from 'react'
import StandardButton from '../Components/Buttons/StandardButton'
import { PasswordTextField } from '../Components/Inputs/TextField'
import { resetPass, getUserForPassReset } from '../Api/Requests'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import { useNavigate } from 'react-router-dom'

import Logo from '../assets/logos/LUGAR.svg'
import './css/PassReset.css'

function PassReset() {
    const { showSnackbar } = useGlobalSnackbar()
    const navigate = useNavigate()

    const [submitting, setSubmitting] = useState()

    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
        passwordIsValid: true,
        confirmPasswordIsValid: true,
        formIsValid: false,
        formErrors: { password: '', confirmPassword: '' }

    })

    const navigateToLogin = () => navigate('/login', { replace: true })
    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {

        if (!urlParams.get('secret') || !urlParams.get('uid')) {
            navigateToLogin();
            return;
        }

        getUserForPassReset(urlParams.get('uid'), urlParams.get('secret')).then(res => {
        }, err => {
            navigateToLogin()
            return;
        })

    }, [])

    const handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value

        setState(state => ({ ...state, ...{ [name]: value } }))

        switch (name) {
            case 'password':
                const passIsValid = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/).test(value)
                if (state.confirmPassword.length > 0) setState(state => ({ ...state, ...{ confirmPassword: '', confirmPasswordIsValid: false } }))
                setState(state => ({
                    ...state,
                    ...{
                        passwordIsValid: passIsValid,
                        formErrors: {
                            password: passIsValid ? '' : 'Password deve conter no mínimo 8 caracteres, incluindo minúsculas, maiúsculas, números',
                            confirmPassword: state.formErrors.confirmPassword
                        }
                    }
                }))
                break;
            case 'confirmPassword':
                const confirmPassISValid = value === state.password
                setState(state => ({
                    ...state,
                    ...{
                        confirmPasswordIsValid: confirmPassISValid,
                        formErrors: {
                            password: state.formErrors.password,
                            confirmPassword: confirmPassISValid ? '' : 'Passwords não coincidem'
                        }
                    }
                }))
                break;
            default: return ''
        }
    }

    useEffect(() => {
        console.log(state)
        if (state.confirmPassword.length > 0 && state.password.length > 0) {
            setState(state => ({ ...state, ...{ formIsValid: state.passwordIsValid && state.confirmPasswordIsValid } }))
        }
    }, [state.password, state.confirmPassword])

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            id: urlParams.get('uid'),
            password: state.password,
            secret: urlParams.get('secret'),
            secure: true
        }

        setSubmitting(true)

        resetPass(data).then(res => {
            setSubmitting(false)
            showSnackbar(`${res.data}. Irá ser redirecionado para a pagina de login dentro de segundos`, '', "success", { vertical: 'bottom', horizontal: 'center' }, false)

            setTimeout(() => {
                navigate('/login', { replace: true })
            }, 5000)
        }, err => {
            console.log(err)
            setSubmitting(false)
            showSnackbar(err.response ? err.response.data : 'Cannot communicate with the server, please try again later', '', "error", { vertical: 'bottom', horizontal: 'center' }, false)
        })
    }

    return (
        <div className='pass-reset-main-div'>
            <div className='login-right-side-div'></div>
            <div className='login-left-side-div'></div>
            <img alt='lugar logo' src={Logo} className='pass-reset-logo' />
            <p className='pass-reset-title'>Defina a sua nova password</p>
            <form onSubmit={handleSubmit} className='pass-reset-form'>
                <PasswordTextField
                    autoFocus={true}
                    onChange={handleInput}
                    label='Escreva aqui a sua nova password'
                    helperText={state.formErrors.password}
                    error={!state.passwordIsValid}
                    value={state.password}
                />
                <PasswordTextField
                    label='Confirme a sua nova password'
                    name='confirmPassword'
                    onChange={handleInput}
                    helperText={state.formErrors.confirmPassword}
                    error={!state.confirmPasswordIsValid}
                    value={state.confirmPassword}
                />
                <StandardButton
                    disabled={!state.formIsValid}
                    loading={submitting}
                    style={{ marginTop: '25px' }}
                    type={submitting ? 'button' : 'submit'}
                >
                    Submeter
                </StandardButton>
            </form>
        </div>
    )
}

export default PassReset