import React from 'react'
import { Autocomplete, Checkbox, Popper, TextField, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'


const FilterAutoCompleteStyled = styled(TextField)({
    margin: '0',
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInputBase-root': {
        padding: '0 10px',
        '& fieldset': {
            borderWidth: 2,
            borderColor: 'white',
        },
        '& input': {
            color: 'white',
            height: '15px',
        },
        '&:hover fieldset': {
            borderColor: 'white',
            borderLeftWidth: 6
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderLeftWidth: 6
        },
        '&.Mui-error fieldset': {
            borderLeftWidth: 6,
            borderColor: 'var(--light-red)',
            padding: '4px !important',
        },
        '& button': {
            color: 'white'
        }
    },
})

const CustomAutoCompleteStyled = styled(TextField)({
    margin: '0',
    '& label.Mui-focused': {
        color: 'var(--dark-beige)',
    },
    '& .MuiInputBase-root': {
        '& fieldset': {
            borderWidth: 2,
            borderColor: 'var(--dark-beige)',
        },
        '& input': {
            color: 'var(--dark-beige)',
        },
        '&:hover fieldset': {
            borderColor: 'var(--dark-beige)',
            borderLeftWidth: 6
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--dark-beige)',
            borderLeftWidth: 6
        },
        '&.Mui-error fieldset': {
            borderLeftWidth: 6,
            borderColor: 'var(--light-red)',
        },
        '& button': {
            color: 'var(--dark-beige)'
        }
    },
})

const CustomPopper = (props) => {
    return <Popper {...props} style={{ width: 'max-content', maxWidth: '300px', minWidth: '150px' }} />
}

export function CustomAutoComplete({ data, label, id, onChange, value, name, OptionLabel, helperText, error, ...props }) {

    return (
        <Autocomplete
            id={id}
            renderTags={(tagValue) =>
                <p style={{ color: 'white' }}>{`${tagValue.length} ${tagValue.length > 1 ? 'itens selecionados' : 'item selecionado'}`}</p>
            }
            options={data}
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            value={value}
            disableClearable
            onChange={(e, newValue) => onChange(id, newValue)}
            getOptionLabel={(option) => option[OptionLabel]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: '250px', height: 'fit-content', fontSize: '14px' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize='small' />}
                        checkedIcon={<CheckBox fontSize='small' />}
                        style={{ color: 'var(--dark-beige)' }}
                        checked={selected}
                    />
                    {option[OptionLabel]}
                </li>
            )}
            renderInput={(params) =>
                <CustomAutoCompleteStyled required {...params} error={error} label={label} name={name} helperText={helperText}  />
            }
            {...props}
        />
    )
}


export function FilterAutocomplete({ data, label, id, onChange, value, optionLabel, ...props }) {

    return (
        <Autocomplete
            id={id}
            renderTags={(tagValue) =>
                <p style={{ color: 'white' }}>{`${tagValue.length} ${tagValue.length > 1 ? 'Itens selecionados' : 'Item selecionado'}`}</p>
            }
            options={data}
            disableCloseOnSelect
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            value={value}
            disableClearable
            onChange={(e, newValue) => onChange(id, newValue)}
            getOptionLabel={(option) => option[optionLabel]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ width: '250px', height: 'fit-content', fontSize: '14px' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize='small' />}
                        checkedIcon={<CheckBox fontSize='small' />}
                        style={{ color: 'var(--dark-beige)' }}
                        checked={selected}
                    />
                    {option[optionLabel]}
                </li>
            )}
            renderInput={(params) =>
                <FilterAutoCompleteStyled {...params} label={label} name='location' />
            }
            {...props}
        />
    )
}