import React, { useState, useEffect } from 'react'
import StatsTable from '../Tables/StatsTable'
import ProjectStatsChart from '../Charts/projects/ProjectStatsChart'
import { Grid } from 'react-loading-icons'

import './ProjectsStatsPanel.css'

function ProjectStatsPanel(props) {
    const [views, setViews] = useState([])
    const [shares, setShares] = useState([])
    const [viewChartTitle, setViewChartTitle] = useState('Visualizações / Partilhas')

    useEffect(() => {
        setViews([...props.data.views])
        setShares([...props.data.shares])
    }, [props.data])

    const setActiveEntity = (entity) => {
        setViewChartTitle(entity.title)
        const filteredViews = [...props.data.views].filter(view => view.entity_id === entity.id)
        const filteredShares = [...props.data.shares].filter(share => share.entity_id === entity.id)
        setViews(filteredViews)
        setShares(filteredShares)
    }

    const clearEntitySelected = () => {
        setViewChartTitle('Visualizações / Partilhas')
        setViews([...props.data.views])
        setShares([...props.data.shares])
    }

    return (
        <div className='projects-stats-panel-main-div'>
            <div className='projects-stats-panel-title'>
                <p>Estatísticas de Projetos</p>
            </div>
            {props.loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' style={{ marginTop: '150px'}} /> :
                <div className='projects-stats-panel-content'>
                    <div className='projects-charts-div'>
                        <ProjectStatsChart views={views} shares={shares} title={viewChartTitle} clearSelected={clearEntitySelected} />
                    </div>
                    <div className='projects-stats-table'>
                        <StatsTable data={props.data.data} setActiveEntity={setActiveEntity} type='Projetos' nameProperty='title' />
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectStatsPanel