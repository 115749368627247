import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles';
import './CustomToggleButton.css'

function CustomToggleButton(props) {
    return (
        <StyledEngineProvider injectFirst>
            <ToggleButtonGroup
                sx={props.light ? { border: '2px solid white' } : {}}
                value={props.value}
                exclusive
            >
                {props.data.map(elem => {
                    return (
                        <ToggleButton
                            key={elem.label}
                            size='small'
                            value={elem.value}
                            onClick={() => props.onChange(elem.value)}
                            classes={{
                                root: 'toggle-button',
                                selected: 'toggle-button selected',
                            }}
                        >
                            {elem.label}
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </StyledEngineProvider>
    )
}

export default CustomToggleButton