import BigSwitch from '../../Switches/TableSwitch'
import { Edit, Delete, Visibility, AddTask, Restore } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useUserRole } from '../../../Hooks/useUser'

import './BodyComponents.css'

export const BooleanBodyComponent = (props) => {
    const projectObject = { id: props.meta.rowData[0], title: props.meta.rowData[1] }
    return (
        <div className='boolean-main-div'>
            <BigSwitch checked={props.value} onChange={(e) => props.change(projectObject, e.target.checked)} />
        </div>
    )
}

export const BooleanBodyComponentTrash = (props) => {
    return (
        <div className='boolean-main-div'>
            <BigSwitch checked={props.value} />
        </div>
    )
}

export const ProgressBodyComponent = (props) => {
    return (
        <div className='progress-main-div' >
            <div className='progress-inner-div'>
                <div className='progress-inner-inner-div' style={{ width: `${props.value}%` }}></div>
            </div>
        </div>
    )
}

export const ActionsBodyComponent = (props) => {
    const { userRole } = useUserRole()

    return (
        <div className='actions-main-div'>
            {(props.onAdd && userRole.canAddContribution) &&
                <Tooltip disableInteractive title='Adicionar Contribuição'>
                    <AddTask fontSize='small' onClick={() => props.onAdd(props)} />
                </Tooltip>
            }
            {(props.onEdit && userRole[`canEdit${props.entity}`]) &&
                <Tooltip disableInteractive title='Editar'>
                    <Edit fontSize='small' onClick={() => props.onEdit(props)} />
                </Tooltip>
            }
            {props.onView &&
                <Tooltip disableInteractive title='Ver Mais Info'>
                    <Visibility fontSize='small' onClick={() => props.onView(props)} />
                </Tooltip>
            }
            {(props.onDelete && userRole[`canRemove${props.entity}`]) &&
                <Tooltip disableInteractive title='Eliminar'>
                    <Delete fontSize='small' onClick={() => props.onDelete(props)} />
                </Tooltip>
            }
        </div>
    )
}

export const ActionsBodyComponentTrash = (props) => {
    return (
        <div className='actions-main-div'>
            <Tooltip disableInteractive title='Eliminar'>
                <Delete fontSize='small' onClick={() => props.onDelete(props)} />
            </Tooltip>
            <Tooltip disableInteractive title='Ver Mais Info'>
                <Visibility fontSize='small' onClick={() => props.onView(props)} />
            </Tooltip>
            <Tooltip disableInteractive title='Restaurar'>
                <Restore fontSize='small' onClick={() => props.onRestore(props)} />
            </Tooltip>
        </div>
    )
}