import React, { useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import moment from 'moment'
import { Divider, Button } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import DeleteDialog from '../DeleteDialog'
import EditIcon from './Edit/EditIcon'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { useUserRole } from '../../../Hooks/useUser'

import './ViewMore.css'

function ViewMore(props) {
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const { showSnackbar } = useGlobalSnackbar()
    const { userRole } = useUserRole()

    const triggerDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='xs'
            title={`Icon ${props.icon.icon_name}`}
        >
            <div className='view-more-icons'>
                <div className='view-more-icons-info'>
                    <p>{props.icon.icon_name}</p>
                    <img alt={props.icon.icon_name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${props.icon.icon}`} width='60px' height='60px' />
                </div>
                <Divider orientation='horizontal' />
                <div className='view-more-icons-meta'>
                    <p>{`ID: ${props.icon.id}`}</p>
                    <p>{`Icon: ${props.icon.icon}`}</p>
                    <p>{`Criado em: ${moment(props.icon.createdAt).format('DD-MMM-YYYY HH:mm')}`}</p>
                    <p>{`Modificado em: ${moment(props.icon.updatedAt).format('DD-MMM-YYYY HH:mm')}`}</p>
                </div>
                <TasksAndCategoriesSection tasks={props.icon.tasks} categories={props.icon.categories} />
                <Divider orientation='horizontal' />
                <div className='view-more-icons-actions'>
                    {userRole.canEditIcons &&
                        <CardAction permission='canEditIcons'>
                            <Button onClick={() => setOpenEdit(true)} startIcon={<Edit />}>Editar Icon</Button>
                        </CardAction>
                    }
                    {userRole.canRemoveIcons &&
                        <CardAction permission='canRemoveIcons'>
                            <Button onClick={() => setOpenDelete(true)} startIcon={<Delete />}>Eliminar Icon</Button>
                        </CardAction>
                    }
                </div>
            </div>
            <DeleteDialog onlyPermanent={true} open={openDelete} close={() => { setOpenDelete(false); props.close() }} entityName='Icon' entityType='icons' nameField='icon_name' data={props.icon} refresh={props.refresh} />
            <EditIcon open={openEdit} close={() => { setOpenEdit(false); props.close() }} data={props.icon} refresh={props.refresh} triggerSnackbar={triggerDialogSnackbar} />
        </DialogPrefab>
    )
}

export default ViewMore

const CardAction = ({ children, permission }) => {
    const { userRole } = useUserRole()

    if (!userRole[permission]) return ''

    return (children)
}

const TasksAndCategoriesSection = ({ tasks, categories }) => {
    const hasTasks = tasks.length > 0;
    const hasCategories = categories.length > 0;

    return (
        <div style={{ width: '100%', textAlign: 'center', maxHeight: '400px', overflowY: 'auto'}}>
            {hasTasks &&
                <>
                    <Divider orientation='horizontal' />
                    <div className='view-more-icon-tasks-alert'>
                        <p style={{ marginBottom: '10px' }}>Existem as seguintes tarefas a utilizar este icon:</p>
                        {tasks.map(task => {
                            return (
                                <p key={task.id}>{`ID: ${task.id}, Nome: ${task.title_pt}`}</p>
                            )
                        })}
                    </div>
                </>
            }
            {hasCategories &&
                <>
                    <Divider orientation='horizontal' />
                    <div className='view-more-icon-tasks-alert'>
                        <p style={{ marginBottom: '10px' }}>Existem as seguintes categorias a utilizar este icon:</p>
                        {categories.map(cat => {
                            return (
                                <p key={cat.id}>{`ID: ${cat.id}, Nome: ${cat.name_pt}`}</p>
                            )
                        })}
                    </div>
                </>
            }
            {(hasTasks || hasCategories) && <p style={{ marginTop: '10px', fontSize: '14px', fontWeight: '600', color: 'var(--dark-beige)' }}>Se editar este ícone, categorias ou tarefas serão atualizadas automaticamente, se eliminar tarefas ou categorias a utilizar este ícone, estas ficarão sem icon</p> }
        </div>
    )
}