import React, { useState, useEffect } from 'react'
import TablePrefab from './TablePrefab'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import EditCategory from '../Dialogs/Categories/Edit/EditCategory'
import placeholderIcon from '../../assets/placeholders/logo_placeholder_1.png'
import useDialog from '../Dialogs/useDialog'

function CategoriesTable(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [selectedCat, setSelectedCat] = useState(null)
    const { openInfoDialog } = useDialog()

    useEffect(() => {
        props.data.forEach(cat => {
            cat.displayIcon = cat.icon?.icon
        })
    }, [props, props.refresh])

    const getCatById = id => {
        return props.data.filter(cat => cat.id === id)[0]
    }

    const handleOpenDelete = (props) => {
        const category = getCatById(props.meta.rowData[0])

        setSelectedCat(category)

        if (category.tasks.length > 0) {
            openInfoDialog({ title: 'Aviso', message: `Ao eliminar esta categoria, ${category.tasks.length} tarefas irão ficar sem categoria.\n\nPoderá mais tarde associar outra categoria a estas tarefas.\nPoderá também ver que tarefas não têm categoria com os filtros na pagina Tarefas.\n\nPretende continuar?` }, 'y/n', () => setOpenDeleteDialog(true))
            return;
        }
        setOpenDeleteDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedCat(getCatById(props.meta.rowData[0]))
        setOpenEditDialog(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'name_pt', label: 'Nome em Português', options: { filter: false, searchable: true } },
        { name: 'name_en', label: 'Nome em Inglês', options: { filter: false, searchable: true } },
        { name: 'displayIcon', label: 'Icon', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <img height='30px' width='30px' alt={value} src={value ? `${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${value}` : placeholderIcon} ></img>, } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onDelete={handleOpenDelete} entity='Category' /> } },
    ]

    return (
        <>
            <TablePrefab data={props.data} columns={columns} loading={props.loading} title='Categorias de tarefas' />
            {selectedCat &&
                <>
                    <EditCategory open={openEditDialog} close={() => { setOpenEditDialog(false); setSelectedCat(null) }} data={selectedCat} refresh={props.refresh} triggerSnackbar={triggerSnackbar} />
                    <DeleteDialog onlyPermanent={true} open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Categoria' entityType='categories' nameField='name_pt' data={selectedCat} refresh={props.refresh} />
                </>
            }
        </>
    )
}

export default CategoriesTable