import React from 'react'

function Review(props) {
   
    return (
        <div className='add-dialog-review-container'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>{Object.entries(props.state).length > 0 ? 'Foram detetadas as seguintes alterações' : 'Não foram detetadas quaisquer alterações'}</p>
            </div>
            <div className='add-dialog-review-main-div'>
                <div className='add-dialog-review-info' style={{ width: '100%' }}>
                    {Object.entries(props.state).map(([key, value]) => {
                        return (
                            <InfoLabel key={key} label={getCorrespondingLabel(key)} value={value} />
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value, fullWidth }) => {
    return (
        typeof value === "object" ? (
            <div className='review-label-div' style={fullWidth ? { width: '100%' } : {}}>
                <p className='review-label-title'>{label}</p>
                <p className='review-label-value'>{label === 'Projeto' ? value.title : label === 'Icon' ? value.icon_name : value.name_pt}</p>
            </div>
        ) : (
            <div className='review-label-div' style={fullWidth ? { width: '100%' } : {}}>
                <p className='review-label-title'>{label}</p>
                <p className='review-label-value'>{typeof value === 'string' ? value : value ? 'Sim' : 'Não'}</p>
            </div>
        )
    )
}

const getCorrespondingLabel = (label) => {
    switch (label) {
        case 'title_pt': return 'Nome da tarefa Português';
        case 'title_en': return 'Nome da tarefa em Inglês';
        case 'milestone': return 'Objetivo'
        case 'category': return 'Categoria';
        case 'project': return 'Projeto';
        case 'online': return 'Online?';
        case 'local_craft': return 'Artesanato Local?'
        case 'icon': return 'Icon'
        default: return label
    }
} 