import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import CategoriesTable from '../Components/Tables/CategoriesTable'
import ThemesTable from '../Components/Tables/ThemesTable'
import Icons from '../Components/Display/Icons'
import { getEntity } from '../Api/Requests'
import AddIcon from '../Components/Dialogs/Icons/Add/AddIcon'
import AddCategory from '../Components/Dialogs/Categories/Add/AddCategory'
import AddNewsTheme from '../Components/Dialogs/NewsThemes/Add/AddNewsTheme'
import { getUserSettings, setUserSettings } from '../UserSettings'

const viewOptions = [
    { label: 'Categorias', value: 'category' },
    { label: 'Icons', value: 'icons' },
    { label: 'Temas', value: 'themes' },
]

function Lists() {
    const [view, setView] = useState(getUserSettings('lists').view.view)
    const [openAddCat, setOpenAddCat] = useState(false)
    const [openAddIcon, setOpenAddIcon] = useState(false)
    const [openAddTheme, setOpenAddTheme] = useState(false)
    const [categories, setCategories] = useState([])
    const [icons, setIcons] = useState([])
    const [themes, setThemes] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { showSnackbar } = useGlobalSnackbar();

    useEffect(() => {

        async function getData() {
            getEntity('categories').then(res => {
                setCategories(res.data.filter(cat => !cat.hide))
            }, err => {
                console.log(err)
                showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            })

            getEntity('icons').then(res => {
                setIcons(res.data)
            }, err => {
                console.log(err)
                showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            })

            getEntity('newsTheme').then(res => {
                setThemes(res.data.filter(theme => !theme.hide))
            }, err => {
                console.log(err)
                showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            })
        }
        setLoading(true);
        getData()
        setLoading(false)
    }, [refresh])

    const topPageInfo = [
        { label: 'Total de categorias', value: categories.length },
        { label: 'Total de icons', value: icons.length },
        { label: 'Noticias temas', value: themes.length }
    ]

    const entityConfig = {
        category: { name:'Categorias', addFunc: () => setOpenAddCat(true), display: <CategoriesTable data={categories} refresh={() => setRefresh(!refresh)} loading={loading} /> },
        icons: { name:'Icons', addFunc: () => setOpenAddIcon(true), display: <Icons data={icons} refresh={() => setRefresh(!refresh)} loading={loading} /> },
        themes: { name: 'Temas' , addFunc: () => setOpenAddTheme(true), display: <ThemesTable data={themes} refresh={() => setRefresh(!refresh)} loading={loading} /> }
    }

    const triggerAddDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='news-main-div'>
            <PageTop
                title={entityConfig[view]?.name}
                info={topPageInfo}
                view={view}
                viewOptions={viewOptions}
                changeView={(view) => { setView(view); setUserSettings('lists', 'view', 'view', view) }}
                addEntity={entityConfig[view]?.addFunc}
            />
            <div className='news-container'>
                {view && entityConfig[view]?.display}
            </div>
            <AddIcon open={openAddIcon} close={() => setOpenAddIcon(false)} refresh={() => setRefresh(!refresh)} triggerSnackbar={triggerAddDialogSnackbar} />
            <AddCategory open={openAddCat} close={() => setOpenAddCat(false)} refresh={() => setRefresh(!refresh)} triggerSnackbar={triggerAddDialogSnackbar} />
            <AddNewsTheme open={openAddTheme} close={() => setOpenAddTheme(false)} refresh={() => setRefresh(!refresh)} triggerSnackbar={triggerAddDialogSnackbar} />
        </div>
    )
}

export default Lists