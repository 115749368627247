import React, { useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import { Button } from '@mui/material'
import { Grid } from 'react-loading-icons'
import { removeUser } from '../../../Api/Requests'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import { PasswordTextField } from '../../Inputs/TextField'

function DeleteUser(props) {
    const [submitting, setSubmitting] = useState(false)
    const [password, setPassword] = useState('')
    const { showSnackbar } = useGlobalSnackbar();

    const handleSubmit = () => {
        setSubmitting(true)

        removeUser(props.user.id, password).then(res => {
            setSubmitting(false)
            showSnackbar(res.data, '', 'success')
            props.refresh()
            props.close()
        }, err => {
            setSubmitting(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
            console.log(err)
        })
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            title={`Eliminar o user ${props.user.name}`}
        >
            <div className='delete-dialog-main-div'>
                <div className='delete-dialog-text-div'>
                    <p>{`Tem a certeza que pretende eliminar o user: ${props.user.name}?`}</p>
                    <p>{`Irá eliminar o user permanentemente. Se pretender continuar introduza a sua password e clique em submeter. Depois deste processo não poderá voltar a trás`}</p>
                </div>
                <div className='delete-dialog-actions-div'>
                    {!submitting ? (
                        <>
                            <div style={{ width: '60%'}}>
                                <PasswordTextField size='small' value={password} onChange={(e) => setPassword(e.target.value)} autoFocus={true} />
                            </div>
                            <Button disabled={password.length < 6} onClick={() => handleSubmit(false)} size='small'>Submeter</Button>
                        </>
                    ) : (
                        <Grid fill='var(--dark-beige)' height='50px' width='50px' />
                    )}
                </div>
            </div>
        </DialogPrefab>
    )
}

export default DeleteUser