import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import { getEntity } from '../Api/Requests'
import { Grid } from 'react-loading-icons'
import { useDebounce } from '../utils'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import UserCard from '../Components/Cards/Users/UserCard'
import AddUser from '../Components/Dialogs/Users/Add/AddUser'

import './css/Users.css'

function Users() {
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openAddUser, setOpenAddUser] = useState(false)

    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const { showSnackbar } = useGlobalSnackbar()
    const debounceSearch = useDebounce(search, 500)

    const topPageInfo = [
        { label: 'Total de users', value: users.length },
    ]

    useEffect(() => {
        setLoading(true)
        getEntity('users').then(res => {
            setUsers(res.data)
            setFilteredData(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            console.log(err)
        })
    }, [refresh])

    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setUsers([...filteredData.filter(user => user.name.toLowerCase().includes(search.toLowerCase()))])
                return;
            }
            setUsers(filteredData)
        }
    }, [debounceSearch])

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='users-main-div'>
            <PageTop
                title='Users'
                info={topPageInfo}
                view={'cards'}
                searchValue={search}
                onChangeSearch={(value) => setSearch(value)}
                addEntity={() => setOpenAddUser(true)}
            />
            <div className='users-container'>
                {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> :
                    users.length === 0 ? <p>Sem resultados para apresentar</p> :
                        users.map(user => {
                            return (
                                <UserCard key={user.id} user={user} refresh={() => setRefresh(!refresh)} />
                            )
                        })
                }
            </div>
            <AddUser open={openAddUser} close={() => setOpenAddUser(false)} refresh={() => setRefresh(!refresh)} triggerSnackbar={triggerSnackbar} />
        </div>
    )
}

export default Users