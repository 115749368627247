import React, { Component } from 'react'
import axios from 'axios'
import { DialogContent } from '@mui/material'
import DialogPrefab from '../../DialogPrefab'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'

import '../../StepDialog.css'

export default class AddContribution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            quantity: 1,
            task: props.task,
            partner: null,
            infoComplete: false,
            submitting: false,
        }
        
        this.initialState = {
            activeStep: 0,
            quantity: 1,
            task: props.task,
            partner: null,
            infoComplete: false,
            submitting: false,
        }
    }

    resetState = () => {
        this.setState({ ...this.initialState })
    }


    updateState = (property, value) => {
        this.setState({ [property]: value })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const data = {
            quantity: this.state.quantity,
            taskId: this.state.task.id,
            partnerId: this.state.partner.id,
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/contributions`, data, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }


    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='xs'
                open={this.props.open}
                close={this.props.close}
                title='Adicionar nova contribuição'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-inputs' style={{ height: '250px', overflow: 'visible' }}>
                        <Info state={this.state} updateStateOnParent={this.updateState} tasksData={this.props.tasksData} partnersData={this.props.partnersData} addPartner={this.props.addPartner} />
                    </div>
                    <div className='add-dialog-action' style={{ justifyContent: 'center' }}>
                        <StandardButton disabled={!this.state.infoComplete} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}
