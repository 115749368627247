import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import useDialog from "../Components/Dialogs/useDialog";

export default function useGlobalSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { openErrDialog } = useDialog()

    const Actions = ({ snackbarKey, description }) => {
        return (
            <>
                {description &&
                    <Button onClick={() => { openErrDialog(description); closeSnackbar(snackbarKey) }} color='inherit' size='small'>VER</Button>
                }
                <Button onClick={() => closeSnackbar(snackbarKey)} color='inherit' size='small'>FECHAR</Button>
            </>
        )
    }

    const showSnackbar = (
        message = '',
        description = '',
        type = 'default',
        position = { vertical: 'bottom', horizontal: 'right' },
        persist = type === 'error' ? true : false,
        autoHideDuration = 5000,
        customComponent = null,
    ) => {
        enqueueSnackbar(message, {
            variant: type,
            anchorOrigin: { horizontal: position.horizontal, vertical: position.vertical },
            persist,
            autoHideDuration,
            content: customComponent ? (key, message) => customComponent : null,
            action: (key) => <Actions snackbarKey={key} description={description} />
        });
    }

    return {
        showSnackbar
    }
}