import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'
import { CloudUpload } from '@mui/icons-material'
import IconPicker from '../../Icons/IconPicker';



export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            openIcons: false,
            formIsValid: false,
        }
    }

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value });
    }

    handleIconInput = icon => {
        this.props.updateStateOnParent('icon', icon);
        this.setState({ icon: icon });
    }


    render() {

        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' style={{ flexDirection: 'row' }} >
                    <p className='contributions-info-dialog-text-title'>Editar Categoria:</p>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column' }}>
                    <TextField
                        size='small'
                        label='Nome em Português'
                        name='name_pt'
                        autoFocus={true}
                        value={this.state.name_pt}
                        onChange={this.handelInput}
                        required
                        error={this.state.name_pt.length < 3}
                    />
                    <TextField
                        size='small'
                        label='Nome em Inglês'
                        name='name_en'
                        value={this.state.name_en}
                        onChange={this.handelInput}
                        required
                        error={this.state.name_en.length < 3}
                    />
                    <div className='task-icon-picker'>
                            <div className='task-icon' onClick={() => this.setState({ openIcons: true })} tabIndex={1} onKeyPress={e => { if (e.key === 'Enter') this.setState({ openIcons: true }) }}>
                                {this.state.icon ? (
                                    <img src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${this.state.icon.icon}`} alt={this.state.icon.icon_name} height='100%' width='100%' />
                                ) : <CloudUpload fontSize='large' />}
                            </div>
                            {!this.state.icon ?
                                <p>Clique para escolher um icon para a tarefa</p> : <p>{this.state.icon.icon}</p>
                            }
                        </div>
                </div>
                <IconPicker open={this.state.openIcons} close={() => this.setState({ openIcons: false })} pickIcon={(icon) => this.handleIconInput(icon)} />
            </div>
        )
    }
}


