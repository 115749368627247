import React, { useEffect, useState } from 'react'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab'
import sortArray from 'sort-array'
import moment from 'moment'
import ViewMoreContributions from '../../Dialogs/Contributions/ViewMoreContributions'
import { Avatar } from '@mui/material'

function ContributionsTimeLine(props) {
    const [data, setData] = useState([])
    const [openViewMore, setOpenViewMore] = useState(false)
    const [selectedCont, setSelectedCont] = useState(null)

    useEffect(() => {
        const lastMonth = props.data.filter(elem => moment(elem.createdAt).isSameOrAfter(moment().subtract(1, 'month'), 'day'))

        const sortedArr = sortArray(lastMonth, { by: 'createdAt', order: 'desc' })
        setData(sortedArr)

    }, [props.data])

    const handleSelectedCont = (cont) => {
        setSelectedCont(cont)
        setOpenViewMore(true)
    }

    return (
        <>
            <p style={{ textAlign: 'center', letterSpacing: '2px', color: 'var(--dark-beige)' }}>Contribuições nos últimos 30 dias</p>
            <Timeline position='alternate' sx={{ padding: '6px 5px' }}>
                {data.map(cont => {
                    return (
                        <TimeLineItem key={cont.id} contribution={cont} setClickedCont={handleSelectedCont} />
                    )
                })}
            </Timeline>
            {selectedCont &&
                <ViewMoreContributions open={openViewMore} close={() => setOpenViewMore(false)} data={selectedCont} partner={selectedCont.partner} />
            }
        </>
    )
}

export default ContributionsTimeLine

const TimeLineItem = ({ contribution, setClickedCont }) => {
    return (
        <>
            <TimelineItem onClick={() => setClickedCont(contribution)} sx={{ cursor: 'pointer', borderRadius:'20px', '&:hover': { boxShadow: '1px 1px 4px 0px rgba(0,0,0,0.5)'} }}>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {moment(contribution.createdAt).format('DD-MMM-YYYY HH:mm')}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <Avatar alt={contribution.partner.name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${contribution.partner.logo}`} />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '25px', px: 2 }}>
                    <p style={{ fontSize: '14px' }}>{contribution.partner.name}</p>
                    <p style={{ fontSize: '14px', color: 'var(--medium-beige)' }}>{`${contribution.quantity} ${contribution.task.title_pt}`}</p>
                    <p style={{ fontSize: '14px', color: 'var(--medium-beige)' }}>{contribution.task.project? contribution.task.project.title : 'Sem projeto associado'}</p>
                </TimelineContent>
            </TimelineItem>
        </>
    )
}