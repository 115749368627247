import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'
import BigSwitch from '../../../Switches/TableSwitch'
import { CustomAutoComplete } from '../../../Inputs/CustomAutoComplete'
import { Divider, FormControlLabel } from '@mui/material';
import LanguageChanger from '../../../Display/LanguageChanger'
import { projectsTooltips } from '../../../../TooltipHints';
import CustomTooltip from '../../../Display/CustomTooltip';
import TextArea from '../../../Inputs/TextArea';

import '../Info.css'

const locationData = [
    { id: 1, name: "Celorico da Beira" },
    { id: 2, name: "Guarda" },
    { id: 3, name: "Fornos de Algodres" },
    { id: 4, name: "Oliveira do hospital" },
    { id: 5, name: "Seia" },
    { id: 6, name: "Gouveia" },
    { id: 7, name: "Manteigas" },
    { id: 8, name: "Covilhã" },
    { id: 9, name: "Fundão" }
]

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            titleIsValid: false,
            vr_linkIsValid: true,
            formIsValid: false,
            formErrors: { title: '', vr_link: '' },
            selectedLanguage: 'pt'
        }
    }

    componentDidMount() {
        if (this.state.infoComplete) {
            this.setState({
                titleIsValid: true,
                vr_linkIsValid: true,
                formErrors: { title: '', vr_link: '' }
            })
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    handleCheckboxInput = event => {
        const name = event.target.name;
        const value = event.target.checked;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.validateForm())
    }

    handleAutocompleteInput = data => {
        this.props.updateStateOnParent('location', data)
        this.setState({ location: data }, () => this.validateForm())
    }

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleIsValid = this.state.titleIsValid;
        let vr_linkIsValid = this.state.vr_linkIsValid;

        switch (name) {
            case 'title':
                titleIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s]*$/).test(value) && value.length > 4;
                fieldValidationErrors.title = titleIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um nome válido`}`
                break;
            case 'vr_link':
                vr_linkIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
                fieldValidationErrors.vr_link = vr_linkIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um URL válido`}`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            titleIsValid,
            vr_linkIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.titleIsValid &&
                this.state.vr_linkIsValid &&
                this.state.address.length > 2 &&
                this.state.location &&
                (this.state.split_display ? this.state.space_description_en.length > 10 : true) &&
                (this.state.split_display ? this.state.space_description_pt.length > 10 : true) &&
                this.state.project_description_pt.length > 10 &&
                this.state.project_description_en.length > 10
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    checkLanguageAlert = () => {
        if (this.state.selectedLanguage === 'en') {
            return this.state.space_description_pt.length === 0 || this.state.project_description_pt.length === 0
        }
        return this.state.space_description_en.length === 0 || this.state.project_description_en.length === 0


    }

    render() {
        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' >
                    <p className='project-info-dialog-text-title'>Use este formulário para poder editar toda a informação sobre o projeto.</p>
                    <p className='project-info-dialog-text-subtitle'>Todos os campos são obrigatórios e as descrições têm a versão em Português e Inglês</p>
                </div>
                <div className='project-info-form'>
                    <div className='project-info-left-div'>
                        <TextField
                            size='small'
                            label='Nome do projeto'
                            name='title'
                            value={this.state.title}
                            onChange={this.handelInput}
                            autoFocus
                            required
                            error={!this.state.titleIsValid}
                            helperText={this.state.formErrors.title}
                        />
                        <TextField
                            size='small'
                            label='Localidade'
                            name='address'
                            value={this.state.address}
                            onChange={this.handelInput}
                            required
                            placeholder='Exemplo: Aldeia Verdelhos, Vila de Manteigas'
                            error={this.state.address.length < 3}
                        />
                        <CustomAutoComplete
                            OptionLabel='name'
                            size='small'
                            fullWidth
                            data={locationData}
                            multiple={false}
                            id='location'
                            label='Localização'
                            value={this.state.location}
                            onChange={(id, value) => this.handleAutocompleteInput(value)}
                            error={!this.state.location}
                        />
                        <TextField
                            size='small'
                            label='Link para vista 360'
                            name='vr_link'
                            value={this.state.vr_link}
                            onChange={this.handelInput}
                            placeholder='Exemplo: https://website.com'
                            error={!this.state.vr_linkIsValid}
                            helperText={this.state.formErrors.vr_link}
                        />
                        <div className='info-form-checkbox'>
                            <CustomTooltip title={projectsTooltips.online}>
                                <FormControlLabel
                                    label='Online'
                                    control={<BigSwitch
                                        sx={{ marginRight: '10px' }}
                                        name='online'
                                        value={this.state.online}
                                        checked={this.state.online}
                                        onChange={this.handleCheckboxInput}
                                    />}
                                />
                            </CustomTooltip>
                            <CustomTooltip title={projectsTooltips.home_page}>
                                <FormControlLabel
                                    label='Pagina Inicial'
                                    control={<BigSwitch
                                        sx={{ marginRight: '10px' }}
                                        name='main_page'
                                        value={this.state.main_page}
                                        checked={this.state.main_page}
                                        onChange={this.handleCheckboxInput}
                                    />}
                                />
                            </CustomTooltip>
                            <CustomTooltip title={projectsTooltips.display_type}>
                                <FormControlLabel
                                    label='Modo "Antes e Depois"'
                                    sx={{ marginTop: '15px' }}
                                    control={<BigSwitch
                                        sx={{ marginRight: '10px' }}
                                        name='split_display'
                                        value={this.state.split_display}
                                        checked={this.state.split_display}
                                        onChange={this.handleCheckboxInput}
                                    />}
                                />
                            </CustomTooltip>
                        </div>
                    </div>
                    <Divider orientation='vertical' sx={{ height: 'inherit' }} />
                    <div className='project-info-right-div'>
                        <LanguageChanger onLanguageChange={(lang) => this.setState({ selectedLanguage: lang })} />
                        {this.state.selectedLanguage === 'pt' ? (
                            <>
                                {this.state.split_display &&
                                    <TextArea
                                        size='small'
                                        rows={5}
                                        label='Descrição do espaço'
                                        name='space_description_pt'
                                        value={this.state.space_description_pt}
                                        onChange={this.handelInput}
                                        required
                                        actions
                                        error={this.state.space_description_pt.length < 10}
                                    />
                                }
                                <TextArea
                                    size='small'
                                    rows={this.state.split_display ? 5 : 10}
                                    label='Descrição do projeto'
                                    name='project_description_pt'
                                    value={this.state.project_description_pt}
                                    onChange={this.handelInput}
                                    required
                                    actions
                                    error={this.state.project_description_pt.length < 10}
                                />

                                {this.checkLanguageAlert() && <p className='project-info-dialog-text-subtitle'>Clique na bandeira Inglesa para preencher esta info em Inglês</p>}
                            </>
                        ) : (
                            <>
                                {this.state.split_display &&
                                    <TextArea
                                        size='small'
                                        rows={5}
                                        label='Space description'
                                        name='space_description_en'
                                        value={this.state.space_description_en}
                                        onChange={this.handelInput}
                                        required
                                        actions
                                        error={this.state.space_description_en.length < 10}
                                    />
                                }
                                <TextArea
                                    size='small'
                                    rows={this.state.split_display ? 5 : 10}
                                    label='Project description'
                                    name='project_description_en'
                                    value={this.state.project_description_en}
                                    onChange={this.handelInput}
                                    required
                                    actions
                                    error={this.state.project_description_en.length < 10}
                                />
                                {this.checkLanguageAlert() && <p className='project-info-dialog-text-subtitle'>Clique na bandeira Portuguesa para preencher esta info em Português</p>}
                            </>
                        )}

                    </div>
                </div>
            </div>
        )
    }
}
