export const reducer = (state, action) => {
    switch (action.type) {
        case 'setUserRole':
            return {
                ...state,
                userRole: action.payload
            }
        case 'setUserInfo':
            return {
                ...state,
                userInfo: action.payload
            }
        default: return state
    }
}

export const initialState = {
    userRole: {},
    userInfo: {}
}