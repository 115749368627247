import React from "react";
import { Divider } from "@mui/material";
import { Delete, Restore } from '@mui/icons-material'
import CustomTooltip from "../../Display/CustomTooltip";

import './HeaderComponent.css'

const HeaderComponent = ({ visible, deleteCb, restoreCb }) => {

    return (
        <>
            {visible &&
                <div className="custom-toolbar">
                    <CustomTooltip title='Restaure os itens selecionados'>
                        <div className="custom-toolbar-btn" onClick={restoreCb}>
                            <Restore />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip title='Eliminar os itens selecionados'>
                        <div className="custom-toolbar-btn" onClick={deleteCb}>
                            <Delete />
                        </div>
                    </CustomTooltip>
                    <Divider orientation="vertical" sx={{ height: '25px', backgroundColor: 'white', marginLeft:'5px'}} />
                </div>
            }
        </>
    );

}

export default HeaderComponent