import React, { useEffect, useState } from 'react'
import CustomToggleButton from '../../Buttons/CustomToggleButton'
import { Button } from '@mui/material'
import { FilterAutocomplete } from '../../Inputs/CustomAutoComplete'

import './FilterPanel.css'


function TaskFilter(props) {
    const [state, setState] = useState({
        category: [],
        project: [],
        local_craft: 'all',
        online: 'all',
        hasProject: 'all'
    })

    const [projectsData, setProjectsData] = useState([])
    const [categoryData, setCategoryData] = useState([])

    const filterButtons = [
        {
            label: 'Tarefa Online?',
            onChange: (e) => filterData('online', e),
            value: state.online,
            data: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
        {
            label: 'Artesanato local?',
            onChange: (e) => filterData('local_craft', e),
            value: state.local_craft,
            data: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
        {
            label: 'Projeto Associado?',
            onChange: (e) => filterData('hasProject', e),
            value: state.hasProject,
            data: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
    ]

    const resetFilters = () => {
        setState({
            category: [],
            project: [],
            local_craft: 'all',
            online: 'all',
            hasProject: 'all'
        })
    }

    const filterData = (filter, value) => {
        setState(state => ({
            ...state,
            ...{ [filter]: value }
        }))
    }

    useEffect(() => {
        if (props.data) {
            const filteredData = props.data.filter(elem => {
                return (
                    (state.online !== 'all' ? elem.online === state.online : true) &&
                    (state.local_craft !== 'all' ? elem.local_craft === state.local_craft : true) &&
                    (state.hasProject !== 'all' ? Boolean(elem.project) === state.hasProject : true) &&
                    (state.project.length > 0 ? state.project.some(project => project.id === elem.projectId) : true) &&
                    (state.category.length > 0 ? state.category.some(cat => cat.id === elem.categoryId) : true)
                )
            })
            props.setData(filteredData)
        }
    }, [state])

    useEffect(() => {
        const projects = []
        const categories = []

        if (props.data.length > 0) {
            props.data.forEach(elem => {
                if (elem.project) {
                    if (projects.every(project => project.id !== elem.project.id)) {
                        projects.push(elem.project)
                    }
                }

                if (elem.category) {
                    if (categories.every(cat => cat.id !== elem.category.id)) {
                        categories.push(elem.category)
                    }
                }
            })
        }

        setProjectsData(projects)
        setCategoryData(categories)
    }, [props.data])


    return (
        <div className='filter-main-div'>
            {filterButtons.map(filter => {
                return (
                    <div key={filter.label} className='filter-label-div'>
                        <p>{filter.label}</p>
                        <CustomToggleButton light data={filter.data} value={filter.value} onChange={filter.onChange} />
                    </div>
                )
            })}
            <div className='filter-label-div'>
                <p>Projetos</p>
                <div style={{ width: '250px' }}>
                    <FilterAutocomplete multiple value={state.project} optionLabel='title' data={projectsData} label='' id='project' onChange={(id, value) => filterData('project', value)} />
                </div>
            </div>
            <div className='filter-label-div'>
                <p>Categorias</p>
                <div style={{ width: '250px' }}>
                    <FilterAutocomplete multiple value={state.category} optionLabel='name_pt' data={categoryData} label='' id='category' onChange={(id, value) => filterData('category', value)} />
                </div>
            </div>
            <Button size='small' sx={{ border: '2px solid white', marginTop: '20px', color: 'white', width: '202px' }} onClick={resetFilters}>Limpar Filtros</Button>
        </div>
    )
}

export default TaskFilter