import { FormControlLabel } from '@mui/material';
import React, { Component } from 'react'
import { TextField } from '../../../Inputs/TextField'
import BigSwitch from '../../../Switches/TableSwitch'

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            formIsValid: false,
        }
    }

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value });
    }


    handleCheckboxInput = event => {
        const name = event.target.name;
        const value = event.target.checked;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value })
    }

    render() {
        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' style={{ flexDirection: 'row', marginBottom: 0 }} >
                    <p className='contributions-info-dialog-text-title'>Editar email:</p>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        size='small'
                        label='Email'
                        name='email'
                        autoFocus={true}
                        value={this.state.email}
                        onChange={this.handelInput}
                        required
                        error={!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(this.state.email)}
                    />
                    <FormControlLabel
                        label='Subscrever?'
                        control={<BigSwitch
                            sx={{ marginRight: '10px' }}
                            name='subscribed'
                            value={this.state.subscribed}
                            checked={this.state.subscribed}
                            onChange={this.handleCheckboxInput}
                        />}
                    />
                </div>
            </div>
        )
    }
}
