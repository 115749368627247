import React, { useEffect, useState } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import { HelpOutline as Help, Delete, CloudUpload as Upload } from '@mui/icons-material'
import CustomTooltip from '../../../Display/CustomTooltip'
import { partnersTooltips } from '../../../../TooltipHints'
import { findImageGCD } from '../../../../utils'


const StyledButton = styled(Button)({
    color: 'white !important',
    backgroundColor: 'var(--dark-blue) !important',
    borderColor: 'white !important',
    margin: '20px 0 !important',
    '&:hover': {
        backgroundColor: 'white',
        color: 'var(--dark-blue)'
    }
})


function Images(props) {
    const [openImageUploader, setOpenImageUploader] = useState(false)
    const [imagePreview, setImagePreview] = useState([])

    useEffect(() => {
        if (props.state.logo?.length > 0) changeImage(props.state.logo, true)
    }, [])

    const changeImage = async (file, skipUpdateParent) => {
        const id = crypto.randomUUID()

        if (typeof file === 'string') {

            const image = new Image();

            //create a blob and file from the fetched img
            const fetchedImage = await fetch(`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${file}`)
            const blob = await fetchedImage.blob()
            const blobFile = new File([blob], file, { type: blob.type })

            image.onload = () => {
                blobFile.width = image.width;
                blobFile.height = image.height;

                const GCD = findImageGCD(image.width, image.height);
                blobFile.aspectRatio = `${image.width / GCD}:${image.height / GCD}`

                setImagePreview([{ id, picture: `${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${file}`, info: blobFile }])

                props.updateStateOnParent('logo', [{ id, picture: `${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${file}`, info: blobFile }], true)
            }

            image.src = `${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${file}`

        } else {

            if (skipUpdateParent) {
                setImagePreview(file);
                return;
            }

            const picture = URL.createObjectURL(new Blob(file, { type: file[0].type }))
            const image = new Image();

            image.onload = () => {
                file[0].width = image.width;
                file[0].height = image.height;
                const GCD = findImageGCD(image.width, image.height);
                file[0].aspectRatio = `${image.width / GCD}:${image.height / GCD}`

                setImagePreview([{ id, picture, info: file[0] }])
            }

            image.src = picture;

            props.updateStateOnParent('logo', [{ id, picture, info: file[0] }])
        }
    }

    const removeBackgroundImage = () => {
        setImagePreview([])

        props.updateStateOnParent('logo', [])
    }

    return (
        <div className='add-dialog-images-upload-main-div'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title' style={{ textAlign: 'center' }}>Faça o upload do logo, este passo não é obrigatório, pode deixar em branco se não tiver logo</p>
            </div>
            <div className='images-upload-divs'>
                <div className='main-image-upload-div' style={{ width: '50%' }}>
                    <div className='image-upload-section-title'>
                        <p className='project-info-dialog-text-subtitle'>Logo</p>
                        <CustomTooltip title={partnersTooltips.logo}>
                            <Help sx={{ marginLeft: '10px' }} fontSize='small' />
                        </CustomTooltip>
                    </div>
                    <div className='main-image-inner-div'>
                        {imagePreview.length === 0 ? (
                            <StyledButton sx={{ alignSelf: 'flex-start' }} onClick={() => setOpenImageUploader(true)} variant='outlined' startIcon={<Upload />}>Carregar Imagem</StyledButton>
                        ) : (
                            <div className='project-upload-background-image-preview' style={{ backgroundImage: `url(${imagePreview[0].picture})`, height: '200px', width: '200px', marginTop: '20px' }}>
                                <div className='image-action-div'>
                                    <CustomTooltip title='Remover imagem'>
                                        <IconButton sx={{ color: 'black' }} onClick={removeBackgroundImage}>
                                            <Delete htmlColor='white' />
                                        </IconButton>
                                    </CustomTooltip>
                                </div>
                                {imagePreview[0].info &&
                                    <div className='image-info-div'>
                                        <p>{`${imagePreview[0].info.name}`}</p>
                                        <p>{`${imagePreview[0].info.size} Bytes`}</p>
                                        <p>{`${imagePreview[0].info.width} X ${imagePreview[0].info.height} | ${imagePreview[0].info.aspectRatio}`}</p>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ImageUploader open={openImageUploader} close={() => setOpenImageUploader(false)} changeImage={changeImage} />
        </div>
    )
}

export default Images

const ImageUploader = props => {
    return (
        <DropzoneDialog
            previewGridClasses={{ container: 'dropzone-preview-container', item: 'dropzone-preview-item' }}
            acceptedFiles={['image/*']}
            cancelButtonText='Cancelar'
            submitButtonText='Submeter'
            filesLimit={1}
            maxFileSize={1000000}
            open={props.open}
            onClose={() => props.close()}
            onSave={(file) => { props.changeImage(file); props.close() }}
            showPreviews={false}
            showPreviewsInDropzone={true}
            dialogTitle='Upload Logo'
            maxWidth='xs'
            dropzoneText='Arraste o Logo ou clique aqui para fazer upload'
            getFileLimitExceedMessage={() => 'Numero Máximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
            getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
            getFileRemovedMessage={() => 'Imagem removida com sucesso'}
            getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas até um máximo de 1mb.'}
        />
    )
}