import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { UserProvider } from './Context/user/index'

import Login from './Pages/Login'
import PassReset from './Pages/PassReset'

import { SocketContext, socket } from './Context/socket/socket'

ReactDOM.render(
	<SnackbarProvider maxSnack={6}>
		<UserProvider>
			<SocketContext.Provider value={socket}>
				<BrowserRouter>
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='/pass-reset' element={<PassReset />} />
						<Route path='/*' element={<App />} />
						<Route path='*' element={<Navigate to='/login' />} />
					</Routes>
				</BrowserRouter>
			</SocketContext.Provider>
		</UserProvider>
	</SnackbarProvider>,
	document.getElementById('root')
);


