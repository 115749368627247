import React, { useEffect, useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import { Paper } from '@mui/material'
import moment from 'moment'
import { openInNewTab } from '../../../utils'
import { getEntity } from '../../../Api/Requests'
import ContributionsCard from '../../Cards/Contributions/ContributionsCard'
import sortArray from 'sort-array'
import PlaceholderImage from '../../../assets/placeholders/logo_placeholder_1.png'

import './ViewMore.css'

function ViewMorePartner(props) {
    const [contributions, setContributions] = useState([])

    useEffect(() => {
        getEntity('partners', props.data.id).then(res => {
            setContributions(sortArray(res.data.contributions, { by: 'createdAt', order: 'desc' }))
        })
    }, [props.data])

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='md'
            title={props.data.name}
        >
            <div className='partner-view-more-main-div'>
                <div className='partner-view-more-left-div'>
                    <Paper elevation={6} className='partner-view-more-info'>
                        <p className='partner-view-more-paper-title'>Informação</p>
                        <div className='partner-view-more-image'>
                            {props.data.logo ?
                                <img alt='partner' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${props.data.logo}`} height='100%' width='auto' />
                                :
                                <img alt='partner' src={PlaceholderImage} height='100%' width='auto' />
                            }
                        </div>
                        <div className='partner-view-more-info-info'>
                            <p>{props.data.name}</p>
                            <p>{props.data.address}</p>
                            <p>{props.data.contact.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}</p>
                            <p style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => openInNewTab(props.data.website)}>{props.data.website}</p>
                        </div>
                    </Paper>
                    <Paper elevation={6} className='partner-view-more-metadata'>
                        <p className='partner-view-more-paper-title'>Metadata</p>
                        <div className='partner-view-more-metadata-p'>
                            <p>{`ID: ${props.data.id}`}</p>
                            <p>{`Image ID: ${props.data.image_id}`}</p>
                            <p>{`Criado em: ${moment(props.data.createdAt).format('DD MMM YYYY, HH:mm')}`}</p>
                            <p>{`Modificado em: ${moment(props.data.updatedAt).format('DD MMM YYYY, HH:mm')}`}</p>
                        </div>
                    </Paper>
                </div>
                <div className='partner-view-more-right-div'>
                    <Paper elevation={6} className='partner-view-more-contributions'>
                        <p className='partner-view-more-paper-title'>Contribuições</p>
                        {contributions?.map(cont => {
                            return (
                                <ContributionsCard key={cont.id} data={cont} partner={props.data} />
                            )
                        })}
                    </Paper>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default ViewMorePartner