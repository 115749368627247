import React from 'react'
import './Ranking.css'

function ProjectsRanking(props) {
    return (
        <div className='ranking-card-main-div'
            style={
                props.data.background_picture ?
                    { backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${props.data.background_picture})` } :
                    { backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/news/${props.data.image})` }
            }
        >
            <div className='ranking-element'>
                {props.element}
            </div>
            <div className='ranking-values'>
                <div className='ranking-outer-bubble'>
                    <div className='ranking-bubble'>{props.data.views}</div>
                    <p className='ranking-outer-bubble-text'>Visitas</p>
                </div>
                <div className='ranking-outer-bubble'>
                    <div className='ranking-bubble'>{props.data.shares}</div>
                    <p className='ranking-outer-bubble-text'>Partilhas</p>
                </div>
                <div className='ranking-outer-bubble'>
                    <div className='ranking-bubble'>{props.data.shares + props.data.views}</div>
                    <p className='ranking-outer-bubble-text'>Total</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectsRanking