import React, { useEffect, useState } from 'react'
import TablePrefab from './TablePrefab'
import { publishNews } from '../../Api/Requests'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import { newsTooltips } from '../../TooltipHints'
import { BooleanBodyComponent, ActionsBodyComponent } from './TableComponents/BodyComponents'
import DeleteDialog from '../Dialogs/DeleteDialog'
import ViewMoreNews from '../Dialogs/News/ViewMoreNews'
import EditNews from '../Dialogs/News/Edit/EditNews'
import moment from 'moment'

function NewsTable(props) {
    const [data, setData] = useState([])
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openViewMoreDialog, setOpenViewMoreDialog] = useState(false)
    const [selectedNews, setSelectedNews] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    moment.locale('pt')

    useEffect(() => {
        setData(props.data)
    }, [props])

    const getNewsById = id => {
        return props.data.filter(news => news.id === id)[0]
    }

    const publishNew = (news, published) => {
        setSubmitting(true)
        publishNews(news.id).then(res => {
            showSnackbar(`A noticia ${news.title} está agora ${published ? 'publicada' : 'não publicada'}`, '', "success")
            setSubmitting(false)
            props.refresh()
        }, err => {
            console.log(err)
            setSubmitting(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const handleOpenDelete = (props) => {
        setSelectedNews(getNewsById(props.meta.rowData[0]))
        setOpenDeleteDialog(true)
    }

    const handleOpenViewMore = (props) => {
        setSelectedNews(getNewsById(props.meta.rowData[0]))
        setOpenViewMoreDialog(true)
    }

    const handleOpenEdit = (props) => {
        setSelectedNews(getNewsById(props.meta.rowData[0]))
        setOpenEditDialog(true)
    }

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: true } },
        { name: 'title_pt', label: 'Titulo', options: { filter: false, searchable: true } },
        { name: 'location', label: 'Localidade', options: { filter: false, searchable: true } },
        { name: 'views', label: 'Visitas', options: { filter: false, searchable: false, hint: newsTooltips.views, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'shares', label: 'Partilhas', options: { filter: false, searchable: false, hint: newsTooltips.shares, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>{value}</p>, } },
        { name: 'published', label: 'Publicada?', options: { filter: true, searchable: false, hint: newsTooltips.published, customBodyRender: (value, tableMeta, updateValue) => <BooleanBodyComponent value={value} meta={tableMeta} update={updateValue} change={publishNew} /> } },
        { name: 'publish_date', label: 'Publicação', options: { filter: false, searchable: false, hint: newsTooltips.date, customBodyRender: (value, tableMeta, updateValue) => <p>{value ? moment(value).format('DD MMM YYYY HH:mm') : 'Não publicada'}</p> } },
        { name: 'actions', label: 'Ações', options: { print: false, sort: false, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <ActionsBodyComponent value={value} meta={tableMeta} update={updateValue} onEdit={handleOpenEdit} onView={handleOpenViewMore} onDelete={handleOpenDelete} entity='News' /> } },
    ]

    return (
        <>
            <TablePrefab data={data} columns={columns} loading={submitting} title='Noticias' />
            {selectedNews &&
                <>
                    <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Noticia' entityType='news' nameField='title_pt' data={selectedNews} refresh={props.refresh} />
                    <ViewMoreNews open={openViewMoreDialog} close={() => setOpenViewMoreDialog(false)} news={selectedNews} />
                    <EditNews open={openEditDialog} close={() => setOpenEditDialog(false)} data={selectedNews} refresh={props.refresh} triggerSnackbar={triggerSnackbar} themesData={props.themesData} addTheme={props.addTheme} />
                </>
            }
        </>
    )
}

export default NewsTable