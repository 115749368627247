import React, { Component } from 'react'
import { TextField } from '../../../Inputs/TextField'

export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            formIsValid: false,
        }
    }

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' style={{ flexDirection: 'row' }} >
                    <p className='contributions-info-dialog-text-title'>Criar um novo Tema:</p>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column' }}>
                    <TextField
                        size='small'
                        label='Nome em Português'
                        name='name_pt'
                        autoFocus={true}
                        value={this.state.name_pt}
                        onChange={this.handelInput}
                        required
                        error={this.state.name_pt.length < 3}
                    />
                    <TextField
                        size='small'
                        label='Nome em Inglês'
                        name='name_en'
                        value={this.state.name_en}
                        onChange={this.handelInput}
                        required
                        error={this.state.name_en.length < 3}
                    />
                </div>
            </div>
        )
    }
}
