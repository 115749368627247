import React, { useState, useEffect } from 'react'
import DialogPrefab from '../DialogPrefab'
import { useDebounce } from '../../../utils'
import { Grid } from 'react-loading-icons'
import { getEntity } from '../../../Api/Requests'
import SearchInput from '../../Inputs/SearchInput'

import './IconPicker.css'
import CustomTooltip from '../../Display/CustomTooltip'

function IconPicker(props) {
    const [icons, setIcons] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const debounceSearch = useDebounce(search, 500)

    useEffect(() => {
        setLoading(true)
        getEntity('icons').then(res => {
            setIcons(res.data)
            setFilteredData(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setIcons([...filteredData.filter(icon => icon.icon_name.toLowerCase().includes(search.toLowerCase()))])
                return;
            }
            setIcons(filteredData)
        }
    }, [debounceSearch])

    const handlePickIcon = (icon) => {
        props.pickIcon(icon)
        props.close()
    }

    return (
        <DialogPrefab
            fullWidth
            maxWidth='xs'
            open={props.open}
            close={props.close}
            title='Escolher icon'
            margin='0px'
        >
            <div className='icon-picker-main-div'>
                <div className='icon-picker-search'>
                    <SearchInput value={search} onChange={(value) => setSearch(value)} />
                </div>
                <div className='icon-picker-container'>
                    {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> : (
                        icons.map(icon => {
                            return (
                                <div tabIndex={1} key={icon.id} className='icon-button' onClick={() => handlePickIcon(icon)} onKeyPress={e => { if (e.key === 'Enter') handlePickIcon(icon) }}>
                                    <CustomTooltip title={icon.icon_name}>
                                        <img src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${icon.icon}`} alt={icon.icon_name} height='100%' width='100%' />
                                    </CustomTooltip>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        </DialogPrefab>
    )
}

export default IconPicker