import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import { getEntity } from '../Api/Requests'
import { Grid } from 'react-loading-icons'
import { useDebounce, checkIfTaskIsCompleted } from '../utils'
import { TaskCard } from '../Components/Cards/Tasks/TaskCard'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import TaskFilter from '../Components/Panels/FilterPanels/TaskFilter'
import TasksTable from '../Components/Tables/TasksTable'
import AddTask from '../Components/Dialogs/Tasks/Add/AddTask'
import AddCategory from '../Components/Dialogs/Categories/Add/AddCategory'
import AddPartner from '../Components/Dialogs/Partners/Add/AddPartner'
import AddProject from '../Components/Dialogs/Projects/Add/AddProject'
import { getUserSettings, setUserSettings } from '../UserSettings'

import './css/Tasks.css'

function Tasks() {
    const [view, setView] = useState(getUserSettings('tasks').view.view)
    const [search, setSearch] = useState('')
    const [tasks, setTasks] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [immutableTasks, setImmutableTasks] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openAddTask, setOpenAddTask] = useState(false)
    const { showSnackbar } = useGlobalSnackbar();
    const [completedTasks, setCompletedTasks] = useState(0)
    const [projectsData, setProjectsData] = useState([])
    const [categoriesData, setCategoriesData] = useState([])
    const [partnersData, setPartnersData] = useState([])
    const [openAddCategory, setOpenAddCategory] = useState(false)
    const [openAddPartner, setOpenAddPartner] = useState(false)
    const [openAddProject, setOpenAddProject] = useState(false)
    const debounceSearch = useDebounce(search, 500)

    const topPageInfo = [
        { label: 'Total de Tarefas', value: immutableTasks.length },
        { label: 'Tarefas Concluídas', value: completedTasks },
    ]

    useEffect(() => {
        setCompletedTasks([...immutableTasks.filter(task => checkIfTaskIsCompleted(task))].length)
    }, [immutableTasks])

    useEffect(() => {
        setLoading(true)
        getEntity('tasks').then(res => {
            setTasks(res.data)
            setFilteredData(res.data)
            setImmutableTasks(res.data)
            getProjectsAndCategories()
            setLoading(false)
        }, err => {
            setLoading(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            console.log(err)
        })
    }, [refresh])

    const getProjectsAndCategories = () => {
        getEntity('projects').then(res => {
            setProjectsData(res.data)
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))

        getEntity('categories').then(res => {
            setCategoriesData(res.data.filter(cat => !cat.hide))
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))

        getEntity('partners').then(res => {
            setPartnersData(res.data)
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))
    }

    const refreshSingleEntity = (entity) => {
        getEntity(entity).then(res => {
            switch (entity) {
                case 'projects': setProjectsData(res.data);
                    break;
                case 'categories': setCategoriesData(res.data.filter(cat => !cat.hide))
                    break;
                default: setPartnersData(res.data)
            }
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))
    }

    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setTasks([...filteredData.filter(news => {
                    return (
                        news.title_pt.toLowerCase().includes(search.toLowerCase()) ||
                        news.project.title.toLowerCase().includes(search.toLowerCase())
                    )
                })])
                return;
            }
            setTasks(filteredData)
        }
    }, [debounceSearch])

    const updateFilteredData = (data) => {
        setFilteredData(data);
        setTasks(data)
    }

    const triggerAddDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='tasks-main-div'>
            <PageTop
                title='Tarefas'
                info={topPageInfo}
                view={view}
                changeView={(view) => { setView(view); setUserSettings('tasks', 'view', 'view', view) }}
                searchValue={search}
                onChangeSearch={(value) => setSearch(value)}
                filterComponent={<TaskFilter data={immutableTasks} setData={updateFilteredData} />}
                addEntity={() => setOpenAddTask(true)}
            />
            <div className='tasks-container'>
                {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> :
                    tasks.length === 0 ? <p>Sem resultados para apresentar</p> :
                        view === 'cards' ? (
                            tasks.map(task => {
                                return (
                                    <TaskCard
                                        key={task.id}
                                        task={task}
                                        lang='pt'
                                        refresh={() => setRefresh(!refresh)}
                                        projectsData={projectsData}
                                        categoriesData={categoriesData}
                                        partnersData={partnersData}
                                        addCategory={() => setOpenAddCategory(true)}
                                        addProject={() => setOpenAddProject(true)}
                                        addPartner={() => setOpenAddPartner(true)}
                                    />
                                )
                            })
                        ) : (
                            <TasksTable
                                data={tasks}
                                refresh={() => setRefresh(!refresh)}
                                projectsData={projectsData}
                                categoriesData={categoriesData}
                                partnersData={partnersData}
                                addCategory={() => setOpenAddCategory(true)}
                                addProject={() => setOpenAddProject(true)}
                                addPartner={() => setOpenAddPartner(true)}
                            />
                        )}

            </div>
            <AddTask
                open={openAddTask}
                close={() => setOpenAddTask(false)}
                refresh={() => setRefresh(!refresh)}
                triggerSnackbar={triggerAddDialogSnackbar}
                projectsData={projectsData}
                categoriesData={categoriesData}
                addCategory={() => setOpenAddCategory(true)}
                addProject={() => setOpenAddProject(true)}
            />

            <AddProject open={openAddProject} close={() => setOpenAddProject(false)} refresh={() => refreshSingleEntity('projects')} triggerSnackbar={triggerAddDialogSnackbar} />
            <AddCategory open={openAddCategory} close={() => setOpenAddCategory(false)} refresh={() => refreshSingleEntity('categories')} triggerSnackbar={triggerAddDialogSnackbar} />
            <AddPartner open={openAddPartner} close={() => setOpenAddPartner(false)} refresh={() => refreshSingleEntity('partners')} triggerSnackbar={triggerAddDialogSnackbar} />
        </div>
    )
}

export default Tasks
