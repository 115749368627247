import React, { useState, useEffect } from 'react'
import { Drawer, Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { MainListItems, SecondaryListItems, BottomListItems } from './ListItems'

import './SidePanel.css'

const SideDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })({
    '& .MuiDrawer-paper': {
        position: 'fixed',
        whiteSpace: 'nowrap',
        height: '100vh',
        width: '75px',
        top: '0',
        alignItems: 'center',
        overflowX: 'hidden',
        background: 'linear-gradient(0deg, black, var(--dark-blue))',
        boxShadow: '1px 0px 7px -1px rgba(0,0,0,0.4)',
        border: 'none',
        boxSizing: 'border-box'
    }
})


function SidePanel() {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(location.pathname.split('/')[1])

    return (
        <div>
            <SideDrawer className='side-bar' variant='permanent'>
                <MainListItems activeTab={activeTab} setActiveTab={data => setActiveTab(data)} />
                <Divider sx={{ backgroundColor: 'white', width: '80%' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <SecondaryListItems activeTab={activeTab} setActiveTab={data => setActiveTab(data)} />
                    <BottomListItems activeTab={activeTab} setActiveTab={data => setActiveTab(data)} />
                </div>
            </SideDrawer>
        </div>
    )
}

export default SidePanel