const defaultSettings = {
    projects: {
        view: { view: 'cards' },
    },
    news: {
        view: { view: 'cards' },
    },
    tasks: {
        view: { view: 'cards' },
    },
    partners: {
        view: { view: 'cards' },
    },
    lists: {
        view: { view: 'category' },
    },
    trash: {
        view: { view: 'projects' },
    },
}


export const setUserSettings = (entity, param, subParam, value) => {

    if (localStorage.getItem('settings')) {
        const localStorageData = JSON.parse(localStorage.getItem('settings'))
        localStorageData[entity][param][subParam] = value

        localStorage.setItem('settings', JSON.stringify(localStorageData))

    } else {
        const prefObject = defaultSettings;
        prefObject[entity][param][subParam] = value

        localStorage.setItem('settings', JSON.stringify(prefObject))
    }
}

export const getUserSettings = (entity) => {
    if(localStorage.getItem('settings')) {
        const localStorageData = JSON.parse(localStorage.getItem('settings'))
        return localStorageData[entity]
    }

    return defaultSettings[entity]
}

