import React, { useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import moment from 'moment'
import { Paper } from '@mui/material'
import LanguageChanger from '../../Display/LanguageChanger'
import { openInNewTab } from '../../../utils'
import regexifyString from 'regexify-string'

import './ViewMore.css'

function ViewMoreNews(props) {
    const [language, setLanguage] = useState('pt')

    const regexifyPattern = /\[\[(.*?)\]\]/gim;
    const regexifyDecorator = (match, index) => {
        const string = match.substring(2, match.length - 2)
        if (string.includes('@')) {
            return <a key={match} className='text-link' href={`mailto:${string.split('|')[0]}`}>{string.split('|')[1]}</a>
        }
        return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='md'
            title={props.news.title_pt}
        >
            <div className='view-more-news-main-div'>
                <div className='view-more-news-data-div'>
                    <Paper elevation={6} className='view-more-news-data'>
                        <p style={{ position: 'absolute', right: '10px', top: '5px', fontSize: '14px' }}>Info</p>
                        <ViewMoreLabels label='Localização' value={props.news.location} />
                        <ViewMoreLabels label='Tema' value={props.news.news_theme.name_pt} />
                        <ViewMoreLabels label='Publicada?' value={props.news.published ? 'Sim' : 'Não'} />
                        {props.news.published &&
                            <ViewMoreLabels label='Publicação' value={moment(props.news.publish_date).format('DD MMMM YYYY HH:mm')} />
                        }
                        <ViewMoreLabels label='Visitas' value={props.news.views} />
                        <ViewMoreLabels label='Partilhas' value={props.news.shares} />
                    </Paper>
                    <Paper elevation={6} className='view-more-news-meta'>
                        <p style={{ position: 'absolute', right: '10px', top: '5px', fontSize: '14px' }}>Metadata</p>
                        <p>{`ID: ${props.news.id}`}</p>
                        <p>{`Images ID: ${props.news.image_id}`}</p>
                        <p>{`Criado em: ${moment(props.news.createdAt).format('DD MMM YYYY, HH:mm')}`}</p>
                        <p>{`Modificado em: ${moment(props.news.updatedAt).format('DD MMM YYYY, HH:mm')}`}</p>
                    </Paper>
                </div>
                <Paper elevation={6} className='view-more-news-info-div'>
                    <div className='news-info-title' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/news/${props.news.image})` }}>
                        <div className='news-info-title-background' />
                        <p>{language === 'pt' ? props.news.title_pt : props.news.title_en}</p>
                        <LanguageChanger onLanguageChange={setLanguage} />
                    </div>
                    <div className='news-info-text'>
                        <p>{language === 'pt' ?
                            regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: props.news.description_pt }) : 
                            regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: props.news.description_en })
                        }
                        </p>
                    </div>
                </Paper>
            </div>
        </DialogPrefab>
    )
}

export default ViewMoreNews

const ViewMoreLabels = ({ label, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 20px' }}>
            <p style={{ fontSize: '20px', color: 'var(--medium-beige)', letterSpacing: '2px' }}>{label}</p>
            <p style={{ marginLeft: '20px', fontSize: '15px', color: 'var(--dark-blue)' }}>{value}</p>
        </div>
    )
}