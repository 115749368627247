import React, { useState } from 'react'
import DialogPrefab from './DialogPrefab'
import { Button } from '@mui/material'
import { Grid } from 'react-loading-icons'
import { removeEntity } from '../../Api/Requests'
import useGlobalSnackbar from '../../Hooks/useGlobalSnackbar'
import useDialog from './useDialog'

import './DeleteDialog.css'

function DeleteDialog({ open, close, data, entityName, entityType, nameField, refresh, ...props }) {
    const [submitting, setSubmitting] = useState(false)
    const { showSnackbar } = useGlobalSnackbar();
    const { openInfoDialog } = useDialog()

    const infoDialogData = { title: `Eliminar ${entityName}`, message: `Tem a certeza que pretende eliminar ${entityName}: ${data[nameField]} de forma permanente? \n\nNão poderá reverter este processo` };

    const handleOpenInfoDialog = () => {
        openInfoDialog(infoDialogData, 'y/n', () => handleSubmit(true))
    }

    const handleSubmit = (permanent) => {
        setSubmitting(true)

        removeEntity(entityType, data.id, permanent).then(res => {
            setSubmitting(false)
            showSnackbar(res.data, '', 'success')
            refresh()
            close()
        }, err => {
            setSubmitting(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
            console.log(err)
        })
    }

    return (
        <DialogPrefab
            open={open}
            close={close}
            title={`Eliminar ${data[nameField]}`}
        >
            <div className='delete-dialog-main-div'>
                <div className='delete-dialog-text-div'>
                    <p>{`Tem a certeza que pretende eliminar ${entityName}: ${data[nameField]}?`}</p>
                    {!props.onlyPermanent &&
                        <p>{`Pode escolher em enviar ${entityName} para a reciclagem ou pode eliminar permanentemente.
                    Eliminar um item permanentemente irá remover toda a informação e também todas as imagens associadas a esse item, esta ação não pode ser revertida.`}</p>
                    }
                </div>
                <div className='delete-dialog-actions-div'>
                    {!submitting ? (
                        <>
                            {!props.onlyPermanent &&
                                <Button onClick={() => handleSubmit(false)} size='small'>Enviar para a reciclagem</Button>
                            }
                            <Button onClick={handleOpenInfoDialog} size='small' color='error'>Eliminar permanentemente</Button>
                        </>
                    ) : (
                        <Grid fill='var(--dark-beige)' height='50px' width='50px' />
                    )}
                </div>
            </div>
        </DialogPrefab>
    )
}

export default DeleteDialog