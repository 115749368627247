import React, { useState } from 'react'
import moment from 'moment'
import { IconButton } from '@mui/material'
import CustomTooltip from '../../Display/CustomTooltip'
import { Edit, Delete, Visibility, Share, PowerSettingsNew } from '@mui/icons-material'
import useGlobalSnackbar from '../../../Hooks/useGlobalSnackbar'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import DialogPrefab from '../../Dialogs/DialogPrefab'
import { publishNews } from '../../../Api/Requests'
import ViewMoreNews from '../../Dialogs/News/ViewMoreNews'
import EditNews from '../../Dialogs/News/Edit/EditNews'
import { useUserRole } from '../../../Hooks/useUser'

import './NewsCard.css'

function NewsCard(props) {
    const { showSnackbar } = useGlobalSnackbar()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openAccDialog, setOpenAccDialog] = useState(false)
    const [openViewMore, setOpenViewMore] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { userRole } = useUserRole()

    const publishNew = () => {
        publishNews(props.news.id).then(res => {
            showSnackbar(`A noticia ${props.news.title_pt} está agora ${!props.news.published ? 'publicada' : 'não publicada'}`, '', "success")
            props.refresh()
        }, err => {
            console.log(err)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response ? err.response : 'Cannot communicate with the server, please try again later', "error")
        })
    }

    const triggerEditDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }
    //TODO: ao clicar enter na carta abre o dialogo de accessibilidade, mas isto tambem acontece quando temos outro dialog aberto
    return (
        <div className='news-card-main-div'
            tabIndex={1}
            style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/news/${props.news.image})` }}
        // onKeyPress={e => { if (e.key === 'Enter') setOpenAccDialog(true) }}
        >
            {userRole.canEditNews ?
                (
                    <CustomTooltip title={`Clique para tornar esta noticia ${props.news.published ? 'não publicada' : 'publicada'}`}>
                        <div
                            className='news-card-online-div'
                            style={props.news.published ? { color: 'green' } : { color: 'red' }}
                            onClick={() => publishNew()}
                        >
                            {props.news.published ? 'Publicada' : 'Não publicada'}
                        </div>
                    </CustomTooltip>

                ) : (
                    <div
                        className='news-card-online-div'
                        style={props.news.published ? { color: 'green' } : { color: 'red' }}
                    >
                        {props.news.published ? 'Publicada' : 'Não publicada'}
                    </div>
                )}
            <div className='news-card-top-div'>
                {props.news.published &&
                    <div className='news-card-date'>
                        <p>{moment(props.news.published_date).format('DD MMM YYYY')}</p>
                    </div>
                }
                <div className='news-card-stats'>
                    <CustomTooltip title={`${props.news.views} Visitas`}>
                        <div className='stats-div'>
                            <Visibility fontSize='small' htmlColor='white' sx={{ marginRight: '5px' }} />
                            <p>{props.news.views}</p>
                        </div>
                    </CustomTooltip>
                    <CustomTooltip title={`${props.news.shares} Partilhas`}>
                        <div className='stats-div'>
                            <Share fontSize='small' htmlColor='white' />
                            <p>{props.news.shares}</p>
                        </div>
                    </CustomTooltip>
                </div>
            </div>
            <div className='news-card-bottom-div'>
                <div className='news-card-title-div'>
                    <p style={{ fontSize: '14px' }}>{props.news.news_theme.name_pt}</p>
                    <p>{props.news.title_pt}</p>
                </div>
                <div className='news-card-action-div'>
                    <CardActions
                        icon={<Edit htmlColor='white' />}
                        tooltip='Editar Noticia'
                        permission='canEditNews'
                        action={() => setOpenEditDialog(true)}
                    />
                    <CustomTooltip title='Ver main info'>
                        <Visibility htmlColor='white' sx={{ cursor: 'pointer' }} onClick={() => setOpenViewMore(true)} />
                    </CustomTooltip>
                    <CardActions
                        icon={<Delete htmlColor='white' />}
                        tooltip='Eliminar Noticia'
                        permission='canRemoveNews'
                        action={() => setOpenDeleteDialog(true)}
                    />
                </div>
            </div>
            <div className='news-card-black-background' />
            <DeleteDialog open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} entityName='Noticia' entityType='news' nameField='title_pt' data={props.news} refresh={props.refresh} />
            <AccessibleDialog open={openAccDialog} close={() => setOpenAccDialog(false)} news={props.news} openEdit={() => console.log('edit')} openViewMore={() => console.log('view more')} openDelete={() => setOpenDeleteDialog(true)} setOnline={() => publishNew()} />
            <ViewMoreNews open={openViewMore} close={() => setOpenViewMore(false)} news={props.news} />
            <EditNews open={openEditDialog} close={() => setOpenEditDialog(false)} refresh={props.refresh} data={props.news} triggerSnackbar={triggerEditDialogSnackbar} themesData={props.themesData} addTheme={props.addTheme} />
        </div>
    )
}

export default NewsCard

const CardActions = ({ icon, tooltip, permission, action }) => {
    const { userRole } = useUserRole()

    if (!userRole[permission]) return ''

    return (
        <CustomTooltip title={tooltip}>
            <IconButton onClick={action}>
                {icon}
            </IconButton>
        </CustomTooltip>
    )
}

const AccessibleDialog = ({ open, close, openEdit, openViewMore, openDelete, setOnline, news }) => {
    return (
        <DialogPrefab
            open={open}
            close={close}
            title='Ações'
            maxWidth='sm'
        >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                <IconButton onClick={openEdit}>
                    <Edit />
                    <p style={{ marginLeft: '5px', fontSize: '16px' }}>Editar Noticia</p>
                </IconButton>
                <IconButton onClick={openViewMore}>
                    <Visibility />
                    <p style={{ marginLeft: '5px', fontSize: '16px' }}>Mais Info</p>
                </IconButton>
                <IconButton onClick={openDelete}>
                    <Delete />
                    <p style={{ marginLeft: '5px', fontSize: '16px' }}>Eliminar Noticia</p>
                </IconButton>
                <IconButton onClick={setOnline}>
                    <PowerSettingsNew />
                    <p style={{ marginLeft: '5px', fontSize: '16px' }}>{`Tornar ${news.published ? 'não publicada' : 'publicada'}`}</p>
                </IconButton>
            </div>
        </DialogPrefab>
    )
}