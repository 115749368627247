import React, { useEffect, useState } from 'react'

import '../Review.css'

function Review(props) {
    const [changes, setChanges] = useState(false)

    useEffect(() => {
        if (Object.keys(props.state.string).length > 0 ||
            Object.keys(props.state.boolean).length > 0 ||
            Object.keys(props.state.object).length > 0) {
            setChanges(true)
        }
    }, [props.state])

    return (
        <div className='add-dialog-review-container'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>{changes ? 'Foram detetadas as seguintes alterações' : 'Não foram detetadas quaisquer alterações'}</p>
            </div>
            {changes &&
                <div className='add-dialog-review-main-div'>
                     {(Object.keys(props.state.string).length > 0 || Object.keys(props.state.boolean).length > 0) &&
                    <div className='add-dialog-review-info' style={Object.keys(props.state.object).length === 0 ? { width: '100%' } : {}}>
                        {Object.entries(props.state.string).map(([key, value]) => {
                            return (
                                <InfoLabel key={key} label={getCorrespondingLabel(key)} value={typeof value === 'string' ? value : value.name_pt} />
                            )
                        })}
                        {Object.entries(props.state.boolean).map(([key, value]) => {
                            return (
                                <InfoLabel key={key} label={getCorrespondingLabel(key)} value={value ? 'Sim' : 'Não'} />
                            )
                        })}
                    </div>
}
                    {Object.keys(props.state.object).length > 0 &&
                        <div className='add-dialog-review-images'>
                            <p style={{ textAlign: 'center', fontSize: '20px', marginBottom: '15px' }}>Imagem</p>
                            {props.state.object.image &&
                                <ImagesInfo label='' data={props.state.object.image} />
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value }) => {
    return (
        <div className='review-label-div'>
            <p className='review-label-title'>{label}</p>
            <p className='review-label-value'>{value}</p>
        </div>
    )
}

const ImagesInfo = ({ label, data }) => {
    return (
        <div className='review-images-div'>
            <p className='review-images-title'>{label}</p>
            <div className='review-images-container'>
                {data?.map(elem => {
                    return (
                        <div key={elem.id} className='review-image-info' style={{ backgroundImage: `url(${elem.picture})`, width: '100%', height: '150px' }}>
                            <p>{elem.info.name}</p>
                            <p>{`${elem.info.size} bytes`}</p>
                            <p>{elem.info.aspectRatio}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const getCorrespondingLabel = (label) => {
	switch (label) {
		case 'title_pt': return 'Titulo da noticia em Português';
        case 'title_en': return 'Titulo da noticia em Inglês';
		case 'location': return 'Localização'
		case 'news_theme': return 'Tema';
		case 'description_pt': return 'Noticia em Português';
		case 'description_en': return 'Noticia em Inglês';
		case 'published': return 'Publicar noticia?'
		default: return label
	}
} 