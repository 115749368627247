import React, { Component } from 'react'
import axios from 'axios'
import { DialogContent } from '@mui/material'
import DialogPrefab from '../../DialogPrefab'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'

import '../../StepDialog.css'

export default class AddCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name_pt: '',
            name_en: '',
            icon: null,
            infoComplete: false,
            submitting: false,
        }

        this.initialState = {
            name_pt: '',
            name_en: '',
            icon: null,
            infoComplete: false,
            submitting: false,
        }
    }

    resetState = () => {
        this.setState({ ...this.initialState })
    }

    updateState = (property, value) => {
        this.setState({ [property]: value }, () => {
            if (this.state.name_pt.length > 2 && this.state.name_en.length > 2 && this.state.icon) {
                this.setState({ infoComplete: true })
            } else {
                this.setState({ infoComplete: false })
            }
        })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const data = { name_pt: this.state.name_pt, name_en: this.state.name_en, iconId: this.state.icon.id};

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/categories`, data, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }


    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='xs'
                open={this.props.open}
                close={this.props.close}
                title='Adicionar nova Categoria'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-inputs' style={{ height: 'fit-content', overflow: 'visible', margin: '0' }}>
                        <Info state={this.state} updateStateOnParent={this.updateState} />
                    </div>
                    <div className='add-dialog-action' style={{ justifyContent: 'center' }}>
                        <StandardButton disabled={!this.state.infoComplete} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}
