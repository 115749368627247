import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import ProjectsFilter from '../Components/Panels/FilterPanels/ProjectsFilter'
import ProjectCard from '../Components/Cards/Projects/ProjectCard'
import ProjectsTable from '../Components/Tables/ProjectsTable'
import { checkIfProjectIsComplete } from '../utils'
import { getEntity } from '../Api/Requests'
import { useDebounce } from '../utils'
import { Grid } from 'react-loading-icons'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import AddPartner from '../Components/Dialogs/Partners/Add/AddPartner'
import { getUserSettings, setUserSettings } from '../UserSettings'

import './css/Projects.css'
import AddProject from '../Components/Dialogs/Projects/Add/AddProject'

function Projects() {
    const [view, setView] = useState(getUserSettings('projects').view.view)
    const [search, setSearch] = useState('')
    const [projects, setProjects] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [immutableProjects, setImmutableProjects] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [openAddProject, setOpenAddProject] = useState(false)
    const [partnersData, setPartnersData] = useState([])
    const [openAddPartner, setOpenAddPartner] = useState(false)
    const { showSnackbar } = useGlobalSnackbar();
    const [loading, setLoading] = useState(false)
    const debounceSearch = useDebounce(search, 500)

    const topPageInfo = [
        { label: 'Total de Projetos', value: immutableProjects.length },
        { label: 'Projetos Completos', value: immutableProjects.filter(x => checkIfProjectIsComplete(x)).length },
        { label: 'Projetos Offline', value: immutableProjects.filter(x => !x.online).length }
    ]

    useEffect(() => {
        setLoading(true)
        getEntity('projects').then(res => {
            setProjects(res.data)
            setFilteredData(res.data)
            setImmutableProjects(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })

        getEntity('partners').then(res => {
            setPartnersData(res.data)
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))

    }, [refresh])

    const refreshSingleEntity = () => {
        getEntity('partners').then(res => {
            setPartnersData(res.data)
        }, err => showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error"))
    }

    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setProjects([...filteredData.filter(project => project.title.toLowerCase().includes(search.toLowerCase()))])
                return;
            }
            setProjects(filteredData)
        }
    }, [debounceSearch])

    const updateFilteredData = (data) => {
        setFilteredData(data);
        setProjects(data)
    }

    const triggerAddDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='projects-main-div'>
            <PageTop
                title='Projetos'
                info={topPageInfo}
                view={view}
                changeView={(view) => { setView(view); setUserSettings('projects', 'view', 'view', view) }}
                searchValue={search}
                onChangeSearch={(value) => setSearch(value)}
                filterComponent={<ProjectsFilter data={immutableProjects} setData={updateFilteredData} />}
                addEntity={() => setOpenAddProject(true)}
            />
            <div className='projects-container'>
                {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> :
                    projects.length === 0 ? <p>Sem resultados para apresentar</p> :
                        view === 'cards' ? (
                            projects.map(project => {
                                return (
                                    <ProjectCard key={project.title} project={project} refresh={() => setRefresh(!refresh)} partnersData={partnersData} addPartner={() => setOpenAddPartner(true)} />
                                )
                            })
                        ) : (
                            <ProjectsTable data={projects} refresh={() => setRefresh(!refresh)} partnersData={partnersData} addPartner={() => setOpenAddPartner(true)} />
                        )}
            </div>
            <AddProject open={openAddProject} close={() => setOpenAddProject(false)} refresh={() => setRefresh(!refresh)} triggerSnackbar={triggerAddDialogSnackbar} />
            <AddPartner open={openAddPartner} close={() => setOpenAddPartner(false)} refresh={() => refreshSingleEntity()} triggerSnackbar={triggerAddDialogSnackbar} />
        </div>
    )
}

export default Projects