import React, { useEffect, useState } from 'react'
import ContributionsTimeLine from '../Components/Display/Contributions/ContributionsTimeLine'
import DashboardCard from '../Components/Cards/Dashboard/DashboardCard'
import { Divider } from '@mui/material'
import { getEntity } from '../Api/Requests'
import ProjectsRanking from '../Components/Display/Dashboard/ProjectsRanking'
import PartnersRanking from '../Components/Display/Dashboard/PartnersRanking'
import { Grid } from 'react-loading-icons'

import './css/Dashboard.css'

function Dashboard() {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getEntity('dashboard/stats').then(res => {
            setData(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    return (
        <div className='dashboard-main-div'>
            <div className='dashboard-top-div'>
                <DashboardCard title='Projetos' text={data?.countProjects} />
                <Divider orientation='vertical' sx={{ backgroundColor: 'rgb(255,255,255,50%)', height: '65%' }} />
                <DashboardCard title='Noticias' text={data?.countNews} />
                <Divider orientation='vertical' sx={{ backgroundColor: 'rgb(255,255,255,50%)', height: '65%' }} />
                <DashboardCard title='Tarefas' text={data?.countTasks} />
                <Divider orientation='vertical' sx={{ backgroundColor: 'rgb(255,255,255,50%)', height: '65%' }} />
                <DashboardCard title='Parceiros' text={data?.countPartners} />
                <Divider orientation='vertical' sx={{ backgroundColor: 'rgb(255,255,255,50%)', height: '65%' }} />
                <DashboardCard title='Contribuições' text={data?.contributions.length} />
            </div>
            {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' style={{ margin: '150px' }} /> :
                data &&
                <div className='dashboard-bottom-div'>
                    <div className='dashboard-contributions-timeline'>
                        <ContributionsTimeLine data={data.contributions} />
                    </div>
                    <div className='dashboard-rankings-div'>
                        <div className='dashboard-ranking-project'>
                            <p className='ranking-title'>Projetos mais vistos e partilhados</p>
                            <div className='dashboard-inner-ranking'>
                                {data.ranking.projects.length === 0 ?
                                    <p>Sem projetos para apresentar</p>
                                    :
                                    data.ranking.projects.map(project => {
                                        return (
                                            <ProjectsRanking key={project.id} data={project} element={<ProjectDisplay project={project} />} />
                                        )
                                    })}
                            </div>
                        </div>
                        <Divider orientation='horizontal' sx={{ backgroundColor: 'rgb(255,255,255,50%)', width: '100%' }} />
                        <div className='dashboard-ranking-news'>
                            <p className='ranking-title'>Noticias mais vistas e partilhadas</p>
                            <div className='dashboard-inner-ranking'>
                                {data.ranking.news.length === 0 ?
                                    <p>Sem noticias para apresentar</p>
                                    :
                                    data.ranking.news.map(news => {
                                        return (
                                            <ProjectsRanking key={news.id} data={news} element={<NewsDisplay news={news} />} />
                                        )
                                    })}
                            </div>
                        </div>
                        <Divider orientation='horizontal' sx={{ backgroundColor: 'rgb(255,255,255,50%)', width: '100%' }} />
                        <div className='dashboard-ranking-partner'>
                            <p className='ranking-title'>Parceiros com mais contribuições</p>
                            <div className='dashboard-inner-ranking'>
                                {data.ranking.partners.length === 0 ?
                                    <p>Sem Parceiros para apresentar</p>
                                    :
                                    data.ranking.partners.map(partner => {
                                        return (
                                            <PartnersRanking key={partner.id} data={partner} element={<PartnerDisplay partner={partner} />} />
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Dashboard


const ProjectDisplay = (props) => {
    return (
        <div className='project-ranking-display' >
            <p>{props.project.title}</p>
            <p style={{ fontSize: '16px' }}>{props.project.address}</p>
        </div>
    )
}

const NewsDisplay = (props) => {
    return (
        <div className='project-ranking-display' >
            <p>{props.news.title_pt}</p>
        </div>
    )
}

const PartnerDisplay = (props) => {
    return (
        <div className='project-ranking-display' >
            <p>{props.partner.name}</p>
        </div>
    )
}