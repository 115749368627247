import React, { useState } from 'react'
import moment from 'moment'
import { Divider } from '@mui/material'
import CustomTooltip from '../../Display/CustomTooltip'
import { Delete, Edit } from '@mui/icons-material'
import portraitPlaceholder from '../../../assets/placeholders/portrait_placeholder.png'
import Logo from '../../../assets/logos/simple_logo.png'
import DeleteUser from '../../Dialogs/Users/DeleteUser'
import OtherUserProfile from '../../Dialogs/Users/Profile/OtherUserProfile'
import { useUserInfo, useUserRole } from '../../../Hooks/useUser'

import './UserCard.css'

function UserCard(props) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const { userInfo } = useUserInfo()
    const { userRole } = useUserRole()

    return (
        <div className='users-card-main-container'>
            <div className='logged-in-div' style={props.user.loggedIn ? { backgroundColor: 'var(--light-green)' } : { backgroundColor: 'var(--light-red)' }} />
            <div className='userType-wrapper-div'>
                <div className='userType-div-user-card'>{props.user.user_role.name}</div>
            </div>
            <div className='users-card-top-div'><img className='user-card-logo' alt='leader insights' src={Logo} width='70%' height='auto' /></div>
            <div className='users-card-picture-div'>
                {props.user.picture && props.user.picture.length > 0 ? (
                    <img alt='user portrait' style={{ objectFit: 'cover' }} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${props.user.picture}`} width='100%' height='100%' />
                ) : (
                    <img alt='user portrait' src={portraitPlaceholder} width='100%' height='100%' />
                )}
            </div>
            <div className='users-card-bottom-div'>
                <p className='users-name'>{props.user.name}</p>
                <p className='users-job'>{props.user.email}</p>
                {moment(props.user.last_login).isValid() ?
                    <p className='users-last-login'>{`Last login: ${moment(props.user.last_login).format('YYYY-MM-DD H:mm:ss')}`}</p>
                    :
                    <p className='users-last-login'>Ultimo login: Nunca</p>
                }
                <Divider orientation='horizontal' sx={{ width: '80%' }} />
                <div className='users-action-area'>
                    {userInfo.id !== props.user.id &&
                        <CardActions
                            icon={<Edit htmlColor='#333f55' onClick={() => setOpenEdit(true)} />}
                            tooltip='Editar User'
                            permission='canEditUser'
                        />
                    }
                    <CardActions
                        icon={<Delete htmlColor='#333f55' onClick={() => setDeleteDialog(true)} />}
                        tooltip='Eliminar User'
                        permission='canRemoveUser'
                    />
                </div>
            </div>
            <DeleteUser open={deleteDialog} close={() => setDeleteDialog(false)} user={props.user} refresh={props.refresh} />
            <OtherUserProfile open={openEdit} close={() => setOpenEdit(false)} user={props.user} refresh={props.refresh} />
        </div>
    )
}

export default UserCard

const CardActions = ({ icon, tooltip, permission }) => {
    const { userRole } = useUserRole()

    if (!userRole[permission]) return ''

    return (
        <CustomTooltip title={tooltip}>
            {icon}
        </CustomTooltip>
    )
}