import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const generateArrayOfColors = (quantity) => {
    const colorArray = ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)']

    if (quantity > colorArray.length) {
        for (let i = 0; i <= quantity - colorArray.length; i++) {
            const o = Math.round, r = Math.random, s = 255;
            colorArray.push('rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 0.5 + ')')
        }
    }

    return colorArray
}

function PartnersByTask(props) {

    const data = {
        labels: props.data.map(x => x.name_pt),
        datasets: [
            {
                data: props.data.map(x => x.tasks.length),
                backgroundColor: generateArrayOfColors(props.data.length),
                borderWidth: 1,
            },
        ],
    }

    return (
        <Doughnut
            data={data}
            width={250}
            height={250}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                    },
                    title: {
                        display: true,
                        position: 'top',
                        text: ['Categorias de tarefas'],
                        padding: 10
                    },
                },
            }}
        />
    )
}

export default PartnersByTask