import React, { useState } from 'react'
import moment from 'moment'
import ViewMoreContributions from '../../Dialogs/Contributions/ViewMoreContributions'

import './Contributions.css'

function ContributionsCard(props) {
    const [openDialog, setOpenDialog] = useState(false)
    
    return (
        <>
            <div className='contribution-card-main-div' onClick={() => setOpenDialog(true)}>
                <p className='contribution-card-date'>{moment(props.data.createdAt).format('DD-MMM-YYYY')}:</p>
                <p className='contribution-card-info'>{`${props.data.quantity} ${props.data.task.title_pt}`}</p>
                <p className='contribution-card-info'>{props.data.task.project ? props.data.task.project.title : 'Sem projeto associado'}</p>
            </div>
            <ViewMoreContributions open={openDialog} close={() => setOpenDialog(false)} data={props.data} partner={props.partner} />
        </>
    )
}

export default ContributionsCard