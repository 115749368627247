import React, { Component } from 'react'
import axios from 'axios'
import { DialogContent } from '@mui/material'
import DialogPrefab from '../../DialogPrefab'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'

import '../../StepDialog.css'

export default class EditCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name_pt: props.data.name_pt,
            name_en: props.data.name_en,
            icon: props.data.icon,
            infoComplete: false,
            submitting: false,
        }
        this.changedState = {}
    }

    resetState = () => {
        this.setState({ ...this.state })
        this.changedState = {}
    }

    addToChangedState = (property, value) => {
        if (property === 'infoComplete') return;

        if (value !== this.props.data[property]) {
            this.changedState[property] = value;
        } else {
            delete this.changedState[property]
        }

        this.setState({ infoComplete: Object.keys(this.changedState).length > 0 && this.state.name_pt.length > 2 && this.state.name_en.length > 2 })
    }

    updateState = (property, value, ignoreToChangedState) => {
        this.setState({ [property]: value }, () => {
            if (!ignoreToChangedState) this.addToChangedState(property, value)
        })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const data = {};

        Object.entries(this.changedState).forEach(([key, value]) => {
            if (key === 'icon') {
                data.iconId = value.id;
                return
            }
            data[key] = value
        })

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/categories/${this.props.data.id}`, data, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            this.setState({ submitting: false })
            this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }

    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='xs'
                open={this.props.open}
                close={this.props.close}
                title='Editar categoria'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-inputs' style={{ height: 'fit-content', overflow: 'visible', margin: '0' }}>
                        <Info state={this.state} updateStateOnParent={this.updateState} />
                    </div>
                    <div className='add-dialog-action' style={{ justifyContent: 'center' }}>
                        <StandardButton disabled={!this.state.infoComplete} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}