import React, { useEffect } from 'react'
import MUIDatatables from 'mui-datatables'
import { styled } from '@mui/material/styles'
import sortArray from 'sort-array'

const MUIDatatablesStyled = styled(MUIDatatables)({
    borderRadius: '20px',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to right, var(--dark-blue), black)',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--dark-blue)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        }
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--dark-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--dark-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--dark-blue)',
        borderLeft: '5px solid var(--light-beige)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        cursor: 'pointer',
        borderLeft: '5px solid transparent'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'white',
    },
    '& .MuiTableCell-head': {
        padding: '0 16px'
    },
    '& .MuiTableCell-body': {
        padding: '0 16px'
    }
})

function TasksStatsTable(props) {

    useEffect(() => {
        sortArray(props.data, { by: 'current', order: 'desc' })
    }, [props.data])

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: false, display: false } },
        { name: 'title_pt', label: 'Titulo', options: { filter: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ maxHeight: '20px', maxWidth: '350px', overflow: 'auto' }}>{value}</p> } },
        { name: 'milestone', label: 'Objetivo', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center' }}>{value}</p>, } },
        { name: 'current', label: 'Contribuído', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center' }}>{value}</p>, } },
    ]

    const options = {
        filter: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: 'standard',
        rowsPerPageOptions: [10],
        tableBodyMaxHeight: '350px',

        onRowClick: item => props.setActiveEntity(item[0]),
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    };

    return (
        <>
            <MUIDatatablesStyled
                data={props.data}
                options={options}
                columns={columns}
                title={props.type}
            />
        </>
    )
}

export default TasksStatsTable