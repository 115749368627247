import React from 'react'
import placeholder from '../../assets/placeholders/portrait_placeholder.png'

function Image({ alt, value, entity, fallbackType, ...props }) {
    return (
        value ? <img alt={alt} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/${entity}/${value}`} {...props} />
            : <img alt={alt} src={placeholder} {...props} />
    )
}

export default Image