import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import { getNewsletter } from '../Api/Requests'
import NewsletterTable from '../Components/Tables/NewsletterTable'
import AddNewsletter from '../Components/Dialogs/Newsletter/Add/AddNewsletter'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'

import './css/Newsletter.css'

function Newsletter() {
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openAddEmail, setOpenAddEmail] = useState(false)
    const [emails, setEmails] = useState([])
    const { showSnackbar } = useGlobalSnackbar()

    const topPageInfo = [
        { label: 'Emails subscritos', value: emails.length },
    ]

    useEffect(() => {
        setLoading(true)
        getNewsletter().then(res => {
            setEmails(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }, [refresh])

    const triggerSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='newsletter-main-div'>
            <PageTop
                title='Newsletter'
                addName='Email'
                info={topPageInfo}
                view={'cards'}
                addEntity={() => setOpenAddEmail(true)}
            />
            <div className='newsletter-table'>
                <NewsletterTable data={emails} loading={loading} refresh={() => setRefresh(!refresh)} />
            </div>
            <AddNewsletter open={openAddEmail} close={() => setOpenAddEmail(false)} refresh={() => setRefresh(!refresh)} triggerSnackbar={triggerSnackbar} />
        </div>
    )
}

export default Newsletter