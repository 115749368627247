import React, { Component } from 'react'
import { TextField, PasswordTextField } from '../../../Inputs/TextField'
import StandardButton from '../../../Buttons/StandardButton'
import { debounce } from '../../../../utils';
import { CustomAutoComplete } from '../../../Inputs/CustomAutoComplete';
import axios from 'axios'

import './EditProfile.css'

export default class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.user.name,
            email: props.user.email,
            userRole: props.user.user_role,
            nameIsValid: true,
            emailIsValid: true,
            formIsValid: false,
            authPassword: '',
            formErrors: { name: '', email: '' },
            userRoleData: props.userRolesData,
            submitting: false,
        }
        this.changedState = {}
    }


    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.handleChangedState(name, value)
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    handleChangedState = (name, value) => {
        if (name === 'authPassword') return;

        if (typeof value === 'object') {
            if (value.id !== this.props.user.user_role.id) {
                this.changedState[name] = value;
            } else {
                delete this.changedState[name];
            }
            return;
        }

        if (value.toString() !== this.props.user[name].toString()) {
            this.changedState[name] = value;
        } else {
            delete this.changedState[name];
        }
    }

    handleAutocompleteInput = (field, data) => {
        this.handleChangedState(field, data)
        this.setState({ [field]: data }, () => this.validateForm())
    }

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let emailIsValid = this.state.emailIsValid;

        switch (name) {
            case 'name':
                nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s]*$/).test(value) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um nome válido`}`
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `${value} não é um email válido`}`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid,
            emailIsValid,
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid && this.state.emailIsValid &&
                this.state.authPassword.length > 4 && Object.entries(this.changedState).length > 0
        })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const data = { authPassword: this.state.authPassword }

        Object.entries(this.changedState).forEach(([key, value]) => {

            if (key === 'userRole') {
                data.userRoleId = value.id
            } else {
                data[key] = value
            }
        })

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${this.props.user.id}`, data, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.setState({ submitting: false, authPassword: '', formIsValid: false })
            this.changedState = {}
            this.props.refresh();
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }

    checkIfOnlyAdmin = () => {
        if (this.props.user.user_role.name !== 'Admin') return false

        const adminRole = this.state.userRoleData.filter(role => role.name === 'Admin')[0]
        return adminRole.users.length === 1
    }

    render() {

        return (
            <div className='edit-profile-main-div'>
                <div className='edit-profile-upper-div'>
                    <TextField
                        size='small'
                        label='Nome'
                        name='name'
                        value={this.state.name}
                        onChange={this.handelInput}
                        autoFocus
                        required
                        error={!this.state.nameIsValid}
                        helperText={this.state.formErrors.name}
                    />
                    <TextField
                        size='small'
                        label='Email'
                        name='email'
                        value={this.state.email}
                        onChange={this.handelInput}
                        required
                        error={!this.state.emailIsValid}
                        helperText={this.state.formErrors.email}
                    />
                    {this.props.activeUser?.user_role.name === 'Admin' &&
                        <CustomAutoComplete
                            OptionLabel='name'
                            size='small'
                            fullWidth
                            sx={{ margin: '10px 0' }}
                            data={this.state.userRoleData}
                            multiple={false}
                            id='userRole'
                            disabled={this.checkIfOnlyAdmin()}
                            label='Permissões'
                            value={this.state.userRole}
                            onChange={(id, value) => this.handleAutocompleteInput('userRole', value)}
                            error={!this.state.userRole}
                            helperText={this.checkIfOnlyAdmin() ? 'Você é o único administrador, não poderá alterar o cargo' : ''}
                        />
                    }
                </div>
                <div className='edit-profile-password'>
                    <PasswordTextField
                        size='small'
                        sx={{ marginBottom: '20px' }}
                        label='Introduza a sua password'
                        name='authPassword'
                        value={this.state.authPassword}
                        onChange={this.handelInput}
                        required
                    />
                    <StandardButton onClick={this.submitEventToServer} disabled={!this.state.formIsValid}>Submeter</StandardButton>
                </div>
            </div>
        )
    }
}
