import React, { useState, useEffect } from 'react'
import PageTop from '../Components/Panels/PageTop'
import { getEntity } from '../Api/Requests'
import { Grid } from 'react-loading-icons'
import { useDebounce } from '../utils'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'
import { PartnerCard } from '../Components/Cards/Partners/PartnerCard'
import PartnersTable from '../Components/Tables/PartnersTable'
import AddPartner from '../Components/Dialogs/Partners/Add/AddPartner'
import { getUserSettings, setUserSettings } from '../UserSettings'

import './css/Tasks.css'

function Partners() {
    const [view, setView] = useState(getUserSettings('partners').view.view)
    const [search, setSearch] = useState('')
    const [partners, setPartners] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [immutablePartners, setImmutablePartners] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openAddPartner, setOpenPartner] = useState(false)
    const { showSnackbar } = useGlobalSnackbar();
    const debounceSearch = useDebounce(search, 500)

    const topPageInfo = [
        { label: 'Total de parceiros', value: immutablePartners.length }
    ]

    useEffect(() => {
        setLoading(true)
        getEntity('partners').then(res => {
            setPartners(res.data)
            setFilteredData(res.data)
            setImmutablePartners(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', err.response, "error")
            console.log(err)
        })
    }, [refresh])


    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setPartners([...filteredData.filter(partner => partner.name.toLowerCase().includes(search.toLowerCase()))])
                return;
            }
            setPartners(filteredData)
        }
    }, [debounceSearch])


    const triggerAddDialogSnackbar = (data) => {
        if (data.err) {
            showSnackbar('Ocorreu um erro ao fazer o seu pedido', data.response, "error")
            return
        }
        showSnackbar(data.response, '', 'success')
    }

    return (
        <div className='tasks-main-div'>
            <PageTop
                title='Parceiros'
                info={topPageInfo}
                view={view}
                changeView={(view) => { setView(view); setUserSettings('partners', 'view', 'view', view) }}
                searchValue={search}
                onChangeSearch={(value) => setSearch(value)}
                addEntity={() => setOpenPartner(true)}
            />
            <div className='tasks-container'>
                {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> :
                    partners.length === 0 ? <p>Sem resultados para apresentar</p> :
                        view === 'cards' ? (
                            partners.map(partner => {
                                return (
                                    <PartnerCard key={partner.id} partner={partner} refresh={() => setRefresh(!refresh)} />
                                )
                            })
                        ) : (
                            <PartnersTable data={partners} refresh={() => setRefresh(!refresh)} />
                        )}

            </div>
            <AddPartner
                open={openAddPartner}
                close={() => setOpenPartner(false)}
                refresh={() => setRefresh(!refresh)}
                triggerSnackbar={triggerAddDialogSnackbar}
            />
        </div>
    )
}

export default Partners
