import React, { useState, useEffect } from 'react'
import SearchInput from '../Inputs/SearchInput'
import { useDebounce } from '../../utils'
import { Grid } from 'react-loading-icons'
import ViewMore from '../Dialogs/Icons/ViewMore'

import './Icons.css'

function Icons(props) {
    const [icons, setIcons] = useState([])
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [selectedIcon, setSelectedIcon] = useState()
    const [openViewMore, setOpenViewMore] = useState(false)
    const debounceSearch = useDebounce(search, 500)

    useEffect(() => {
        setIcons(props.data)
        setFilteredData(props.data)
    }, [props])

    useEffect(() => {
        if (filteredData) {
            if (debounceSearch) {
                setIcons([...filteredData.filter(icon => icon.icon_name.toLowerCase().includes(search.toLowerCase()))])
                return;
            }
            setIcons(filteredData)
        }
    }, [debounceSearch])

    const handleOpenViewMore = (icon) => {
        setSelectedIcon(icon)
        setOpenViewMore(true)
    }

    return (
        <div className='icons-display-main-div'>
            <div className='icons-display-search-div'>
                <SearchInput value={search} onChange={(value) => setSearch(value)} />
                <p>{filteredData.length} Icons</p>
            </div>
            {props.loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> :
                <div className='icons-display-icons-div'>
                    {icons.map(icon => {
                        return (
                            <div key={icon.icon_name} className='icons-div' onClick={() => handleOpenViewMore(icon)}>
                                <img alt={icon.icon_name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${icon.icon}`} width='40px' height='40px' />
                                <p>{icon.icon_name}</p>
                            </div>
                        )
                    })}
                </div>
            }
            {selectedIcon &&
                <ViewMore open={openViewMore} close={() => setOpenViewMore(false)} icon={selectedIcon} refresh={props.refresh} />
            }
        </div>
    )
}

export default Icons