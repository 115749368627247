import React, { useState, useEffect } from 'react'
import { Divider, DialogContent } from '@mui/material'
import UserRolesSettings from './UserRoles/UserRolesSettings'
import { getEntity } from '../../../Api/Requests'
import DialogPrefab from '../DialogPrefab'

import './SettingsDialog.css'


function SettingsDialog(props) {
    const [view, setView] = useState('user-roles')
    const [refresh, setRefresh] = useState(false)
    const [userRoles, setUserRoles] = useState([])
    
    useEffect(() => {
        getEntity('userRoles').then(res => {
            setUserRoles(res.data)
        }, err => {
            console.log(err)
        })
    }, [refresh])

    return (
        <DialogPrefab
            maxWidth='md'
            open={props.open}
            close={props.close}
            title='Preferências'
            margin='0px'
            aria-labelledby="max-width-dialog-title"
        >
            <DialogContent className='settings-dialog-content'>
                <div className='settings-dialog-body'>
                    <div className='settings-body-menu'>
                        <MenuButton view={view} viewValue='user-roles' label='Permissões' cb={() => setView('user-roles')} />
                    </div>
                    <Divider orientation='vertical' sx={{ height: '100%' }} />
                    <div className='settings-body-right-content' >
                        {view === 'user-roles' &&
                            <div className='body-right-roles'>
                                <UserRolesSettings userRoles={userRoles} refresh={() => setRefresh(!refresh)} />
                            </div>
                        }
                    </div>
                </div>
            </DialogContent>
        </DialogPrefab >
    )
}

export default SettingsDialog

const MenuButton = ({ label, cb, view, viewValue }) => {
    return (
        <>
            <div className={`settings-menu-button ${view === viewValue ? 'active' : ''}`} onClick={cb}>
                <p>{label}</p>
            </div>
            <Divider orientation='horizontal' sx={{ width: '100%' }} />
        </>
    )
}