import React, { useState } from 'react'
import DialogPrefab from '../DialogPrefab'
import moment from 'moment'
import { Paper, IconButton, Chip } from '@mui/material'
import LanguageChanger from '../../Display/LanguageChanger'
import { openInNewTab } from '../../../utils'
import { SmallTaskCard } from '../../Cards/Tasks/TaskCard'
import regexifyString from 'regexify-string'

import vrViewIcon from '../../../assets/icons/icon360.svg'

import './ViewMore.css'
import CustomTooltip from '../../Display/CustomTooltip'

function ViewMore(props) {
    const [language, setLanguage] = useState('pt')

    const regexifyPattern = /\[\[(.*?)\]\]/gim;
    const regexifyDecorator = (match, index) => {
        const string = match.substring(2, match.length - 2)
        if (string.includes('@')) {
            return <a key={match} className='text-link' href={`mailto:${string.split('|')[0]}`}>{string.split('|')[1]}</a>
        }
        return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>
    }

    return (
        <DialogPrefab
            open={props.open}
            close={props.close}
            maxWidth='lg'
            title={props.data.title}
        >
            <div className='project-view-more-container'>
                <div className='project-view-more-left-div'>
                    <Paper elevation={0} className='project-view-more-left-tasks'>
                        {props.data.tasks.map(task => {
                            task.project = props.data
                            return (
                                <SmallTaskCard key={task.id} task={task} lang={language} />
                            )
                        })}
                    </Paper>
                    <Paper elevation={6} className='project-view-more-left-metadata'>
                        <p style={{ position: 'absolute', right: '10px', top: '5px' }}>Metadata</p>
                        <p>{`ID: ${props.data.id}`}</p>
                        <p>{`Images ID: ${props.data.image_id}`}</p>
                        <p>{`Criado em: ${moment(props.data.createdAt).format('DD MMM YYYY, HH:mm')}`}</p>
                        <p>{`Modificado em: ${moment(props.data.updatedAt).format('DD MMM YYYY, HH:mm')}`}</p>
                    </Paper>
                </div>
                <div className='project-view-more-right-div'>
                    <Paper elevation={6} className='project-view-more-right-info'>
                        <div className='project-info-title' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${props.data.background_picture})` }}>
                            <div className='project-info-title-background' />
                            <div className='project-view-more-chip'>
                                {props.data.online ?
                                    <Chip color='success' size='small' label='Online' variant='filled' />
                                    :
                                    <Chip color='error' size='small' label='Offline' variant='filled' />
                                }
                            </div>
                            {props.data.vr_link &&
                                <CustomTooltip title={props.data.vr_link}>
                                    <IconButton
                                        style={{ position: 'absolute', bottom: '20px', right: '20px' }}
                                        onClick={() => openInNewTab(props.data.vr_link)}
                                    >
                                        <img alt='vista 360' src={vrViewIcon} height='30px' width='auto' />
                                    </IconButton>
                                </CustomTooltip>
                            }
                            <p>{props.data.title}</p>
                            <LanguageChanger onLanguageChange={setLanguage} />
                        </div>
                        <div className='project-info-text'>
                            <ViewMoreLabels label='Morada' value={props.data.address} />
                            <ViewMoreLabels label='Localização' value={props.data.location.name} />
                            <ViewMoreLabels label='Descrição do Projeto' value={language === 'pt' ?
                                regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: props.data.project_description_pt }) :
                                regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: props.data.project_description_en })
                            } />
                            {props.data.display_type === 'split' &&
                                <ViewMoreLabels label='Descrição do Espaço' value={language === 'pt' ?
                                    regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: props.data.space_description_pt }) :
                                    regexifyString({ pattern: regexifyPattern, decorator: regexifyDecorator, input: props.data.space_description_en })
                                } />
                            }
                        </div>
                    </Paper>
                    <Paper elevation={6} className='project-view-more-right-images'>
                        {props.data.display_type === 'split' &&
                            <>

                                <p>Imagens do Espaço</p>
                                <ImageDisplay images={props.data.before_pictures} />
                            </>
                        }
                        <p>Imagens do Projeto</p>
                        <ImageDisplay images={props.data.after_pictures} />
                    </Paper>
                </div>
            </div>
        </DialogPrefab>
    )
}

export default ViewMore

const ViewMoreLabels = ({ label, value }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 20px' }}>
            <p style={{ fontSize: '20px', color: 'var(--medium-beige)', letterSpacing: '2px' }}>{label}</p>
            <p style={{whiteSpace: 'break-spaces', marginLeft: '20px', fontSize: '15px', color: 'var(--dark-blue)' }}>{value}</p>
        </div>
    )
}

const ImageDisplay = ({ images }) => {

    const imageArray = images.split(',');

    return (
        imageArray.map(image => {
            return (
                <img
                    key={image}
                    alt={image}
                    src={`${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${image}`}
                    width='100%'
                    height='auto'
                    className='project-view-more-image'
                    onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/projects/${image}`)}
                />
            )
        })
    )
}