import React, { useEffect, useState } from 'react'


function Review(props) {
    const [changes, setChanges] = useState(false)

    useEffect(() => {
        if (Object.keys(props.state.string).length > 0 || Object.keys(props.state.object).length > 0) {
            setChanges(true)
        }
    }, [props.state])

    return (
        <div className='add-dialog-review-container'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>{changes ? 'Foram detetadas as seguintes alterações' : 'Não foram detetadas quaisquer alterações'}</p>
            </div>
            {changes &&
                <div className='add-dialog-review-main-div' style={{ height: 'fit-content', minHeight: '200px', flexDirection: 'column', alignItems: 'center' }}>
                    {Object.keys(props.state.string).length > 0 &&
                        <div className='add-dialog-review-info' style={{ marginBottom: '15px', maxHeight: '100%', width: '100%' }}>
                            {Object.entries(props.state.string).map(([key, value]) => {
                                return (
                                    <InfoLabel key={key} label={getCorrespondingLabel(key)} value={value} />
                                )
                            })}
                        </div>
                    }
                    {Object.keys(props.state.object).length > 0 ?
                        props.state.object.logo.length > 0 ?
                            <img alt={props.state.object.logo[0].info.name} src={props.state.object.logo[0].picture} width='200px' height='200px' style={{ objectFit: 'cover' }} />
                            :
                            <p>Logo Removido</p>
                        : ''}
                </div>
            }
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value }) => {
    return (
        <div className='review-label-div'>
            <p className='review-label-title'>{label}</p>
            <p className='review-label-value'>{value}</p>
        </div>
    )
}

const getCorrespondingLabel = (label) => {
    switch (label) {
        case 'name': return 'Nome';
        case 'address': return 'Morada';
        case 'contact': return 'Contacto'
        case 'website': return 'Website';
        default: return label
    }
} 