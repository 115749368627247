import React, { Component } from 'react'
import { PasswordTextField } from '../../../Inputs/TextField'
import StandardButton from '../../../Buttons/StandardButton'
import { debounce } from '../../../../utils';
import axios from 'axios'

import './EditProfile.css'

export default class PasswordChange extends Component {
    constructor(props) {
        super(props)
        this.state = {
            authPassword: '',
            newPassword: '',
            confirmPassword: '',
            newPasswordIsValid: false,
            confirmPasswordIsValid: false,
            formIsValid: false,
            formErrors: { newPassword: '', confirmPassword: '' },
            submitting: false,
        }
    }

    resetState = () => {
        this.setState({
            authPassword: '',
            newPassword: '',
            confirmPassword: '',
            newPasswordIsValid: false,
            confirmPasswordIsValid: false,
            formIsValid: false,
            formErrors: { newPassword: '', confirmPassword: '' },
            submitting: false,
        })
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if(name === 'newPassword') {
            this.setState({ confirmPassword: '', confirmPasswordIsValid: false})
        }

        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }


    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let newPasswordIsValid = this.state.newPasswordIsValid;
        let confirmPasswordIsValid = this.state.confirmPasswordIsValid;

        switch (name) {
            case 'newPassword':
                newPasswordIsValid = new RegExp(/^(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
                fieldValidationErrors.newPassword = newPasswordIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Password inválida, tem que conter 2 Maiúsculas, 2 números e mais de 8 caracteres `}`
                break;
            case 'confirmPassword':
                confirmPasswordIsValid = value === this.state.newPassword;
                fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Passwords não coincidem`}`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            newPasswordIsValid,
            confirmPasswordIsValid,
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.newPasswordIsValid && this.state.confirmPasswordIsValid && this.state.authPassword.length > 4
        })
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        const data = { 
            authPassword: this.state.authPassword,
            newPassword: this.state.newPassword 
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/changepass/${this.props.user.id}`, data, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
            }
        }).then(res => {
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.resetState()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.resetState()
        })
    }

    render() {

        return (
            <div className='edit-profile-main-div'>
                <div className='edit-profile-upper-div'>
                    <PasswordTextField
                        size='small'
                        label='Nova Password'
                        name='newPassword'
                        value={this.state.newPassword}
                        onChange={this.handelInput}
                        autoFocus
                        required
                        error={!this.state.newPasswordIsValid}
                        helperText={this.state.formErrors.newPassword}
                    />
                    <PasswordTextField
                        size='small'
                        label='Confirmar Password'
                        name='confirmPassword'
                        value={this.state.confirmPassword}
                        onChange={this.handelInput}
                        required
                        error={!this.state.confirmPasswordIsValid}
                        helperText={this.state.formErrors.confirmPassword}
                    />
                </div>
                <div className='edit-profile-password'>
                    <PasswordTextField
                        size='small'
                        sx={{ marginBottom: '20px' }}
                        label='Introduza a sua password'
                        name='authPassword'
                        value={this.state.authPassword}
                        onChange={this.handelInput}
                        required
                    />
                    <StandardButton onClick={this.submitEventToServer} disabled={!this.state.formIsValid}>Submeter</StandardButton>
                </div>
            </div>
        )
    }
}
