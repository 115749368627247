import React, { useState } from 'react'
import LanguageChanger from '../../../Display/LanguageChanger'

import '../Review.css'

function Review(props) {
	const [lang, setLang] = useState('pt')

	return (
		<div className='add-dialog-review-container'>
			<div className='project-info-dialog-title' >
				<p className='project-info-dialog-text-title'>Reveja toda a informação antes de submeter</p>
			</div>
			<div className='add-dialog-review-main-div'>
				<div className='add-dialog-review-info'>
					<div style={{ position: 'absolute', top: '10px', right: '10px' }}>
						<LanguageChanger onLanguageChange={setLang} />
					</div>
					<InfoLabel label='Nome do Projeto' value={props.state.title} />
					<InfoLabel label='Localidade' value={props.state.location?.name} />
					<InfoLabel label='Morada' value={props.state.address} />
					{props.state.vr_link.length > 0 && <InfoLabel label='Link Vr' value={props.state.vr_link} />}
					<InfoLabel label='Online?' value={props.state.online ? 'Sim' : 'Não'} />
					<InfoLabel label='Pagina Inicial?' value={props.state.main_page ? 'Sim' : 'Não'} />
					{props.state.split_display && <InfoLabel label='Descrição do Espaço' value={lang === 'pt' ? props.state.space_description_pt : props.state.space_description_en} />}
					<InfoLabel label='Descrição do Projeto' value={lang === 'pt' ? props.state.project_description_pt : props.state.project_description_en} />
				</div>
				<div className='add-dialog-review-images'>
					<p style={{ textAlign: 'center', fontSize: '20px', marginBottom: '15px' }}>Imagens</p>
					<ImagesInfo label='Imagem de fundo' data={props.state.background_picture} />
					{props.state.split_display && <ImagesInfo label='Imagem do Espaço' data={props.state.before_pictures} />}
					<ImagesInfo label='Imagem do Projeto' data={props.state.after_pictures} />
				</div>
			</div>
		</div>
	)
}

export default Review

const InfoLabel = ({ label, value }) => {
	return (
		<div className='review-label-div'>
			<p className='review-label-title'>{label}</p>
			<p className='review-label-value'>{value}</p>
		</div>
	)
}

const ImagesInfo = ({ label, data }) => {
	return (
		<div className='review-images-div'>
			<p className='review-images-title'>{label}</p>
			<div className='review-images-container'>
				{data?.map(elem => {
					return (
						<div key={elem.id} className='review-image-info' style={{ backgroundImage: `url(${elem.picture})` }}>
							<p>{elem.info.name}</p>
							<p>{`${elem.info.size} bytes`}</p>
							<p>{elem.info.aspectRatio}</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}