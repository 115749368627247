import React, { useEffect, useState } from 'react'
import CustomToggleButton from '../../Buttons/CustomToggleButton'
import { Button } from '@mui/material'
import { FilterAutocomplete } from '../../Inputs/CustomAutoComplete'
import { checkIfProjectIsComplete } from '../../../utils'

import './FilterPanel.css'

//TODO: Se mais tarde for necessário adicionar mais locations, convém ter um endpoint e fazer fetch ao server
const autoCompleteData = [
    {
        id: 1,
        name: "Celorico da Beira",
    },
    {
        id: 2,
        name: "Guarda",
    },
    {
        id: 3,
        name: "Fornos de Algodres",
    },
    {
        id: 4,
        name: "Oliveira do hospital",
    },
    {
        id: 5,
        name: "Seia",
    },
    {
        id: 6,
        name: "Gouveia",
    },
    {
        id: 7,
        name: "Manteigas",
    },
    {
        id: 8,
        name: "Covilhã",
    },
    {
        id: 9,
        name: "Fundão",
    }
]

function ProjectsFilter(props) {
    const [state, setState] = useState({
        online: 'all',
        complete: 'all',
        homePage: 'all',
        location: []
    })

    const filterButtons = [
        {
            label: 'Projeto Online?',
            onChange: (e) => filterData('online', e),
            value: state.online,
            data: [
                { label: 'Online', value: true },
                { label: 'Offline', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
        {
            label: 'Projeto Completo?',
            onChange: (e) => filterData('complete', e),
            value: state.complete,
            data: [
                { label: 'Completo', value: true },
                { label: 'Incompleto', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
        {
            label: 'Projeto na HomePage?',
            onChange: (e) => filterData('homePage', e),
            value: state.homePage,
            data: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
                { label: 'Todos', value: 'all' },
            ]
        },
    ]

    const resetFilters = () => {
        setState({
            online: 'all',
            complete: 'all',
            homePage: 'all',
            location: []
        })
    }

    const filterData = (filter, value) => {
        setState(state => ({
            ...state,
            ...{ [filter]: value }
        }))
    }

    useEffect(() => {
        if (props.data) {
            const filteredData = props.data.filter(elem => {
                return (
                    (state.homePage !== 'all' ? elem.main_page === state.homePage : true) &&
                    (state.online !== 'all' ? elem.online === state.online : true) &&
                    (state.location.length > 0 ? state.location.some(location => location.id === elem.locationId) : true) &&
                    (state.complete !== 'all' ? checkIfProjectIsComplete(elem) === state.complete : true)
                )
            })
            props.setData(filteredData)
        }
    }, [state])

    return (
        <div className='filter-main-div'>
            {filterButtons.map(filter => {
                return (
                    <div key={filter.label} className='filter-label-div'>
                        <p>{filter.label}</p>
                        <CustomToggleButton light data={filter.data} value={filter.value} onChange={filter.onChange} />
                    </div>
                )
            })}
            <div className='filter-label-div'>
                <p>Localização</p>
                <div style={{ width: '250px' }}>
                <FilterAutocomplete multiple value={state.location} optionLabel='name' data={autoCompleteData} label='' id='location' onChange={(id, value) => filterData('location', value)} />
                </div>
            </div>
            <Button size='small' sx={{ border: '2px solid white', marginTop: '20px', color: 'white' }} onClick={resetFilters}>Limpar Filtros</Button>
        </div>
    )
}

export default ProjectsFilter
