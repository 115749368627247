import React, { Component } from 'react'
import { debounce } from '../../../../utils';
import { TextField } from '../../../Inputs/TextField'


export default class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.state,
            nameIsValid: true,
            websiteIsValid: true,
            contactIsValid: true,
            formIsValid: true,
            formErrors: { name: '', website: '', contact: '' },
        }
    }

    componentDidMount() {
        if (this.state.infoComplete) {
            this.setState({
                nameIsValid: true,
                websiteIsValid: true,
                contactIsValid: true,
                formErrors: { name: '', website: '', contact: '' },
            })
        }
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateStateOnParent(name, value);
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let websiteIsValid = this.state.websiteIsValid;
        let contactIsValid = this.state.contactIsValid

        switch (name) {
            case 'name':
                nameIsValid = value.length > 1 && value.length <= 40;
                fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `Este campo é obrigatório` : `Nome inválido`}`
                break;
            case 'website':
                websiteIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0
                fieldValidationErrors.website = websiteIsValid ? '' : `URL invalido, url devem começar por http:// ou https://`
                break;
            case 'contact':
                contactIsValid = value.length > 8 || value.length === 0;
                fieldValidationErrors.contact = contactIsValid ? '' : `Número inválido`
                break;
            default: break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid,
            websiteIsValid,
            contactIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.websiteIsValid &&
                this.state.contactIsValid
        }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
    }

    render() {
        return (
            <div className='project-info-main-div'>
                <div className='project-info-dialog-title' >
                    <p className='project-info-dialog-text-title' style={{ textAlign: 'center'}}>Preencha o seguinte formulário para poder criar um novo Parceiro.</p>
                </div>
                <div className='project-info-form' style={{ flexDirection: 'column' }}>
                        < TextField
                            size='small'
                            label='Nome'
                            name='name'
                            value={this.state.name}
                            onChange={this.handelInput}
                            required
                            autoFocus={true}
                            error={!this.state.nameIsValid}
                            helperText={!this.state.nameIsValid ? this.state.formErrors.name : ''}
                        />
                        < TextField
                            size='small'
                            label='Morada'
                            name='address'
                            value={this.state.address}
                            onChange={this.handelInput}
                        />
                        < TextField
                            size='small'
                            label='Contacto'
                            name='contact'
                            type='number'
                            value={this.state.contact}
                            onChange={this.handelInput}
                            required
                            error={!this.state.contactIsValid}
                            helperText={!this.state.contactIsValid ? this.state.formErrors.contact : ''}
                        />
                        < TextField
                            size='small'
                            label='Website'
                            name='website'
                            value={this.state.website}
                            onChange={this.handelInput}
                            required
                            error={!this.state.websiteIsValid}
                            helperText={!this.state.websiteIsValid ? this.state.formErrors.website : ''}
                        />
                </div>
            </div>
        )
    }
}
