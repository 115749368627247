import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../Context/user/index'

export function useUserInfo() {
    const [userState, dispatch] = useContext(UserContext)
    
    function getUserInfo() {
        return userState
    }

    const [info, setInfo] = useState(getUserInfo().userInfo)
    
    useEffect(() => {
        setInfo(userState.userInfo)
    }, [userState.userInfo])


    function setUserInfo(payload) {
        setInfo(payload)
        dispatch({ type: 'setUserInfo', payload });
    }

    return {
        userInfo: info,
        setUserInfo
    }
}

export function useUserRole() {
    const [userState, dispatch] = useContext(UserContext)
    
    function getUserRole() {
        return userState
    }

    const [role, setRole] = useState(getUserRole().userRole)
    
    useEffect(() => {
        setRole(userState.userRole)
    }, [userState.userRole])


    function setUserRole(payload) {
        setRole(payload)
        dispatch({ type: 'setUserRole', payload });
    }

    return {
        userRole: role,
        setUserRole
    }
}