import { useEffect, useState } from 'react'

export function debounce(fn, ms) {
    let timer;
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn(...args)
        }, ms)
    };
}

export const findImageGCD = (w, h) => {
    if (h === 0) return w
    return findImageGCD(h, w % h)
}

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

export function useDebounce(value, delay) { //debounce to controlled components
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}


export const getPartnersFromProject = (project) => {
    const partners = []

    project.tasks.forEach(task => {
        if (task.contributions.length > 0) {
            task.contributions.forEach(contribution => {
                if (partners.some(partner => partner.id === contribution.partner.id)) return
                partners.push(contribution.partner)
            })
        }
    })

    return partners
}

export const getPartnersFromTask = (task) => {
    const partners = []

    if (task.contributions.length > 0) {
        task.contributions.forEach(contribution => {
            if (partners.some(partner => partner.id === contribution.partner.id)) return
            partners.push(contribution.partner)
        })
    }

    return partners
}

export const getPartnerNumberOfContributions = (data) => {

    const contributionsByPartner = {}

        data.forEach(task => {
            if (task.contributions.length > 0) {
                task.contributions.forEach(cont => {
                    contributionsByPartner[cont.partner.name] = { partner: cont.partner, contributions: contributionsByPartner[cont.partner.name] ? contributionsByPartner[cont.partner.name].contributions + 1 : 1 }
                })
            }
        })
    

    return contributionsByPartner
}

export const getProjectCompletePercentage = (project) => {
    const percentageArr = []

    if (project.tasks.length === 0) return 0

    project.tasks.forEach(task => {
        if (task.contributions.length === 0) {
            percentageArr.push(0)
            return;
        }

        const contributions = task.contributions.reduce((prev, current) => {
            return { quantity: prev.quantity + current.quantity }
        })

        const percentage = (contributions.quantity / task.milestone) * 100;

        percentageArr.push(Math.floor(percentage * 100) / 100)
    })

    var percentageSum = percentageArr.reduce((x, y) => x + y);

    return Math.floor(percentageSum / percentageArr.length)
}

export const getProjectNumberOfContributions = project => {
    if (!project) return;

    let count = 0;

    project.tasks.forEach(task => {
        count += task.contributions.length
    })

    return count;
}

export const checkIfProjectIsComplete = (project) => {
    if (project.tasks.length === 0) return false

    return project.tasks.every(task => {
        if (task.contributions.length === 0) return false;

        const contributions = task.contributions.reduce((prev, current) => {
            return { quantity: prev.quantity + current.quantity }
        })

        return contributions.quantity >= task.milestone
    })
}

export const getTaskCompletePercentage = (task) => {
    if (task.contributions.length === 0) return 0;

    let count = 0;

    task.contributions.forEach(x => {
        count += x.quantity
    })

    const percentage = (count / task.milestone) * 100;

    return Math.floor(percentage * 100) / 100
}

export const checkIfTaskIsCompleted = (task) => {
    if (task.contributions.length === 0) return false;

    let count = 0;

    task.contributions.forEach(x => {
        count += x.quantity
    })

    return count >= task.milestone

}