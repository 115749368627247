import React, { Component } from 'react'
import axios from 'axios'
import FormData from 'form-data'
import { DialogContent, Stepper, Step, StepLabel } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { RateReview, Image as ImageIcon, Preview } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import DialogPrefab from '../../DialogPrefab'
import { Grid } from 'react-loading-icons'
import StandardButton from '../../../Buttons/StandardButton'

import Info from './Info'
import Images from './Images'
import Review from './Review'

import '../../StepDialog.css'

const steps = ['Informação', 'Upload de Imagens', 'Rever Informação']

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, black 0%, var(--dark-blue) 50%, black 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, black 0%, var(--dark-blue) 50%, black 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const StyledStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, black 0%, var(--dark-blue) 50%)',
        border: '2px solid white',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, black 0%, var(--dark-blue) 50%)',
    }),
}));

function ColorLibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <RateReview />,
        2: <ImageIcon />,
        3: <Preview />,
    };

    return (
        <StyledStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </StyledStepIconRoot>
    );
}

export default class EditPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: this.props.data.name,
            address: this.props.data.address,
            contact: this.props.data.contact,
            website: this.props.data.website,
            logo: this.props.data.logo,
            infoComplete: true,
            imagesComplete: true,
            submitting: false,
        }
        this.changedState = { string: {}, object: {} }
    }

    resetState = () => {
        this.setState({ ...this.state, activeStep: 0 })
        this.changedState = { string: {}, object: {} }
    }

    checkNextBtn = () => {
        if (this.state.activeStep === 0 && this.state.infoComplete) return true
        if (this.state.activeStep === 1 && this.state.imagesComplete) return true
        return false
    }

    checkSubmitBtn = () => {
        return Object.keys(this.changedState.string).length > 0 || Object.keys(this.changedState.object).length > 0
    }

    handleNextStep = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }

    handlePrevStep = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };

    addToChangedState = (property, value) => {
        if (property === 'infoComplete') return;

        if (value !== this.props.data[property]) {
            this.changedState[typeof value][property] = value;
        } else {
            delete this.changedState[typeof value][property]
        }
    }

    updateState = (property, value, ignoreToChangedState) => {
        this.setState({ [property]: value }, () => {
            if (!ignoreToChangedState) this.addToChangedState(property, value)
        })
    }

    getStepContent = () => {
        switch (this.state.activeStep) {
            case 0: return <Info state={this.state} updateStateOnParent={this.updateState} />
            case 1: return <Images state={this.state} updateStateOnParent={this.updateState} />
            case 2: return <Review state={this.changedState} previousState={this.state} />
            default: return ''
        }
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        let formData = new FormData();

        Object.entries(this.changedState.string).forEach(([key, value]) => {
            formData.set(key, value)
        })


        if (this.changedState.object.logo?.length === 0) {
            formData.set('logo', 'remove logo')
        } else {
            Object.entries(this.changedState.object).forEach(([key, value]) => {
                for (let i = 0; i < value.length; i++) {
                    formData.append(key, value[i].info)
                }
            })
        }

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/partners/${this.props.data.id}`, formData, {
            headers: {
                'key': JSON.parse(sessionStorage.getItem('token'))?.token,
                'accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
        }).then(res => {
            console.log(res)
            this.setState({ submitting: false })
            this.resetState()
            this.props.triggerSnackbar({ err: false, response: res.data })
            this.props.refresh();
            this.props.close()
        }, err => {
            console.log(err)
            this.props.triggerSnackbar({ err: true, response: err.response ? err.response : 'Cannot communicate with the server, please try again later' })
            this.setState({ submitting: false })
        })
    }

    render() {
        return (
            <DialogPrefab
                fullWidth
                maxWidth='sm'
                open={this.props.open}
                close={this.props.close}
                title='Editar Parceiro'
            >
                <DialogContent className='add-dialog-content'>
                    <div className='add-dialog-stepper'>
                        <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<StyledStepConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorLibStepIcon}><p style={{ color: 'var(--dark-beige)', fontSize: '14px', letterSpacing: '2px' }}>{label}</p></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className='add-dialog-inputs' style={{ height: 'fit-content' }}>
                        {this.getStepContent()}
                    </div>
                    <div className='add-dialog-action' style={{ justifyContent: 'center' }}>
                        {this.state.activeStep !== 0 && <StandardButton onClick={this.handlePrevStep} style={{ marginRight: '20px', color: 'var(--dark-beige)' }} >Anterior</StandardButton>}
                        {this.state.activeStep === steps.length - 1 ? (
                            this.state.submitting ? (
                                <Grid fill='var(--dark-beige)' height='50px' width='50' />
                            ) : (
                                <StandardButton disabled={!this.checkSubmitBtn()} onClick={() => this.submitEventToServer()} >Submeter</StandardButton>
                            )
                        ) : (
                            <StandardButton disabled={!this.checkNextBtn()} onClick={this.handleNextStep} >Seguinte</StandardButton>
                        )}
                    </div>
                </DialogContent>

            </DialogPrefab>
        )
    }
}