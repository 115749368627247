import React from 'react'
import { PolarArea } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


const generateArrayOfColors = (quantity) => {
    const colorArray = ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)']

    if (quantity > colorArray.length) {
        for (let i = 0; i <= quantity - colorArray.length; i++) {
            const o = Math.round, r = Math.random, s = 255;
            colorArray.push('rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 0.5 + ')')
        }
    }

    return colorArray
}

function PartnersByTask(props) {
    
    const getData = () => {
        const arrayOfValues = Object.values(props.data)

        return arrayOfValues.map(x => x.contributions)
    }

    const data = {
        labels: Object.keys(props.data),
        datasets: [
            {
                data: getData(),
                backgroundColor: generateArrayOfColors(Object.keys(props.data).length),
                borderWidth: 1,
            },
        ],
    }

    return (
        <PolarArea
            data={data}
            width={250}
            height={250}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        position: 'top',
                        text: ['Numero de contribuições por parceiro'],
                        padding: 10
                    },
                },
            }}
        />
    )
}

export default PartnersByTask