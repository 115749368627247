export const projectsTooltips = {
    online: 'Um projeto online significa que este está visível na plataforma do Lugar, um projeto Offline não será visível na plataforma mas continua a ter toda a sua informação.',
    home_page: 'Um Projeto na pagina inicial significa que irá aparecer no slider da pagina inicial da plataforma Lugar',
    progress: "Progresso representa a percentagem acumulada de todas as tarefas pertencentes a este projeto fazendo uma percentagem total. Por exemplo um projeto com 100% de progresso significa que o objetivo de todas as suas tarefas foi alcançado",
    tasks: "Tarefas representa as ajudas que este projeto necessita",
    contributions: 'As contribuições representam o numero de vezes que alguém contribuiu para este projeto',
    background_image: 'Para a imagem de fundo só poderá ser feito o upload de uma imagem. Esta imagem não pode ultrapassar os 2.5mb de tamanho e é recomendado ter um aspect ratio de 16:9',
    space_images: 'Para as imagens do espaço poderá ser feito o upload até 5 imagens. Estas imagens não podem ultrapassar os 2.5mb de tamanho (cada) e é recomendado ter um aspect ratio de 16:9',
    project_images: 'Para as imagens do projeto poderá ser feito o upload até 5 imagens. Estas imagens não podem ultrapassar os 2.5mb de tamanho (cada) e é recomendado ter um aspect ratio de 16:9',
    display_type: 'O modo "Antes e depois" faz com que o projeto seja apresentado no website com 2 sliders de imagens.\n\n O primeiro slider de imagens apresenta imagens e uma descrição sobre o estado atual do projeto.\nO segundo slider apresenta imagens e uma descrição da visão do projeto.\n\nPodemos pensar neste modo como um "Antes e Depois" modo de visualização.'
}

export const form = {
    link: 'Inserir um link neste campo',
    email: 'Inserir um endereço de email neste campo',
}

export const newsTooltips = {
    published: 'Uma noticia publicada ficará visível no site com a data da publicação, se a noticia não for publicada ficara só disponível no backoffice onde pode ser alterada e mais tarde publicada',
    views: 'O valor das visitas representa o número de vezes que a noticia foi aberta',
    shares: 'O valor de partilhas representa o número de vezes que a noticia foi partilhada através da plataforma',
    date: 'Data em que a noticia foi publicada'
}

export const tasksTooltips = {
    online: 'Uma tarefa online significa que este está visível na plataforma do Lugar, um tarefa Offline não será visível na plataforma mas continua a ter toda a sua informação.',
    progress: "Progresso representa a percentagem acumulada de todas as contribuições para esta tarefa. Por exemplo uma tarefa com 100% de progresso significa que o seu objetivo foi alcançado",
    contributions: 'As contribuições representam o número de vezes que alguém contribuiu para esta tarefa',
    localCraft: 'Este campo indica se esta tarefa esta classificada como artesanato local',
    milestone: 'O valor do objetivo da tarefa',
    current: 'O valor atual dos itens angariados nesta tarefa'
}

export const partnersTooltips = {
    logo: 'A imagem de logo não deve ultrapassar o 1Mb e é recomendado um tamanho máximo de 200x200. Se não fizer o upload do logo, será atribuído um placeholder'
}

export const iconsTooltips = {
    icon: 'O Icon não deve ultrapassar o 100kb e é recomendado um tamanho máximo de 60x60. O icon é obrigatório'
}