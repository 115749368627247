import React, { useState } from 'react'
import { TextField as MuiTextField, IconButton, InputAdornment } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import PropTypes from 'prop-types'

const StyledTextFiled = styled(MuiTextField)({
    margin: '10px 0',
    '& label.Mui-focused': {
        color: 'var(--dark-beige)',
    },
    '& .MuiInputBase-root': {
        '& fieldset': {
            borderWidth: 2,
            borderColor: 'var(--dark-beige)',
        },
        '&:hover fieldset': {
            borderColor: 'var(--dark-beige)',
            borderLeftWidth: 6
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--dark-beige)',
            borderLeftWidth: 6
        },
        '&.Mui-error fieldset': {
            borderLeftWidth: 6,
            borderColor: 'var(--light-red)',
            padding: '4px !important',
        },
    },
})


export const TextField = ({ label, name, value, helperText, error, onChange, type, autoFocus, required, ...props }) => {
    return (
        <StyledTextFiled
            required={required}
            id={name}
            label={label}
            name={name}
            value={value}
            helperText={helperText}
            error={error}
            onChange={onChange}
            autoComplete={name}
            type={type}
            autoFocus={autoFocus}
            fullWidth
            {...props}
        />
    )
}

export const PasswordTextField = ({ label, name, value, helperText, error, onChange, autoFocus, ...props }) => {
    const [showPass, setShowPass] = useState(false)

    return (
        <StyledTextFiled
            required
            id={name}
            label={label}
            name={name}
            fullWidth
            autoFocus={autoFocus}
            helperText={helperText}
            error={error}
            value={value}
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
            onChange={onChange}
            autoComplete='current-password'
            type={showPass ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            tabIndex={-1}
                            aria-label='toggle password visibility'
                            onClick={() => setShowPass(!showPass)}
                        >
                            {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...props}
        />
    )
}

TextField.propTypes = {
    required: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    helperText: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['text', 'email', 'number']),
    autoFocus: PropTypes.bool
}

PasswordTextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool
}

TextField.defaultProps = {
    type: 'text',
    error: false,
    autoFocus: false,
    required: false
}

PasswordTextField.defaultProps = {
    label: 'Password',
    name: 'password',
    error: false,
    autoFocus: false
}
