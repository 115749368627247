import React from 'react'
import './Ranking.css'

function PartnersRanking(props) {
    return (
        <div className='ranking-card-main-div' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${props.data.logo})`}}>
            <div className='ranking-element'>
                {props.element}
            </div>
            <div className='ranking-values'>
                <div className='ranking-outer-bubble'>
                    <div className='ranking-bubble'>{props.data.total_contributions}</div>
                    <p className='ranking-outer-bubble-text'>Contribuições</p>
                </div>
            </div>
        </div>
    )
}

export default PartnersRanking