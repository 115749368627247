import React from 'react'
import buttonBorder from '../../assets/misc/menu_border.svg'
import buttonBorderLight from '../../assets/misc/menu_border_light.svg'
import { Grid } from 'react-loading-icons'

import './StandardButton.css'

function StandardButton({ children, loading, light, ...props }) {

    return (
        <button
            className={`standard-button${loading ? ' loading' : ''}`}
            style={light ? { color: 'white' } : { color: 'var(--dark-beige)' }}
            onClick={loading ? null : props.onClick}
            {...props}
        >
            {loading ? <Grid fill='var(--dark-beige)' height='75%' width='100' /> : <p>{children}</p>}
            <img className='standard-button-top-border' alt='button-border' src={light ? buttonBorderLight : buttonBorder} />
            <img className='standard-button-bottom-border' alt='button-border' src={light ? buttonBorderLight : buttonBorder} />
            {loading && <p className='loading-text'>Aguarde...</p>}
        </button>
    )
}

export default StandardButton