import React from 'react'

function Review(props) {

    return (
        <div className='add-dialog-review-container'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>Reveja toda a informação antes de submeter</p>
            </div>
            <div className='add-dialog-review-main-div' style={{ height: 'fit-content', minHeight: '200px'}}>
                <div className='add-news-info-div' style={{ width: '100%', alignItems:'center' }}>
                    <div className='add-news-review-info' style={{ marginBottom: '15px', maxHeight:'100%' }}>
                        <InfoLabel label='Nome' value={props.state.name} />
                        <InfoLabel label='Morada' value={props.state.address} />
                        <InfoLabel label='Contacto' value={props.state.contact} />
                        <InfoLabel label='Website' value={props.state.website} />
                    </div>
                    {props.state.logo.length > 0 &&
                        <img alt={props.state.name} src={props.state.logo[0].picture} width='200px' height='200px' style={{ objectFit: 'cover' }} />
                    }
                </div>
            </div>
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value }) => {
    return (
        <div className='review-label-div'>
            <p className='review-label-title'>{label}</p>
            <p className='review-label-value'>{value}</p>
        </div>
    )
}