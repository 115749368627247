import React from 'react'

function Review(props) {

    return (
        <div className='add-dialog-review-container'>
            <div className='project-info-dialog-title' >
                <p className='project-info-dialog-text-title'>Reveja toda a informação antes de submeter</p>
            </div>
            <div className='add-dialog-review-main-div'>
                <div className='add-dialog-review-info'>
                    <InfoLabel fullWidth={true} label='Nome da Tarefa em Português' value={props.state.title_pt} />
                    <InfoLabel fullWidth={true} label='Nome da Tarefa em Inglês' value={props.state.title_en} />
                    <InfoLabel label='Objetivo' value={props.state.milestone} />
                    <InfoLabel label='Categoria' value={props.state.category?.name_pt} />
                    <InfoLabel label='Projeto' value={props.state.project?.title} />
                    <InfoLabel label='Online?' value={props.state.online ? 'Sim' : 'Não'} />
                    <InfoLabel label='Artesanato Local?' value={props.state.local_craft ? 'Sim' : 'Não'} />
                </div>
                <div className='add-dialog-review-images'>
                    <p style={{ textAlign: 'center', fontSize: '20px', marginBottom: '15px' }}>Icon</p>
                    <div className='task-icon' style={{ width: '100%'}}>
                        <img src={`${process.env.REACT_APP_SERVER_URL}/resources/images/icons/${props.state.icon.icon}`} alt={props.state.icon.icon_name} height='100%' width='100%' />
                        <p>{props.state.icon.icon_name}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review

const InfoLabel = ({ label, value, fullWidth }) => {
    return (
        <div className='review-label-div' style={fullWidth ? { width: '100%' } : {}}>
            <p className='review-label-title'>{label}</p>
            <p className='review-label-value'>{value}</p>
        </div>
    )
}