import React, { useEffect, useState } from 'react'
import { AvatarGroup, Avatar, Tooltip } from '@mui/material'
import DialogPrefab from '../../Dialogs/DialogPrefab'
import { SmallPartnerCard } from '../../Cards/Partners/PartnerCard'
import ViewMorePartner from '../../Dialogs/Partners/ViewMorePartner'
import { getPartnersFromProject, getPartnersFromTask } from '../../../utils'

function Avatars({ data, from, max, size, color, ...props }) {
    const [openPartnersDialog, setOpenPartnersDialog] = useState(false)
    const [openPartnerDialog, setOpenPartnerDialog] = useState(false)
    const [partners, setPartners] = useState([])
    const [selectedPartner, setSelectedPartner] = useState(null)

    useEffect(() => {
        if (from === 'task') {
            setPartners(getPartnersFromTask(data))
            return;
        }
        setPartners(getPartnersFromProject(data))
    }, [data])

    const onClickPlusAvatar = (e) => {
        if (e.target.outerText.includes('+')) {
            setOpenPartnersDialog(true)
        }
    }

    const handleAvatarClick = (partner) => {
        if (props.clickable) {
            setSelectedPartner(partner)
            setOpenPartnerDialog(true)
        }
    }

    const getSize = () => {
        switch (size) {
            case 'sm': return { width: '30px', height: '30px' }
            case 'md': return { width: '40px', height: '40px' }
            case 'lg': return { width: '50px', height: '50px' }
            default: return { width: '40px', height: '40px' }
        }
    }

    return (
        <>
            <AvatarGroup max={max} sx={{ cursor: 'pointer' }} onClick={onClickPlusAvatar}>
                {partners?.map(partner => {
                    return (
                        <Tooltip key={partner.id} title={partner.name}>
                            <Avatar
                                sx={getSize}
                                style={{ backgroundColor: 'white', borderColor: color }}
                                alt={partner.name}
                                src={`${process.env.REACT_APP_SERVER_URL}/resources/images/partners/${partner.logo}`}
                                onClick={() => handleAvatarClick(partner)}
                            />
                        </Tooltip>
                    )
                })}
            </AvatarGroup>
            {selectedPartner &&
                <ViewMorePartner open={openPartnerDialog} close={() => { setOpenPartnerDialog(false); setSelectedPartner(null) }} data={selectedPartner} />
            }
            <PartnersDialog
                open={openPartnersDialog}
                close={() => setOpenPartnersDialog(false)}
                partners={partners}
                title={from === 'task' ? `Parceiros na tarefa ${data.title_pt}` : `Parceiros do projeto ${data.title}`}
                handleAvatarClick={handleAvatarClick}
            />
        </>
    )
}

export default Avatars


const PartnersDialog = ({ partners, open, close, title, handleAvatarClick }) => {
    return (
        <DialogPrefab
            open={open}
            close={close}
            maxWidth='sm'
            title={title}
        >
            {partners.map(partner => {
                return (
                    <SmallPartnerCard key={partner.id} partner={partner} onClick={() => handleAvatarClick(partner)} />
                )
            })}
        </DialogPrefab>
    )
}