import React from 'react'
import manual from '../assets/manual.pdf'

function Help() {
    return (
        <div className='help-main-page' style={{ width: '100%', height: '100vh', paddingTop: '75px' }}>
            <object data={manual} type="application/pdf" width="100%" height="100%">
                Error loading pdf
            </object>
        </div>
    )
}

export default Help