import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField, PasswordTextField } from '../Components/Inputs/TextField'
import StandardButton from '../Components/Buttons/StandardButton'
import { loginUser } from '../Api/Requests'
import useToken from '../Hooks/useToken'
import ForgotPass from '../Components/Dialogs/ForgotPass/ForgotPass'
import useGlobalSnackbar from '../Hooks/useGlobalSnackbar'

import Logo from '../assets/logos/LUGAR.svg'

import { SocketContext } from '../Context/socket/socket'

import './css/Login.css'


function Login(props) {
    const [submitting, setSubmitting] = useState(false)
    const [openForgotPassDialog, setOpenForgotPassDialog] = useState(false)

    const { showSnackbar } = useGlobalSnackbar()
    const { setToken } = useToken()
    const navigate = useNavigate()

    const socket = useContext(SocketContext)
    
    useEffect(() => {
        sessionStorage.removeItem('token')
        setToken(null)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true)

        const timer = setTimeout(() => {
            loginUser(e.target[0].value, e.target[2].value).then(res => {
                setToken({ token: res.headers.key })
                socket.auth = { uuid: res.headers.id, name: res.headers.username ,token: res.headers.key }
                socket.connect()
                clearTimeout(timer)
                setSubmitting(false)
                navigate('/dashboard', { replace: true })
            }, err => {
                console.log(err)
                showSnackbar(err.response.data ? err.response.data : 'Não foi possível comunicar com o servidor', '', "error", { vertical: 'bottom', horizontal: 'center' }, false)
                setSubmitting(false)
                clearTimeout(timer)
            })
        }, 1000)
    }

    const handleForgotPassResponse = (res, err) => {

        if (!err) {
            showSnackbar(res.data, '', "success", { vertical: 'bottom', horizontal: 'center' }, false)
            setOpenForgotPassDialog(false)
            return;
        }
        showSnackbar(res.response ? res.response.data : 'Não foi possível comunicar com o servidor', '', "error", { vertical: 'bottom', horizontal: 'center' }, false)
    }

    return (
        <div className='login-main-container'>
            <div className='login-right-side-div'></div>
            <div className='login-left-side-div'></div>
            <img alt='lugar logo' src={Logo} className='login-logo' />
            <form className='login-form' onSubmit={handleSubmit}>
                <TextField label='Email' name='email' type='email' required size='small' autoFocus />
                <PasswordTextField size='small' />
                <StandardButton tabIndex={0} loading={submitting} style={{ marginTop: '25px' }} type={submitting ? 'button' : 'submit'}>Log in</StandardButton>
            </form>
            <p className='login-forgot-pass-p' tabIndex={0} onClick={() => setOpenForgotPassDialog(true)}>Esqueci-me da password</p>
            <ForgotPass open={openForgotPassDialog} close={() => setOpenForgotPassDialog(false)} handleResponse={handleForgotPassResponse} />
        </div>
    )
}

export default Login